/**
 * This function will get the signup platform for a user.
 */
const getSignupPlatform = () => {
  if (window.KovoAppCustomInfo && window.KovoAppCustomInfo.platform) {
    return `${window.KovoAppCustomInfo.platform}app`;
  }

  return 'web';
};

export default getSignupPlatform;
