const local = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'action-lookout-dev',
  },
  apiGateway: {
    installments: {
      REGION: 'us-east-1',
      URL:
        import.meta.env.VITE_INSTALLMENTS_API_URL ??
        'http://127.0.0.1:4000/local',
    },
    billing: {
      REGION: 'us-east-1',
      URL:
        import.meta.env.VITE_BILLING_API_URL ?? 'http://127.0.0.1:4001/local',
    },
    appsService: {
      REGION: 'us-east-1',
      URL: import.meta.env.VITE_APPS_SERVICE_API_URL ?? 'http://127.0.0.1:4014',
    },
    clientsService: {
      REGION: 'us-east-1',
      URL: import.meta.env.VITE_APPS_SERVICE_API_URL ?? 'http://127.0.0.1:4014',
    },
  },
  cognito: {
    REGION: import.meta.env.VITE_AWS_REGION || 'us-east-1',
    USER_POOL_ID:
      import.meta.env.VITE_COGNITO_USER_POOL_ID || 'us-east-1_dqSozsQDU',
    APP_CLIENT_ID:
      import.meta.env.VITE_COGNITO_APP_CLIENT_ID || '1jv8elgdndalfjnd5peom5jdk',

    /**
     * Local cognito mock has no concept of an identity pool
     */
    IDENTITY_POOL_ID:
      import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID &&
      import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID === 'disabled'
        ? undefined
        : 'us-east-1:c441a8d2-e31d-4cd1-b3ca-685d4ed0f829',
  },
  amplifyAuth: {
    cookieStorage: {
      domain: import.meta.env.VITE_COOKIE_DOMAIN ?? 'localhost',
      path: '/',
      expires: 365,
      secure: false,
    },
  },
  FACEBOOK_PIXEL_ID: '150312186207720',
  STRIPE_KEY: 'pk_test_0HPLkLwXIIxIB1dUf8bpp5Om00X6sZAfrJ',
  VITE_STAGE: 'local',
  LOG_LEVEL: 'debug' as const,
};

const dev = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'action-lookout-dev',
  },
  apiGateway: {
    installments: {
      REGION: 'us-east-1',
      URL:
        import.meta.env.VITE_INSTALLMENTS_API_URL ??
        'https://dev-api.kovocredit.com',
    },
    billing: {
      REGION: 'us-east-1',
      URL:
        import.meta.env.VITE_BILLING_API_URL ??
        'https://dev-api.kovocredit.com',
    },
    appsService: {
      REGION: 'us-east-1',
      URL: 'https://apps.services-staging.kovocredit.com',
    },
    clientsService: {
      REGION: 'us-east-1',
      URL: 'https://clients.services-staging.kovocredit.com',
    },
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_dqSozsQDU',
    APP_CLIENT_ID: '1jv8elgdndalfjnd5peom5jdk',
    IDENTITY_POOL_ID: 'us-east-1:c441a8d2-e31d-4cd1-b3ca-685d4ed0f829',
  },
  amplifyAuth: {
    cookieStorage: window.location.hostname.endsWith('scottsdaleoffsite.com')
      ? {
          domain: 'scottsdaleoffsite.com',
          path: '/',
          expires: 365,
          secure: true,
        }
      : undefined,
  },
  FACEBOOK_PIXEL_ID: '150312186207720',
  STRIPE_KEY: 'pk_test_0HPLkLwXIIxIB1dUf8bpp5Om00X6sZAfrJ',
  VITE_STAGE: 'dev',
  LOG_LEVEL: 'debug' as const,
};

const prod = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'YOUR_PROD_S3_UPLOADS_BUCKET_NAME',
  },
  apiGateway: {
    installments: {
      REGION: 'us-east-1',
      URL: 'https://prod-api.kovocredit.com',
    },
    billing: {
      REGION: 'us-east-1',
      URL: 'https://prod-api.kovocredit.com',
    },
    appsService: {
      REGION: 'us-east-1',
      URL: 'https://apps.services.kovocredit.com',
    },
    clientsService: {
      REGION: 'us-east-1',
      URL: 'https://clients.services.kovocredit.com',
    },
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_i0NFZyqNI',
    APP_CLIENT_ID: '5jgo3kt3ukgrs0dfq5t8nvu4qc',
    IDENTITY_POOL_ID: 'us-east-1:7c791449-ab5b-42ee-8bfd-8208ff326f7b',
  },
  amplifyAuth: {
    cookieStorage: {
      domain: 'kovocredit.com',
      path: '/',
      expires: 365,
      secure: true,
    },
  },
  FACEBOOK_PIXEL_ID: '150312186207720',
  STRIPE_KEY: 'pk_live_8NniHPQZ4hHxhY4dhE5ySVCp00ypRjA1TE',
  VITE_STAGE: 'prod',
  LOG_LEVEL: 'info' as const,
};

// Default to dev if not set
const config = (() => {
  switch (import.meta.env.VITE_STAGE) {
    case 'prod':
      return prod;
    case 'local':
      return local;
    default:
      return dev;
  }
})();

// TODO: use env substitution to populate this string with the git hash or tag version
const version = '2.4.0';
const navbarBreakPoint = 'xl';

const outputs = {
  version,
  navbarBreakPoint,
  ...config,
};

// TODO: change export strategy here to support type checking

export default outputs;
