import React from 'react';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { useRewardsQuery } from 'hooks/queries/useRewardsQuery';
import useApplicationStatus from 'hooks/useApplicationStatus';
import CheckoutRewardBanner from 'components/CheckoutRewardBanner';
import StripeRewardBanner from 'components/StripeRewardBanner';

const OfferEligibleBanner: React.FC = () => {
  const { data: applicationStatusData, isLoading: isLoadingApplicationStatus } =
    useApplicationStatus();

  const isCheckoutUser =
    applicationStatusData?.applicationDetails?.provider === 'checkout' ||
    applicationStatusData?.applicationDetails?.billingProvider === 'checkout';

  const { data: rewards, isLoading: isLoadingRewards } = useRewardsQuery();

  if (isLoadingApplicationStatus || isLoadingRewards) {
    return (
      <Skeleton
        variant="rounded"
        height={72}
        sx={{ marginBottom: theme.spacing(2) }}
      />
    );
  }

  if (rewards?.length === 0) {
    return (
      <Card variant="gradient" sx={{ marginBottom: theme.spacing(2) }}>
        <CardContent>
          <Typography>
            <Box component="strong">
              Rewards are currently unavailable in your location
            </Box>
            , but this may change at a later time. We apologize for the
            inconvenience.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (isCheckoutUser) {
    return <CheckoutRewardBanner />;
  }

  return <StripeRewardBanner />;
};

export default OfferEligibleBanner;
