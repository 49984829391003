import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@mui/material';

import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import { AuthContext } from 'context/AuthProvider';
import * as impact from 'helpers/impact';
import useApplicationStatus from 'hooks/useApplicationStatus';
import OnboardingPage from 'components/OnboardingPage';
import InstallmentPayment from 'components/InstallmentPayment';
import ContractDialog from 'components/ContractDialog';

import ProductBenefitsTable from 'components/ProductBenefitsTable';
import { ProductBenefit } from 'components/ProductBenefitsTable/ProductBenefitsTable';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';

const INSTALLMENT_PLAN_BENEFITS: Array<ProductBenefit> = [
  {
    title: 'Installment Credit Account',
    subtitle: '$0 fees, 0% interest, 24-month agreement',
  },
  {
    title: 'Credit Reporting: All 4 Bureaus',
    subtitle: 'TransUnion + Equifax + Experian + Innovis',
  },
  {
    title: 'FICO® Score',
    subtitle: 'Track the score used by 90% of top lenders',
  },
  {
    title: 'Credit Protection',
    subtitle: '24-month period',
  },
  {
    title: 'Credit Rewards',
    subtitle: 'Earn 1% on loans, up to $1,225',
  },
  {
    title: 'Unlimited Referrals',
    subtitle: 'Get $30 for every friend you refer',
  },
  {
    title: 'Personal Growth Tools',
    subtitle: 'Lifetime access to support your credit journey',
  },
];

const PaymentPage: React.FC = () => {
  const { data, isLoading, isFetching } = useApplicationStatus();
  const { currentUser, username, email } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      impact.identify({
        customerId: username,
        customerEmail: email,
      });
    }
  }, [currentUser, username, email]);

  if (!data || isLoading || isFetching) {
    return <PageLoader />;
  }

  if (data.applicationStatus !== 'paymentNeeded') {
    return <Redirect to="/" />;
  }

  return (
    <OnboardingPage onboardingStep={5} supportSubject="initial-payment">
      <Typography variant="h1" marginBottom={theme.spacing(3)}>
        Start building credit!
      </Typography>

      <ProductBenefitsTable
        benefits={INSTALLMENT_PLAN_BENEFITS}
        totalString="$10.00/mo"
        totalExplainer="Every timely payment on your 24-month account adds good payment
              history: the #1 factor in credit scores."
      />

      <InstallmentPayment />

      <Typography variant="footnote" marginTop={theme.spacing(3)}>
        By making your first payment, you are electronically signing the{' '}
        <ContractDialog productId={INSTALLMENTS_PRODUCT_ID}>
          Kovo Installment Credit Agreement
        </ContractDialog>{' '}
        and electing to make monthly payments on your installment credit account
        using auto-pay with this payment method. You acknowledge you have
        reviewed the full text and agree to its content.
      </Typography>
    </OnboardingPage>
  );
};

export default PaymentPage;
