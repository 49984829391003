import config from '../config';

/**
 * Construct the init object for AWS Amplify. This adds the
 * cognito fake id for serverless offline only for local dev.
 *
 * https://docs.amplify.aws/lib/restapi/fetch/q/platform/js
 */
export const amplifyRequestContext = (
  identityId: string,
  username: string,
  clientsServiceConfig?: {
    'x-api-key': string;
    'x-user-id': string;
    [key: string]: string;
  },
) => {
  if (config.VITE_STAGE === 'local' && identityId && username) {
    const cognitoAuthenticationProvider = `cognito-idp.us-east-1.amazonaws.com/us-east-1_xxxxxxxxx,cognito-idp.us-east-1.amazonaws.com/us-east-1_aaaaaaaaa:CognitoSignIn:${username}`;
    return {
      headers: {
        'cognito-identity-id': identityId,
        'cognito-authentication-provider': cognitoAuthenticationProvider,
        // This matches the header that's populated by the service-clients-prod API Gateway
        'x-cognito-authprovider': cognitoAuthenticationProvider,
        ...clientsServiceConfig,
      },
    };
  }

  return {};
};
