import { CognitoUser } from '@aws-amplify/auth';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import { ClientsService } from 'libs/ClientsService';
import { User } from 'types/schemas';
import { USE_CURRENT_USER_QUERY_KEY } from './queries/useCurrentUser';
import config from 'config';
import ReactGA from 'react-ga';

import { logOnboardingEvent } from 'libs/logger';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ROUTES } from 'components/Routes';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { signupUser } from 'libs/signupHelpers/signupUser';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { KovoUserCreationError } from 'libs/KovoError';

export type HandleSignupResponse = {
  cognitoUser: CognitoUser;
  userDetails: UserAuthDetails;
  user: User;
};

export interface HandlePostSignupOptions {
  cognitoUser: CognitoUser;
  clientsService: ClientsService;
}

type UserHandlePostSignupOptions = Omit<
  UseMutationOptions<
    HandleSignupResponse,
    KovoUserCreationError,
    HandlePostSignupOptions
  >,
  'onSuccess' | 'mutationFn'
>;

export const MUTATE_HANDLE_POST_USER_SIGNUP_KEY = 'mutateHandlePostUserSignup';

export const useHandlePostUserSignup = (
  options: UserHandlePostSignupOptions = {},
) => {
  const { authChangeCallback } = useAuthContext();
  const history = useHistory();
  const queryClient = useQueryClient();

  const handlePostUserSignup = useCallback(
    async (options: HandlePostSignupOptions): Promise<HandleSignupResponse> => {
      try {
        const { cognitoUser, clientsService } = options;

        const userDetails = await getCognitoUserAuthDetails(cognitoUser);

        const user = await signupUser(clientsService, {
          email: userDetails.email,
          userId: userDetails.identityId,
          userPoolUserId: userDetails.username,
        });

        queryClient.setQueryData(USE_CURRENT_USER_QUERY_KEY, user);

        if (config.VITE_STAGE === 'prod') {
          const eventName = 'CompleteEmailVerification';

          window.gtag('event', eventName);
          ReactGA.event({
            category: 'User',
            action: eventName,
          });
          fbq(
            'trackCustom',
            eventName,
            {},
            {
              eventID: `${user.userId}-${eventName}-installments10`,
            },
          );
        }

        logOnboardingEvent({
          eventName: 'email verified',
          email: user.currentEmail,
        });

        authChangeCallback(cognitoUser, userDetails, undefined, true);

        /**
         * Send message to mobile app letting it know that the user has been verified
         */
        if (window.ReactNativeWebView) {
          sendMessageToMobileApp({
            eventType: 'kovo.webapp.complete.email_verification',
          });
        }

        return { cognitoUser, userDetails, user };
      } catch (error) {
        throw new KovoUserCreationError('Error during handlePostUserSignup', {
          error,
        }).setDisplayMessage('Error signing up. Please try again.');
      }
    },
    [queryClient, authChangeCallback],
  );

  return useMutation<
    HandleSignupResponse,
    KovoUserCreationError,
    HandlePostSignupOptions
  >({
    mutationKey: MUTATE_HANDLE_POST_USER_SIGNUP_KEY,
    ...options,
    mutationFn: handlePostUserSignup,
    onSuccess() {
      history.push(ROUTES.PHONE_NUMBER);
    },
  });
};
