import { Box, Typography, Link as MuiLink } from '@mui/material';
import OnboardingPage from 'components/OnboardingPage';
import { theme } from 'context/ThemeProvider';
import { Helmet } from 'react-helmet';

export const ESignConsentPage: React.FC = () => {
  return (
    <Box
      style={{
        overflowX: 'hidden',
      }}
    >
      <Helmet>
        <title>Kovo E-Sign Consent</title>
      </Helmet>
      <OnboardingPage supportSubject="E-Sign Consent">
        <Typography marginBottom={theme.spacing(4)} variant="h1">
          Kovo E-Sign Consent
        </Typography>
        <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
          <Typography variant="body1">
            Please read this information carefully and print a copy, bookmark,
            and/or retain this information for future reference.
          </Typography>
          <Typography variant="body1">
            Kovo operates its business solely through the Internet. To the
            extent permitted by law, you consent to use electronic signatures
            and to electronically receive all records, notices, statements,
            communications, and other items for all Services (collectively,
            "Communications") that we may otherwise be required to send or
            provide you in writing or paper form (e.g., by mail).
          </Typography>
          <Typography fontWeight="bold" variant="body1">
            Your agreement to this Electronic Signature Consent confirms your
            ability and consent to receive all Communications electronically,
            rather than in paper form, and to the use of your electronic
            signature in our relationship with you. Before doing so, it is
            important that you: (1) read and understand this Consent; (2)
            determine that you satisfy the minimum hardware and software
            requirements set out below; and (3) understand your consent will
            remain in effect until you withdraw it as provided below.
          </Typography>
          <Typography variant="body1">
            This section informs you of your rights when receiving electronic
            Communications from Kovo.
          </Typography>

          <Typography variant="h2">1. Electronic Communications</Typography>
          <Typography variant="body1">
            Communications covered by this Consent include disclosures and
            communications we provide to you regarding our Services such as: (i)
            terms and conditions, privacy statements or notices and any changes;
            (ii) Services disclosures, transaction receipts and confirmations;
            (iii) account histories; and (iv) customer service communications
            (such as claims of error communications).
          </Typography>

          <Typography variant="h2">2. Receiving Communications</Typography>
          <Typography variant="body1">
            We may provide Communications to you by email, by text message, or
            by making them accessible on the Kovo website or mobile website
            (including via links provided online and in emails). Communications
            will be provided online and will be viewable using browser software
            or PDF viewer software. You should print and save and/or
            electronically store a copy of all Communications that we send to
            you electronically.
          </Typography>

          <Typography variant="h2">
            3. Hardware and Software Requirements
          </Typography>
          <Typography variant="body1">
            Hardware and Software Requirements. In order to receive electronic
            Communications, you must have the following: (1) access to the
            Internet; (2) access to the email address used to create an account
            for Kovo; (3) software capable of receiving email through the
            Internet; (4) supported web browsing software that includes 128-bit
            encryption. Minimum recommended browser standards are Google Chrome{' '}
            <MuiLink
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              current version
            </MuiLink>
            , Apple Safari{' '}
            <MuiLink
              href="https://www.apple.com/safari/"
              target="_blank"
              rel="noopener noreferrer"
            >
              current version
            </MuiLink>
            , Mozilla Firefox{' '}
            <MuiLink
              href="https://www.mozilla.org/en-US/firefox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              current version
            </MuiLink>
            , or Microsoft Edge{' '}
            <MuiLink
              href="https://www.microsoft.com/en-us/edge/"
              target="_blank"
              rel="noopener noreferrer"
            >
              current version
            </MuiLink>
            . The browser must have cookies enabled; (5) Sufficient storage
            space to save Communications and/or a printer to print them.
          </Typography>
          <Typography variant="body1">
            To ensure access and optimal printing of your documents in PDF
            format, you must have Adobe Reader, a free version is available{' '}
            <MuiLink
              href="https://get.adobe.com/reader/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </MuiLink>
            .
          </Typography>
          <Typography variant="body1">
            If you use a spam filter or similar software that blocks or
            re-routes emails from untrusted senders, we recommend that you add
            Kovo to your email address book to ensure you receive Communications
            by email.
          </Typography>

          <Typography variant="h2">
            4. Additional Mobile Technology Requirements
          </Typography>
          <Typography variant="body1">
            If you access our Site and Communications electronically via a
            mobile device (such as a smart phone or tablet), in addition to the
            above requirements you must make sure that you have software on your
            mobile device that allows you to print and save the Communications
            presented to you. If you do not have these capabilities on your
            mobile device, please access our Site through a device that provides
            these capabilities.
          </Typography>

          <Typography variant="h2">5. How to Withdraw Your Consent</Typography>
          <Typography variant="body1">
            You may withdraw your consent to receive Communications
            electronically by contacting us at support@kovocredit.com. If you
            withdraw this consent to receive Communications, you may no longer
            be able to use our Services.
          </Typography>

          <Typography variant="h2">6. Request Paper Copies</Typography>
          <Typography variant="body1">
            You may request that we send you a paper copy of Communications via
            U.S. Mail. To request a paper copy, contact us at
            support@kovocredit.com. You must provide your current U.S. mailing
            address so we can process this request. Except as prohibited by law,
            we reserve the right, in our sole discretion, to deny your request.
          </Typography>

          <Typography variant="h2">
            7. Updating Your Contact Information{' '}
          </Typography>
          <Typography variant="body1">
            In order to ensure that we are able to provide Communications to you
            electronically, you must notify us of any change in your email
            address and your mobile device telephone number by updating your
            profile on the Kovo website.{' '}
            <strong>
              It is your responsibility to keep your contact information current
              so that Kovo can communicate with you electronically
            </strong>
            . You understand and agree that if Kovo sends you a Communication
            but you do not receive it because your contact information on file
            is incorrect, the Communication is blocked by your service provider,
            or you are otherwise unable to receive electronic Communications,
            Kovo will be deemed to have provided the Communication to you. If we
            are unable to deliver electronic Communications to you, we may deem
            your account inactive. You may not be able to use our Services until
            we receive a valid, working primary email address and/or mobile
            device telephone number from you.
          </Typography>
        </Box>
      </OnboardingPage>
    </Box>
  );
};

export default ESignConsentPage;
