import { Redirect } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import useApplicationStatus from 'hooks/useApplicationStatus';
import { theme } from 'context/ThemeProvider';
import Page from 'components/Page';
import OfferEligibleBanner from 'components/OfferEligibleBanner';
import RewardsList from 'components/RewardsList';
import PageLoader from 'components/PageLoader';
import { OFFERS_REWARDS_STATUSES } from './OfferCategoriesPage';
import PopupDialog from 'components/PopupDialog/PopupDialog';

const RewardsPage: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (!OFFERS_REWARDS_STATUSES.includes(data.applicationStatus)) {
    return <Redirect to="/" />;
  }

  const isCheckoutUser =
    data.applicationDetails?.provider === 'checkout' ||
    data.applicationDetails?.billingProvider === 'checkout';

  return (
    <Page>
      <Box
        maxWidth="sm"
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            margin: '0 auto',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Rewards
        </Typography>

        <Typography marginBottom={theme.spacing(2)}>
          Earn up to 1% back on loans and up to $75 when opening a credit card.
          Only through the Kovo network.{' '}
          <PopupDialog
            variant="body1"
            dialogText="Rewards are available to customers who have made 4 on-time payments and are current or have fully paid their Installments. Rewards are 1% back with the following maximums: personal loan (up to $500), student loan (up to $250), auto loan (up to $150) and credit card ($75). Customer may only redeem one reward per category. Rewards are provided in the form of a gift card within 90 days after loan origination or credit card opening. Customers seeking rewards must be logged in to their Kovo account. Note: we may be unable to link your activity if you have a content blocker or ad blocker enabled in your browser."
          >
            Details
          </PopupDialog>
        </Typography>
      </Box>

      {isCheckoutUser && <OfferEligibleBanner />}

      <RewardsList />
    </Page>
  );
};

export default RewardsPage;
