import { API } from 'aws-amplify';
import { useQuery, UseQueryOptions } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { ApplicationStatusResponse } from 'context/Profile';
import useCurrentUser from './queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';

const TWO_SECONDS_MS = 2 * 1000;

function useApplicationStatus(
  options?: UseQueryOptions<ApplicationStatusResponse>,
) {
  const { data: user } = useCurrentUser();

  const getApplicationStatus = async () => {
    try {
      if (!user) {
        throw new Error('User not found');
      }
      const startTime = Date.now();
      const applicationStatus = await API.get(
        'installments',
        '/installments/application-status',
        amplifyRequestContext(user.userId, user.userPoolUserId),
      );
      const endTime = Date.now();
      const elapsedTimeMs = endTime - startTime;

      if (elapsedTimeMs > TWO_SECONDS_MS && rollbarEnabled) {
        rollbar.error('Application status request time exceeded threshold', {
          userId: user.userId,
        });
      }

      return applicationStatus;
    } catch (error) {
      if (rollbarEnabled && user) {
        rollbar.error('Error loading application status', {
          error,
          userId: user.userId,
        });
      }
      throw error;
    }
  };

  return useQuery<ApplicationStatusResponse>({
    queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    queryFn: getApplicationStatus,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    /**
     * Generally, we don't want hooks and useQuery to have to have this manual enabled/disabled toggle
     * However, for this query I could not find a way to get it to stop running earlier than it should
     * (during the login pages), so this was the only solution I found for the time being.
     *
     * TODO: revisit this as part of continued efforts to clean up the contexts and try to synchronize them
     * together in a more maintainable fashion.
     */
    enabled: !!user,
    ...options,
  });
}

export default useApplicationStatus;
