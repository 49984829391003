import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { useClientsService } from 'hooks/useClientsService';
import { AxiosError } from 'axios';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';

type PatchUserInput = {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  appDownloadPromptSeenAt?: string;
};

function usePatchUser(
  options?: UseMutationOptions<any, unknown, PatchUserInput, unknown>,
) {
  const clientsService = useClientsService();

  const queryClient = useQueryClient();

  const patchUser = async (input: PatchUserInput) => {
    return clientsService.patch('/v1/me', input);
  };

  const onSuccess = (
    data: any,
    variables: PatchUserInput,
    context: unknown,
  ) => {
    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    if (options?.onSuccess) {
      options.onSuccess(data, variables, context);
    }
  };

  return useMutation<any, AxiosError<any>, any>(patchUser, {
    ...(options && { ...options }),
    onSuccess,
  });
}

export default usePatchUser;
