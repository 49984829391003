import { createContext, useState, FC, useContext } from 'react';

/**
 * Store a bit of state here to control if we want to do a specific redirect on app load
 *
 * This context is available just to store that we have done a one time redirect, not if we should
 * have done the redirect.
 */

type GlobalRedirectContextType = {
  updateEmailConfirmation: boolean;

  setKey: (key: string, val: boolean) => void;
};

const defaultValue = () => {
  return {
    updateEmailConfirmation: false,

    setKey: (key: string, val: boolean) => {},
  };
};

export const GlobalRedirectContext = createContext<GlobalRedirectContextType>(
  defaultValue(),
);

export const GlobalRedirectContextProvider: FC = ({ children }) => {
  const setKey = (key: string, val: boolean) => {
    setState((state) => {
      return {
        ...state,
        [key]: val,
      };
    });
  };

  const [state, setState] = useState({
    ...defaultValue(),
    setKey,
  });

  return (
    <GlobalRedirectContext.Provider value={state}>
      {children}
    </GlobalRedirectContext.Provider>
  );
};

export const useGlobalRedirectContext = () => {
  return useContext(GlobalRedirectContext);
};
