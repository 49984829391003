import moment from 'moment';
import { Box, Typography } from '@mui/material';

import { KOVO_GREY, theme } from 'context/ThemeProvider';

export type InvoiceDescription =
  | 'Credit Protection: start'
  | 'Credit Protection: auto-renew';

interface Props {
  accountNumber: string;
  description: InvoiceDescription;
  amount: number;
  effectiveAt: Date;
}

const TransactionInvoice: React.FC<Props> = ({
  accountNumber,
  description,
  amount,
  effectiveAt,
}) => {
  const date = moment(effectiveAt);
  const dueDate = date.clone().add(1, 'day');

  return (
    <>
      <Typography variant="h1" marginBottom={theme.spacing(6)}>
        Transaction Invoice
      </Typography>

      <Box
        sx={{
          borderTop: `1px dashed ${KOVO_GREY}`,
          marginBottom: theme.spacing(6),
        }}
      />

      <Typography>
        <strong>Account Number</strong>
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>{accountNumber}</Typography>

      <Typography>
        <strong>Transaction Description</strong>
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>{description}</Typography>

      <Typography>
        <strong>Transaction Amount</strong>
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>
        ${(amount / 100).toFixed(2)}
      </Typography>

      <Typography>
        <strong>Transaction Date</strong>
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>
        {date.format('MM/DD/YY')}
      </Typography>

      <Typography>
        <strong>Payment Due Date</strong>
      </Typography>
      <Typography marginBottom={theme.spacing(6)}>
        {dueDate.format('MM/DD/YY')}
      </Typography>

      <Box
        sx={{
          borderTop: `1px dashed ${KOVO_GREY}`,
          marginBottom: theme.spacing(6),
        }}
      />

      {description === 'Credit Protection: start' && (
        <Typography variant="footnote" marginBottom={theme.spacing(3)}>
          You can cancel upcoming renewal by getting in touch with our support
          team.
        </Typography>
      )}

      <Typography variant="footnote" marginBottom={theme.spacing(1.5)}>
        <strong>
          What To Do If You Think You Find A Mistake On Your Statement
        </strong>
      </Typography>
      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        If you think there is an error on your statement, write to us at Kovo,
        9450 SW Gemini Dr, Suite 87907, Beaverton, OR 97008-7105.
      </Typography>

      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        In your letter, give us the following information:
      </Typography>
      <Box
        component="ul"
        sx={{
          margin: theme.spacing(0, 0, 1),
          padding: theme.spacing(0, 0, 0, 2),
        }}
      >
        {[
          'Account information: Your name and account number.',
          'Dollar amount: The dollar amount of the suspected error.',
          'Description of Problem: If you think there is an error on your bill, describe what you believe is wrong and why you believe it is a mistake.',
        ].map((text) => (
          <Box
            component="li"
            key={text}
            sx={{
              '&::marker': {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                fontSize: '12px',
              },
            }}
          >
            <Typography variant="footnote">{text}</Typography>
          </Box>
        ))}
      </Box>

      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        You must contact us within 60 days after the error appeared on your
        statement. You must notify us of any potential errors in writing.
      </Typography>

      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        While we investigate whether or not there has been an error, the
        following are true:
      </Typography>
      <Box
        component="ul"
        sx={{
          margin: theme.spacing(0, 0, 1),
          padding: theme.spacing(0, 0, 0, 2),
        }}
      >
        {[
          'We cannot try to collect the amount in question, or report you as delinquent on that amount.',
          'The charge in question may remain on your statement, and we may continue to charge you interest on that amount. But, if we determine that we made a mistake, you will not have to pay the amount in question or any interest or other fees related to that amount.',
          'While you do not have to pay the amount in question, you are responsible for the remainder of your balance.',
          'We can apply any unpaid amount against your credit limit.',
        ].map((text) => (
          <Box
            component="li"
            key={text}
            sx={{
              '&::marker': {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                fontSize: '12px',
              },
            }}
          >
            <Typography variant="footnote">{text}</Typography>
          </Box>
        ))}
      </Box>

      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        <strong>
          Your Rights If You Are Dissatisfied With Your Credit Card Purchases
        </strong>
      </Typography>
      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        If you are dissatisfied with the goods or services that you have
        purchased with your credit card, and you have tried in good faith to
        correct the problem with the merchant, you may have the right not to pay
        the remaining amount due on the purchase.
      </Typography>
      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        To use this right, all of the following must be true:
      </Typography>
      <Box
        component="ol"
        sx={{
          margin: theme.spacing(0, 0, 1),
          padding: theme.spacing(0, 0, 0, 2),
        }}
      >
        {[
          'The purchase must have been made in your home state or within 100 miles of your current mailing address, and the purchase price must have been more than $50. (Note: Neither of these is necessary if your purchase was based on an advertisement we mailed to you, or if we own the company that sold you the goods or services.)',
          'You must have used your credit card for the purchase. Purchases made with cash advances from an ATM or with a check that accesses your credit card account do not qualify.',
          'You must not yet have fully paid for the purchase.',
        ].map((text) => (
          <Box
            component="li"
            key={text}
            sx={{
              '&::marker': {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                fontSize: '12px',
              },
            }}
          >
            <Typography variant="footnote">{text}</Typography>
          </Box>
        ))}
      </Box>

      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        If all of the criteria above are met and you are still dissatisfied with
        the purchase, contact us in writing at Kovo, 9450 SW Gemini Dr, Suite
        87907, Beaverton, OR 97008-7105.
      </Typography>
      <Typography variant="footnote">
        While we investigate, the same rules apply to the disputed amount as
        discussed above. After we finish our investigation, we will tell you our
        decision. At that point, if we think you owe an amount and you do not
        pay we may report you as delinquent.
      </Typography>
    </>
  );
};

export default TransactionInvoice;
