import { Box, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import Page from 'components/Page';
import ReferralsBalance from 'components/ReferralsBalance';
import GiftCardsView from 'components/GiftCardsView/GiftCardsView';

const WalletPage: React.FC = () => {
  return (
    <Page>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Wallet
        </Typography>

        <Typography marginBottom={theme.spacing(4)}>
          Access your earnings from inviting friends and participating in Kovo
          Rewards.
        </Typography>
      </Box>

      <ReferralsBalance />

      <GiftCardsView />
    </Page>
  );
};

export default WalletPage;
