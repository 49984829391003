import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useGetApplications from 'hooks/queries/useGetApplications';
import { ROUTES } from 'components/Routes';
import ChargeAccountNavItem from '../ChargeAccountNavItem';

import ChargeAccountIconLarge from 'assets/img/icons/navigation/charge-account_large.svg';

const ChargeAccountNavCard: React.FC = () => {
  const { data, isFetching } = useGetApplications();

  if (isFetching) {
    return (
      <>
        <Grid item xs={12} md={6}>
          <Skeleton
            sx={{
              height: '48px',
              [theme.breakpoints.up('sm')]: {
                height: '64px',
              },
              [theme.breakpoints.up('md')]: {
                height: '96px',
              },
            }}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            sx={{
              height: '48px',
              [theme.breakpoints.up('sm')]: {
                height: '64px',
              },
              [theme.breakpoints.up('md')]: {
                height: '96px',
              },
            }}
            variant="rounded"
          />
        </Grid>
      </>
    );
  }

  if (!data) {
    return null;
  }

  const { chargeAccountApplication } = data;

  if (
    !chargeAccountApplication ||
    ['new', 'started', 'esignViewed'].includes(chargeAccountApplication.status)
  ) {
    return (
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardContent>
            <Box textAlign="center">
              <Typography variant="h2" marginBottom={theme.spacing(2)}>
                {!chargeAccountApplication
                  ? 'You’re approved!'
                  : 'What’s stopping you?'}
              </Typography>

              <Box
                component="img"
                src={ChargeAccountIconLarge}
                alt=""
                marginBottom={theme.spacing(0.5)}
                height="105px"
                width="105px"
              />

              <Typography variant="h3" marginBottom={theme.spacing(2)}>
                Kovo Credit Line
              </Typography>

              <Typography marginBottom={theme.spacing(2)}>
                {!chargeAccountApplication
                  ? 'Power it up! Get the Kovo Credit Line with Maximum Credit Protection.'
                  : 'You’re approved! Get the Kovo Credit Line with Maximum Credit Protection.'}
              </Typography>

              <Button
                component={Link}
                to={ROUTES.CREDIT_LINE_HOW_IT_WORKS}
                variant="contained"
                fullWidth
                data-testid="charge-account-nav-card-start-now-button"
                sx={{ maxWidth: '302px' }}
              >
                {!chargeAccountApplication ? 'Start Now' : 'Finish Setup'}
              </Button>
              <Typography variant="footnote" marginTop={theme.spacing(2)}>
                Maximum Credit Protection includes up to $1M ID Fraud Insurance
                + 24/7 ID Theft Resolution.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (chargeAccountApplication.status === 'completed') {
    return (
      <Grid item xs={12} md={6} order={{ xs: 0 }}>
        <ChargeAccountNavItem />
      </Grid>
    );
  }

  return null;
};

export default ChargeAccountNavCard;
