import { API } from 'aws-amplify';
import React, { useContext } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Alert, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import useStripeStatus from 'hooks/useStripeStatus';
import { theme } from 'context/ThemeProvider';
import { AuthContext } from 'context/AuthProvider';
import StripeForm, { FormValues } from 'components/StripeForm';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';

export interface Props {
  isUpdating?: boolean;
}

export const StripeInstallment: React.FC<
  Props & ReactStripeElements.InjectedStripeProps
> = ({ isUpdating = false, stripe, elements }) => {
  const { identityId, email, username } = useContext(AuthContext);

  const history = useHistory();

  const queryClient = useQueryClient();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const submitPayment = async ({ name }: FormValues) => {
    const cardNumberElement = elements?.getElement('cardNumber');
    const paymentMethod = await stripe?.createPaymentMethod({
      type: 'card',
      card: cardNumberElement!,
      billing_details: {
        email,
        name,
      },
    });

    if (paymentMethod?.error) {
      throw new Error(paymentMethod.error.message);
    }

    if (!paymentMethod?.paymentMethod?.id) {
      throw new Error('Error processing your card. Please try again later.');
    }

    const subscriptionRes = await API.post('billing', '/billing/subscription', {
      body: {
        paymentMethod: paymentMethod.paymentMethod.id,
        email,
      },
      ...amplifyRequestContext(identityId, username),
    });

    if (!subscriptionRes.stripeSubscription) {
      throw new Error('Error processing your card. Please try again later.');
    }
    const {
      latest_invoice: { payment_intent },
    } = subscriptionRes.stripeSubscription;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (
        status === 'requires_action' ||
        status === 'requires_payment_method'
      ) {
        const confirmCardPayment = await stripe?.confirmCardPayment(
          client_secret,
        );

        if (confirmCardPayment?.error) {
          throw new Error(confirmCardPayment.error.message);
        }
      }
    }

    if (!isUpdating) {
      await API.post('installments', '/installments/confirmPayment', {
        body: {},
        ...amplifyRequestContext(identityId, username),
      });
    }
  };

  const onSuccess = () => {
    if (isUpdating) {
      queryClient.invalidateQueries('StripePaymentInfo');
      return history.push('/installment-plan');
    }

    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });
    history.push('/');
  };

  const {
    mutate,
    error: serverError,
    isLoading,
  } = useMutation<void, Error, FormValues>(submitPayment, {
    onSuccess,
  });

  const { data: paymentInfo, isLoading: isLoadingStripeStatus } =
    useStripeStatus();

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError.message}
        </Alert>
      )}

      {isUpdating && !isLoadingStripeStatus && (
        <Typography variant="body2" marginBottom={theme.spacing(3)}>
          Your monthly payments are currently set up on auto-pay with your card
          ending in {paymentInfo?.paymentMethod.last4}. Please update your card
          below.
        </Typography>
      )}

      <StripeForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        {...(isUpdating ? { buttonText: 'Update' } : {})}
      />
    </>
  );
};

export default injectStripe(StripeInstallment);
