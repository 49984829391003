import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';
import { USE_APPLICATIONS_QUERY_KEY } from 'hooks/queries/useGetApplications';

function useCreateApplication() {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const createApplication = async () => {
    const res = await API.post('clientsService', '/v1/applications', {
      body: {
        loanProductId: CHARGE_ACCOUNT_PRODUCT_ID,
      },
      headers: {
        ...amplifyRequestContext(identityId, username, {
          'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
          'x-user-id': identityId,
          'content-type': 'application/json',
        }).headers,
      },
    });

    return res;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATIONS_QUERY_KEY],
    });
  };

  return useMutation(createApplication, { onSuccess });
}

export default useCreateApplication;
