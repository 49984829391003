import { API } from 'aws-amplify';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { getCurrentUser } from 'hooks/queries/useCurrentUser';
import { ClientsService } from 'libs/ClientsService';
import { KovoError } from 'libs/KovoError';
import getSignupData from './getSignupData';
import { isFeatureFlagEnabled } from 'helpers/featureFlags';

export interface SignupUserOptions {
  email: string;
  userId: string;
  userPoolUserId: string;
}

/**
 * Creates a new user in the database.
 */
export const signupUser = async (
  clientsService: ClientsService,
  userDetails: SignupUserOptions,
) => {
  try {
    const signupData = getSignupData();
    if (isFeatureFlagEnabled('FF_USERS_SERVICE_SIGNUP')) {
      await clientsService
        .setHeaderOptions({
          userId: userDetails.userId,
          userPoolUserId: userDetails.userPoolUserId,
        })
        .post(`/v1/signup`, {
          email: userDetails.email,
          referralCodeId: signupData.referralCode,
          ...signupData,
        });
    } else {
      await API.post('installments', '/installments/signup', {
        body: {
          email: userDetails.email,
          ...signupData,
        },
        ...amplifyRequestContext(
          userDetails.userId,
          userDetails.userPoolUserId,
        ),
      });
    }

    const user = await getCurrentUser(
      clientsService.setHeaderOptions({
        userId: userDetails.userId,
        userPoolUserId: userDetails.userPoolUserId,
      }),
    );

    return user;
  } catch (error) {
    throw new KovoError('Failed to signup user', { error });
  }
};
