import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { visuallyHidden } from '@mui/utils';

import useApplicationStatus from 'hooks/useApplicationStatus';
import { theme } from 'context/ThemeProvider';

import WalmartCard from 'assets/img/products/walmart-card.svg';
import AmazonCard from 'assets/img/products/amazon-card.svg';
import VisaCard from 'assets/img/products/visa-card.svg';

interface Props {
  onClose: () => void;
  open: boolean;
  mutateFn: any;
  onSuccess: any;
}

export interface FormValues {
  giftCardName: null | string;
  giftCardImg: null | string;
}

interface MutationArgument {
  values: FormValues;
}

interface GiftCard {
  name: string;
  cardImg: string;
}

const giftCards: GiftCard[] = [
  {
    name: 'Walmart',
    cardImg: WalmartCard,
  },
  {
    name: 'Amazon',
    cardImg: AmazonCard,
  },
  {
    name: 'Visa',
    cardImg: VisaCard,
  },
];

const ClaimGiftCardDialog: React.FC<Props> = ({
  onClose,
  open,
  mutateFn,
  onSuccess,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const { data } = useApplicationStatus();

  const goToNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setFieldValue('giftCardName', null);
    setFieldValue('giftCardImg', null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = (values: FormValues) => {
    mutate({ values });
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      giftCardName: null,
      giftCardImg: null,
    },
    onSubmit,
  });

  const { mutate, isLoading, error } = useMutation<
    string,
    AxiosError,
    MutationArgument
  >(mutateFn, { onSuccess });

  return (
    <Dialog onClose={onClose} open={open}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: theme.spacing(1),
          right: theme.spacing(1),
        }}
      >
        <CloseIcon />
      </IconButton>

      <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <>
            <DialogTitle variant="h4">Choose a gift card</DialogTitle>

            <DialogContent>
              {giftCards.map(({ name, cardImg }) => (
                <Card
                  elevation={4}
                  sx={{
                    ':not(:last-child)': {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                  key={name}
                >
                  <CardActionArea
                    onClick={() => {
                      setFieldValue('giftCardName', name);
                      setFieldValue('giftCardImg', cardImg);
                      goToNext();
                    }}
                    disableRipple
                  >
                    <CardMedia component="img" src={cardImg} />
                    <CardContent>
                      <Typography sx={visuallyHidden}>{name}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </DialogContent>
          </>
        )}

        {activeStep === 1 && (
          <>
            <IconButton
              onClick={handleBack}
              sx={{
                position: 'absolute',
                top: theme.spacing(1),
                left: theme.spacing(1),
              }}
            >
              <ArrowBackIcon />
            </IconButton>

            <DialogTitle sx={{ marginTop: theme.spacing(5) }} variant="h4">
              Gift card will be sent to:
            </DialogTitle>

            <DialogContent>
              <Typography marginBottom={theme.spacing(3)}>
                {data?.applicationDetails?.currentEmail ||
                  data?.applicationDetails?.signupEmail}
              </Typography>

              {values.giftCardImg && (
                <Box component="img" src={values.giftCardImg} maxWidth="100%" />
              )}

              {error && (
                <Typography
                  textAlign="center"
                  color="error"
                  marginTop={theme.spacing(1)}
                >
                  {error.message}
                </Typography>
              )}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                fullWidth
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  );
};

export default ClaimGiftCardDialog;
