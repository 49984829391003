import { useContext } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';

interface Charge {
  amount: string;
  date: string;
  description: string;
  receiptUrl: string;
}

interface Invoice {
  amountDue: number;
  amountPaid: number;
  billingReason: string;
  id: string;
  periodStart: string;
  status: string;
  total: number;
}

interface StripeStatus {
  charges: Charge[];
  customerExists: boolean;
  openInvoices: Invoice[];
  paymentMethod: {
    last4: string;
  };
  provider: 'stripe';
  status: boolean;
  subscription: {
    billingCycleAnchor: string;
    currentPeriodEnd: string;
    currentPeriodStart: string;
    status: string;
  };
}

function useStripeStatus(options?: UseQueryOptions<StripeStatus>) {
  const { identityId, username } = useContext(AuthContext);

  const getPaymentInfo = async () => {
    const billingStatus = await API.post(
      'billing',
      '/billing/status',
      amplifyRequestContext(identityId, username),
    );

    return billingStatus;
  };

  return useQuery<StripeStatus>(['StripePaymentInfo'], () => getPaymentInfo(), {
    refetchOnWindowFocus: false,
    ...options,
  });
}

export default useStripeStatus;
