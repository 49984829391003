import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';

export type Metro2Info = {
  hasDerogatoryMark: boolean;
  hasDisputes: boolean;
  isAllAccountsActive: boolean;
};

function useGetMetro2Info() {
  const { identityId, username } = useContext(AuthContext);

  const getMetro2Status = async () => {
    const res = await API.get('clientsService', `/v1/metro2_records/info`, {
      headers: {
        ...amplifyRequestContext(identityId, username, {
          'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
          'x-user-id': identityId,
        }).headers,
      },
    });

    return res;
  };

  return useQuery<Metro2Info>('metro2Info', getMetro2Status, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export default useGetMetro2Info;
