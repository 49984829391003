import { Grid } from '@mui/material';

import config from 'config';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import useGetApplications from 'hooks/queries/useGetApplications';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import PageLoader from 'components/PageLoader';
import ChargeAccountNavigation from 'components/charge-account/ChargeAccountNavigation';
import NavigationItem from 'components/NavigationItem';
import InstallmentPlanNavItem from 'components/InstallmentPlanNavItem';
import StripeInstallmentPlanNavItem from 'components/StripeInstallmentPlanNavItem';
import {
  CHARGE_ACCOUNT_STATUSES,
  VALID_STRIPE_STATUSES,
} from 'components/charge-account/ChargeAccountRedirect/ChargeAccountRedirect';

import CreditProtectionIcon from 'assets/img/icons/navigation/credit-protection.svg';
import CreditProtectionIconLarge from 'assets/img/icons/navigation/credit-protection_large.svg';
import InviteFriends from 'assets/img/icons/navigation/invite-friends.svg';
import InviteFriendsLarge from 'assets/img/icons/navigation/invite-friends_large.svg';
import Courses from 'assets/img/icons/navigation/courses.svg';
import CoursesLarge from 'assets/img/icons/navigation/courses_large.svg';
import Rewards from 'assets/img/icons/navigation/rewards.svg';
import RewardsLarge from 'assets/img/icons/navigation/rewards_large.svg';
import FicoScore from 'assets/img/icons/navigation/fico-score.svg';
import FicoScoreLarge from 'assets/img/icons/navigation/fico-score_large.svg';
import Wallet from 'assets/img/icons/navigation/wallet.svg';
import WalletLarge from 'assets/img/icons/navigation/wallet_large.svg';
import Offers from 'assets/img/icons/navigation/offers.svg';
import OffersLarge from 'assets/img/icons/navigation/offers_large.svg';
import Settings from 'assets/img/icons/navigation/settings.svg';
import SettingsLarge from 'assets/img/icons/navigation/settings_large.svg';
import { useEffect } from 'react';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { isInstallmentAccountInGoodStanding } from 'helpers/accounts';

const NAV_ITEMS = [
  {
    icon: CreditProtectionIcon,
    iconDesktop: CreditProtectionIconLarge,
    title: 'Credit Protection',
    route: '/credit-protection',
  },
  {
    icon: FicoScore,
    iconDesktop: FicoScoreLarge,
    title: 'FICO® Score',
    route: '/credit-score',
  },
  // hide in mobile app
  ...(!window.ReactNativeWebView
    ? [
        {
          icon: Courses,
          iconDesktop: CoursesLarge,
          title: 'Courses',
          route: '/courses',
        },
      ]
    : []),
  {
    icon: InviteFriends,
    iconDesktop: InviteFriendsLarge,
    title: 'Invite Friends',
    route: '/invite-friends',
  },
  {
    icon: Rewards,
    iconDesktop: RewardsLarge,
    title: 'Rewards',
    route: '/rewards',
  },
  {
    icon: Wallet,
    iconDesktop: WalletLarge,
    title: 'Wallet',
    route: '/wallet',
  },
  {
    icon: Offers,
    iconDesktop: OffersLarge,
    title: 'Offers',
    route: '/offer-categories',
  },
  {
    icon: Settings,
    iconDesktop: SettingsLarge,
    title: 'Settings',
    route: '/settings',
  },
];

const Navigation: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  const { data: userData, isLoading: isLoadingUserData } = useCurrentUser();

  const { data: applicationsData } = useGetApplications();

  const { data: accountsData } = useGetAccounts();

  useEffect(() => {
    if (!userData) {
      return;
    }
    sendMessageToMobileApp({
      eventType: 'kovo.webapp.user_info',
      body: userData,
    });
  }, [userData]);

  if (isLoading || isLoadingUserData) {
    return <PageLoader />;
  }

  if (!data || !userData) {
    return null;
  }

  const installmentAccount = accountsData?.installmentAccount;

  const shouldShowChargeAccount = (): boolean => {
    if (config.VITE_STAGE !== 'prod') {
      return true;
    }

    // If their charge account is completed
    if (applicationsData?.chargeAccountApplication?.status === 'completed') {
      return true;
    }

    // Otherwise, we need to check several things
    // If they have charge account enabled
    if (!userData.chargeAccountEnabled) {
      return false;
    }

    // If their application status is one of these
    if (!CHARGE_ACCOUNT_STATUSES.includes(data.applicationStatus)) {
      return false;
    }

    if (userData.defaultPaymentProvider === 'stripe') {
      // For Stripe users, installmentAccount will be null so we need to check their status instead
      return VALID_STRIPE_STATUSES.includes(userData.status);
    }

    // For Checkout users, let's check their installment account
    return isInstallmentAccountInGoodStanding(installmentAccount);
  };

  return (
    <Grid container spacing={{ xs: 1.5, sm: 2, md: 3 }}>
      {shouldShowChargeAccount() && <ChargeAccountNavigation />}

      {userData.defaultPaymentProvider === 'checkout' ? (
        <InstallmentPlanNavItem />
      ) : (
        <StripeInstallmentPlanNavItem />
      )}

      {NAV_ITEMS.map(({ icon, iconDesktop, title, route }, index) => {
        if (
          data.applicationStatus === 'upfrontPaymentCompleted' &&
          ['Installment Plan', 'Invite Friends', 'Wallet'].includes(title)
        ) {
          return null;
        }

        return (
          <Grid item key={title} xs={12} md={6} order={{ xs: index }}>
            <NavigationItem
              route={route}
              icon={icon}
              iconDesktop={iconDesktop}
              title={title}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Navigation;
