import { useState } from 'react';
import {
  Box,
  Breakpoint,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  SxProps,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

import { GREY, KOVO_GREY, theme } from 'context/ThemeProvider';

import Close from 'assets/img/icons/close.svg';

interface Props {
  children: React.ReactNode;
  dialogText: string | React.ReactNode;
  variant:
    | OverridableStringUnion<
        'inherit' | Variant,
        TypographyPropsVariantOverrides
      >
    | undefined;
  maxWidth?: Breakpoint;
  sx?: SxProps;
}

const PopupDialog: React.FC<Props> = ({
  children,
  dialogText,
  variant,
  maxWidth = 'sm',
  sx,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Link
        href="#"
        role="button"
        variant={variant}
        onClick={handleClick}
        sx={{ verticalAlign: 'baseline', ...sx }}
      >
        {children}
      </Link>

      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={maxWidth}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: theme.spacing(2.5),
            left: theme.spacing(2.5),
            height: '42px',
            width: '42px',
            backgroundColor: GREY,
            zIndex: '9999',

            '&:hover': {
              backgroundColor: KOVO_GREY,
            },
          }}
        >
          <Box component="img" src={Close} alt="✕" />
        </IconButton>

        <DialogContent sx={{ paddingTop: theme.spacing(10) }}>
          {dialogText}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopupDialog;
