import { useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import useOffersByCategoryQuery from 'hooks/queries/useOffersByCategoryQuery';
import { AuthContext } from 'context/AuthProvider';
import PageLoader from 'components/PageLoader';
import OfferCard from './OfferCard';

export interface Offer {
  id: string;
  name: string;
  type: 'credit_card' | 'loan' | 'other';
  offerConfig: {
    disclosure?: string;

    // credit card
    annualFee?: string;
    description?: string;
    imageUrl?: string;

    // loan
    titleImageUrl?: string;
    fundingRange?: string;
    loanTerm?: string;

    // both credit card and loan
    apr?: string;
    creditScoreRange?: string;
  };
  affiliateLink: string;
  categoryId: string;
  categoryName?: string;
}

const OffersList = () => {
  const { identityId } = useContext(AuthContext);

  const { data: offers, isLoading } = useOffersByCategoryQuery();

  if (isLoading) {
    return <PageLoader />;
  }

  if (!offers || offers.length === 0) {
    return (
      <Card variant="gradient">
        <CardContent>
          <Typography>
            We don't currently have any offers for the selected category. Go
            back and select another category.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid container spacing={2}>
      {offers?.map((offer) => (
        <Grid item xs={12} key={offer.id}>
          <OfferCard offer={offer} identityId={identityId} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OffersList;
