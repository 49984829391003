import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { DigitalService, DigitalServiceId } from 'types/schemas';

export const ID_PROTECTION_DIGITAL_SERVICE_ID = 'service_2';

export enum DigitalServiceBillingType {
  Subscription = 'subscription',
  OneTime = 'one_time',
}

function useGetDigitalService(digitalServiceId: DigitalServiceId) {
  const { identityId, username } = useContext(AuthContext);

  const getDigitalService = async () => {
    const digitalService = await API.get(
      'clientsService',
      `/v1/digital-services/${digitalServiceId}`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    return digitalService;
  };

  return useQuery<DigitalService>(['digital-service', digitalServiceId], () =>
    getDigitalService(),
  );
}

export default useGetDigitalService;
