interface KovoErrorOptions {
  internalMessage?: string;
  error?: Error | unknown;
  isSafeDisplayMessage?: boolean;
  displayMessage?: string;
}

export class KovoError extends Error {
  options: KovoErrorOptions;
  metadata: Record<string, unknown>;

  constructor(
    public message: string,
    options: KovoErrorOptions = {},
    metadata: Record<string, unknown> = {},
  ) {
    super(message);

    this.message = message;
    this.options = options;
    this.metadata = metadata;

    // Set the name of the error to match the class name
    this.name = this.constructor.name;

    if (options.error) {
      this.setError(options.error);
    }
  }

  /**
   * Details about the error that will be logged to Rollbar.
   * By default, the details object will include the message, stack, and metadata.
   * If there error on the KovoError options is an instance of an Error, the details
   * will include the original error's message and stack. In many cases, the KovoError
   * will wrap an error thrown by a 3rd party library, so we want to retain the context
   * of the original error.
   */
  get details() {
    const originalError = this.options.error;
    const isErrorType = originalError instanceof Error;
    return {
      ...this.metadata,
      message: this.message,
      stack: this.stack,
      displayMessage: this.displayMessage,
      originalError: this.options.error
        ? {
            message: isErrorType ? originalError.message : this.options.error,
            stack: isErrorType ? originalError.stack : undefined,
          }
        : undefined,
    };
  }

  get displayMessage() {
    if (this.options.displayMessage) {
      return this.options.displayMessage;
    }

    return this.options.isSafeDisplayMessage
      ? this.message
      : 'Something went wrong. Please try again.';
  }

  setError(error: unknown) {
    this.options = {
      error,
      ...this.options,
    };

    if (error instanceof Error) {
      this.stack = error.stack;
    }

    return this;
  }

  setDisplayMessage(displayMessage: string) {
    this.options = {
      ...this.options,
      displayMessage,
    };

    return this;
  }

  addMetadata(metadata: Record<string, unknown>) {
    this.metadata = {
      ...this.metadata,
      ...metadata,
    };

    return this;
  }

  exposeMessage() {
    this.options = {
      ...this.options,
      isSafeDisplayMessage: true,
    };

    return this;
  }
}

export class KovoUnauthenticatedError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}

export class KovoEmailVerificationError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}

export class KovoUserCreationError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}
