import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import { FormField } from './form';
import StateSelect from 'components/StateSelect';

export const ADDRESS_FORM_FIELDS: FormField[] = [
  {
    name: 'address',
    label: 'Street address',
    Component: AddressAutocomplete,
  },
  {
    name: 'address2',
    label: 'Apt number (optional)',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
    Component: StateSelect,
  },
  {
    name: 'zipcode',
    label: 'Zip code',
  },
];
