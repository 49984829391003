import Rollbar from 'rollbar';

const environment = import.meta.env.VITE_STAGE || '';
const accessToken = import.meta.env.VITE_ROLLBAR_TOKEN || '';
const rollbarEnabled =
  import.meta.env.VITE_ENABLE_ROLLBAR === 'true' &&
  !!import.meta.env.VITE_ROLLBAR_TOKEN;

const rollbar = new Rollbar({
  enabled: rollbarEnabled,
  accessToken,

  captureUncaught: true,
  addErrorContext: true,
  captureUnhandledRejections: true,

  /** also log Rollbar calls to console */
  verbose: environment !== 'prod',

  scrubFields: [
    'ssn',
    'phone',
    'birthday',
    'address',
    'address2',
    'state',
    'city',
    'zipcode',
    'monthlyIncome',
  ],

  scrubTelemetryInputs: true,

  payload: {
    environment,
    client: {
      javascript: {
        code_version: import.meta.env.VITE_COMMIT_REF,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

export { rollbar, rollbarEnabled };
