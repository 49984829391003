import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';

function usePayStripeInvoice() {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const submitPayment = async (invoiceId: string) => {
    const response = await API.post('billing', `/billing/payInvoice`, {
      body: {
        invoiceId,
      },
      ...amplifyRequestContext(identityId, username),
    });

    if (response.cardError) {
      throw new Error(response.cardError);
    }
  };

  const onSuccess = () => {
    queryClient.invalidateQueries('StripePaymentInfo');
  };

  return useMutation<void, Error, string>(submitPayment, { onSuccess });
}

export default usePayStripeInvoice;
