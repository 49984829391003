import { Link, Redirect } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useApplicationStatus from 'hooks/useApplicationStatus';
import { theme } from 'context/ThemeProvider';
import Page from 'components/Page';
import CheckoutPaymentUpdateInfo from 'components/CheckoutPaymentUpdateInfo';
import { ProductTypes } from 'types/schemas';
import { useProductConfig } from 'hooks/useProductConfig';
import { UpdatePaymentMethod } from 'components/UpdatePaymentMethod';
import withAccount from 'context/AccountProvider';

interface UpdatePaymentMethodPageProps {
  billingProvider: 'checkout' | 'stripe';
  productType: ProductTypes;
}

const UpdatePaymentMethodPage: React.FC<UpdatePaymentMethodPageProps> = ({
  billingProvider,
  productType,
}) => {
  const { data: applicationStatus, isLoading } = useApplicationStatus();
  const productConfig = useProductConfig(productType);

  /**
   * Redirect to the product info page if the application status is closed and the product is a loan installment.
   */
  if (
    !isLoading &&
    applicationStatus &&
    applicationStatus.applicationStatus === 'closedProductAccessRemains' &&
    productType === 'loan_installment'
  ) {
    return <Redirect to={productConfig.infoRoute} />;
  }

  return (
    <Page>
      <MuiLink
        component={Link}
        to={productConfig.infoRoute}
        underline="none"
        variant="body1"
        color={theme.palette.primary.main}
        marginBottom={theme.spacing(2)}
        display="inline-block"
      >
        <ArrowBackIcon
          sx={{ verticalAlign: 'top', marginRight: theme.spacing(1) }}
        />
        <Box component="strong">{productConfig.label}</Box>
      </MuiLink>

      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" gutterBottom>
          Update Payment Method
        </Typography>

        {billingProvider === 'checkout' && <CheckoutPaymentUpdateInfo />}

        <Card elevation={0}>
          <CardContent>
            <UpdatePaymentMethod
              productType={productType}
              billingProvider={billingProvider}
            />
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default withAccount(UpdatePaymentMethodPage);
