import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import useGetCompletedApplication from './queries/useGetCompletedApplication';
import { LoanProductId } from 'types/schemas';

interface DownloadUrlResponse {
  downloadUrl: string;
}

const SIXTY_SECONDS_IN_MILLISECONDS = 1000 * 60;

function useEsignDownloadUrlQuery(productId: LoanProductId, fileName: string) {
  const { identityId, username } = useContext(AuthContext);
  const { data } = useGetCompletedApplication(productId);
  const contractFileId = data?.completedApplication?.contractFileId;

  const getDownloadUrl = async () => {
    const { downloadUrl } = await API.post(
      'clientsService',
      `/v1/esign/${contractFileId}/download-url`,
      {
        body: { fileName: fileName },
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );
    return { downloadUrl };
  };

  return useQuery<DownloadUrlResponse>(
    [`esign-download-url-${contractFileId}`],
    () => getDownloadUrl(),
    {
      refetchOnWindowFocus: false,
      // the signed URL expires after 60s
      staleTime: SIXTY_SECONDS_IN_MILLISECONDS,
      refetchInterval: SIXTY_SECONDS_IN_MILLISECONDS,
      refetchIntervalInBackground: true,
      enabled: !!contractFileId,
    },
  );
}

export default useEsignDownloadUrlQuery;
