import { Box, Button, Grid, Typography } from '@mui/material';

import { GREEN, RED, theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useStripeStatus from 'hooks/useStripeStatus';
import NavigationItem from 'components/NavigationItem';

import InstallmentPlan from 'assets/img/icons/navigation/installment-plan.svg';
import InstallmentPlanLarge from 'assets/img/icons/navigation/installment-plan_large.svg';
import { COMPLETED_PAYMENTS_STATUSES } from 'components/StripePaymentInfo/StripePaymentInfo';

const StripeInstallmentPlanNavItem: React.FC = () => {
  const { data: stripeStatus } = useStripeStatus({ retry: false });

  const { data: applicationStatus } = useApplicationStatus();

  if (
    !stripeStatus ||
    !applicationStatus ||
    ['closedProductAccessRemains', 'upfrontPaymentCompleted'].includes(
      applicationStatus.applicationStatus,
    )
  ) {
    return null;
  }

  const isPastDue = !!stripeStatus.openInvoices;

  if (isPastDue) {
    return (
      <Grid item xs={12} md={6} order={{ xs: 0 }}>
        <NavigationItem
          route="/installment-plan"
          icon={InstallmentPlan}
          iconDesktop={InstallmentPlanLarge}
          title="Installment Plan"
          subtitle={
            <>
              <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
                Account Status:{' '}
                <Box component="strong" color={RED}>
                  Past Due
                </Box>
              </Typography>
              <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
                <strong>Missed payments can harm your credit</strong>
              </Typography>
            </>
          }
          ctaButton={
            <Button variant="contained" fullWidth>
              Pay Now
            </Button>
          }
        />
      </Grid>
    );
  }

  const paymentsComplete = COMPLETED_PAYMENTS_STATUSES.includes(
    applicationStatus.applicationStatus,
  );
  let status;

  if (paymentsComplete) {
    status = <Box component="strong">Paid in full</Box>;
  } else if (
    applicationStatus.applicationStatus === 'closedProductAccessRemains'
  ) {
    status = <Box component="strong">Closed</Box>;
  } else {
    status = (
      <Box component="strong" color={GREEN}>
        Current
      </Box>
    );
  }

  return (
    <Grid item xs={12} md={6} order={{ xs: 0 }}>
      <NavigationItem
        route="/installment-plan"
        icon={InstallmentPlan}
        iconDesktop={InstallmentPlanLarge}
        title="Installment Plan"
        subtitle={
          <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
            Account Status: {status}
          </Typography>
        }
      />
    </Grid>
  );
};

export default StripeInstallmentPlanNavItem;
