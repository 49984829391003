import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

import config from 'config';
import { InboundMobileAppMessage } from 'types';
import { loadScript } from 'helpers/load-script';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { AuthContext } from 'context/AuthProvider';
import {
  PaymentMode,
  WalletComponentProps,
} from 'components/CheckoutPaymentMethods/types';

import './GooglePayButton.css';
import { sendMessageToMobileApp } from 'helpers/mobile-app';

const CHECKOUT_PUBLIC_KEY = import.meta.env.VITE_CHECKOUT_PUBLIC_KEY!;
const CHECKOUT_DOMAIN = import.meta.env.VITE_CHECKOUT_DOMAIN!;

declare global {
  interface Window {
    googlePayClient: google.payments.api.PaymentsClient;
  }
}

const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
  {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'checkoutltd',
      gatewayMerchantId: CHECKOUT_PUBLIC_KEY,
    },
  };

const apiVersion = 2;
const apiVersionMinor = 0;

const allowedAuthMethods: google.payments.api.CardAuthMethod[] = [
  'PAN_ONLY',
  'CRYPTOGRAM_3DS',
];
const allowedCardNetworks: google.payments.api.CardNetwork[] = [
  'AMEX',
  'DISCOVER',
  'MASTERCARD',
  'VISA',
];

const baseCardPaymentMethod: google.payments.api.PaymentMethodSpecification = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: allowedAuthMethods,
    allowedCardNetworks: allowedCardNetworks,
  },
  tokenizationSpecification,
};

const baseRequest: Pick<
  google.payments.api.PaymentDataRequest,
  'apiVersion' | 'apiVersionMinor' | 'allowedPaymentMethods'
> = {
  apiVersion,
  apiVersionMinor,
  allowedPaymentMethods: [baseCardPaymentMethod],
};

const GooglePayButton: FC<WalletComponentProps> = ({
  onConfirmPaymentMethod,
  onLoad,
  disabled = false,
  amountInDollars,
  paymentMode,
  onError,
}) => {
  const [isInitializing, setIsInitializing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSupported, setIsSupported] = useState<boolean | undefined>(
    undefined,
  );
  const { identityId } = useContext(AuthContext);

  const isEnabled = !disabled;

  const ref = useRef<HTMLDivElement>(null);

  const finalizePayment = useCallback(
    async (paymentData: google.payments.api.PaymentData) => {
      try {
        const tokenizationData = paymentData.paymentMethodData.tokenizationData;

        const exchangeTokenRes = await axios.post<{
          token: string;
          scheme: string;
        }>(
          `${CHECKOUT_DOMAIN}/tokens`,
          {
            type: 'googlepay',
            token_data: JSON.parse(tokenizationData.token),
          },
          {
            headers: {
              Authorization: CHECKOUT_PUBLIC_KEY,
              'Content-Type': 'application/json',
            },
          },
        );

        await onConfirmPaymentMethod({
          token: exchangeTokenRes.data.token,
          walletType: 'google_pay',
          last4: paymentData.paymentMethodData.info?.cardDetails,
          scheme: exchangeTokenRes.data.scheme,
        });
      } catch (error) {
        if (error instanceof Error || typeof error === 'string') {
          onError(error);
        } else {
          onError(new Error('Something went wrong. Please try again'));
        }
      }
    },
    [onConfirmPaymentMethod, onError],
  );

  const onLoaded = useCallback(
    (isSupported: boolean) => {
      setIsSupported(isSupported);
      onLoad(isSupported);
      setIsInitializing(false);
    },
    [onLoad],
  );

  const renderGooglePayButton = useCallback(
    (
      googlePayClient: google.payments.api.PaymentsClient,
      onClick: (event: Event) => void,
    ) => {
      const button = googlePayClient.createButton({
        buttonColor: 'default',
        buttonType: 'short',
        buttonSizeMode: 'fill',
        onClick,
      });

      ref.current?.appendChild(button);
    },
    [],
  );

  const handleGooglePay = useCallback(async () => {
    try {
      if (!isSupported || !window.googlePayClient) {
        return;
      }

      setIsProcessing(true);

      const totalPrice = amountInDollars.toString();

      const paymentRequest: google.payments.api.PaymentDataRequest = {
        ...baseRequest,
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: totalPrice,
          totalPriceLabel: 'Starting now',
          countryCode: 'US',
          currencyCode: 'USD',
          checkoutOption:
            paymentMode === PaymentMode.UPDATE_METHOD
              ? undefined
              : 'COMPLETE_IMMEDIATE_PURCHASE',
          displayItems: [
            {
              label: 'Monthly payment',
              type: 'SUBTOTAL',
              price:
                paymentMode === PaymentMode.UPDATE_METHOD ? '0.00' : '10.00',
            },
          ],
        },
        callbackIntents:
          paymentMode === PaymentMode.UPDATE_METHOD
            ? undefined
            : ['PAYMENT_AUTHORIZATION'],
        merchantInfo: {
          merchantId: import.meta.env.VITE_GOOGLE_PAY_MERCHANT_ID!,
          merchantName: 'Kovo',
        },
      };

      if (window.ReactNativeWebView) {
        // Send the payment request to the mobile app
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.click.google_pay',
          body: paymentRequest,
        });

        return;
      }

      const paymentData = await window.googlePayClient.loadPaymentData(
        paymentRequest,
      );

      await finalizePayment(paymentData);
    } catch (error) {
      const typedError = error as Error;

      // User closed the Payment Request UI.
      if (typedError.message === 'User closed the Payment Request UI.') {
        return;
      }

      rollbarEnabled &&
        rollbar.error(
          `Error processing Google Pay payment: ${typedError.message}`,
          {
            identityId,
            error,
          },
        );
    } finally {
      if (!window.ReactNativeWebView) {
        setIsProcessing(false);
        renderGooglePayButton(window.googlePayClient, handleGooglePay);
      }
    }
  }, [
    isSupported,
    paymentMode,
    finalizePayment,
    identityId,
    amountInDollars,
    renderGooglePayButton,
  ]);

  const loadGooglePay = useCallback(async () => {
    try {
      setIsInitializing(true);

      await loadScript('https://pay.google.com/gp/p/js/pay.js');

      const PaymentsClient = window.google?.payments?.api?.PaymentsClient;

      if (!PaymentsClient) {
        onLoaded(false);

        return;
      }

      const googlePayClient = new PaymentsClient({
        environment: config.VITE_STAGE === 'prod' ? 'PRODUCTION' : 'TEST',
        paymentDataCallbacks:
          paymentMode === PaymentMode.UPDATE_METHOD
            ? undefined
            : {
                async onPaymentAuthorized() {
                  return {
                    transactionState: 'SUCCESS',
                  };
                },
              },
      });

      const isReadyToPay = await googlePayClient.isReadyToPay({
        ...baseRequest,
        existingPaymentMethodRequired: false,
      });

      if (!isReadyToPay) {
        onLoaded(false);

        return;
      }

      window.googlePayClient = googlePayClient;

      renderGooglePayButton(googlePayClient, handleGooglePay);

      onLoaded(true);
    } catch (error) {
      onLoaded(false);

      const typedError = error as Error;
      rollbarEnabled &&
        rollbar.error(`Error loading Google Pay: ${typedError.message}`, {
          identityId,
          error,
        });
    }
  }, [
    renderGooglePayButton,
    paymentMode,
    identityId,
    onLoaded,
    handleGooglePay,
  ]);

  useEffect(() => {
    if (isSupported !== undefined) return;
    if (isInitializing) return;

    if (isEnabled === false) {
      onLoaded(false);

      return;
    }

    // Google Pay is not supported on iOS through the webview
    if (
      window.KovoAppCustomInfo &&
      window.KovoAppCustomInfo.platform !== 'android'
    ) {
      onLoaded(false);
      return;
    }

    loadGooglePay();
  }, [isInitializing, isSupported, isEnabled, loadGooglePay, onLoaded]);

  useEffect(() => {
    function handleMobileAppMessage(this: Window, event: MessageEvent<any>) {
      const message: InboundMobileAppMessage = event.data;

      switch (message.eventType) {
        case 'kovo.mobile_app.complete.google_pay':
          finalizePayment(message.body);
          break;

        default:
          break;
      }
    }

    window.addEventListener('message', handleMobileAppMessage);

    return () => {
      window.removeEventListener('message', handleMobileAppMessage);
    };
  }, [finalizePayment]);

  if (isProcessing) {
    return (
      <LoadingButton
        variant="contained"
        loading
        fullWidth
        sx={{ height: '55px', borderRadius: '4px' }}
      />
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {isInitializing && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            cursor: 'not-allowed',
            zIndex: 9,
          }}
        />
      )}

      <Box
        sx={{ display: isSupported ? 'block' : 'none', fontSize: '12px' }}
        id="google-pay-button"
        onClick={handleGooglePay}
        ref={ref}
      />
    </Box>
  );
};

export default GooglePayButton;
