import { useEffect } from 'react';

const SsnPolicyPage: React.FC = () => {
  useEffect(() => {
    // /ssn is decprecated
    window.location.href = 'https://kovocredit.com/legal/privacy';
  }, []);

  return null;
};

export default SsnPolicyPage;
