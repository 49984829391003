import { Box, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useContractHtmlQuery from 'hooks/useContractHtmlQuery';
import PageLoader from 'components/PageLoader';
import { wrapHtmlBodyInDiv } from 'helpers/html';
import { LoanProductId } from 'types/schemas';

export const CONTRACT_WIDTH = '342px';

interface Props {
  productId: LoanProductId;
}

const EsignContract: React.FC<Props> = ({ productId }) => {
  const { data, isLoading, error } = useContractHtmlQuery(productId);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <Typography>There was an error. Please refresh the page.</Typography>
    );
  }

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: wrapHtmlBodyInDiv(data, 'contract') }}
      data-testid="product-contract"
      sx={{
        padding: theme.spacing(1),
        fontFamily: 'Inter, sans-serif',

        h2: {
          fontSize: '16px',
        },
        h3: {
          fontSize: '14px',
        },
        h4: {
          fontSize: '12px',
        },
        p: {
          fontSize: '12px',
        },

        '> div': {
          maxWidth: CONTRACT_WIDTH,
          margin: '0 auto',

          [theme.breakpoints.up('md')]: {
            maxWidth: 'none',
          },
        },
      }}
    />
  );
};

export default EsignContract;
