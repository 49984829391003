import { Amplify } from 'aws-amplify';
import config from 'config';

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      ...config.amplifyAuth,
    },
    API: {
      endpoints: [
        {
          name: 'installments',
          endpoint: config.apiGateway.installments.URL,
          region: config.apiGateway.installments.REGION,
        },
        {
          name: 'billing',
          endpoint: config.apiGateway.billing.URL,
          region: config.apiGateway.billing.REGION,
        },
        {
          name: 'clientsService',
          endpoint: config.apiGateway.clientsService.URL,
          region: config.apiGateway.clientsService.REGION,
        },
        {
          name: 'appsService',
          endpoint: config.apiGateway.appsService.URL,
          region: config.apiGateway.appsService.REGION,
        },
      ],
    },
  });
};
