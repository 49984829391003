import { ComponentType } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/AuthProvider';
import type { LocationState } from 'context/Location';
import { useGlobalRedirectContext } from 'context/GlobalRedirectContext';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import { AxiosError } from 'axios';
import PageLoader from 'components/PageLoader';
import { ROUTES } from 'components/Routes';

interface Props {
  path: string;
  component: ComponentType;
  /* for unit testing only */
  testNowTimestamp?: Date;
}

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

const AuthRoute: React.FC<Props> = ({ path, component, testNowTimestamp }) => {
  const { pathname } = useLocation<LocationState | undefined>();
  const {
    currentUser: cognitoUser,
    pendingEmail,
    pendingEmailTimestamp,
  } = useAuthContext();
  const {
    data: user,
    error,
    isFetched,
  } = useCurrentUser({
    retry: 2,
  });
  const globalRedirectContext = useGlobalRedirectContext();

  /**
   * If the cognito user hasn't yet loaded at all, we do not want to redirect as we do not yet
   * know if they are authenticated or not. The cognito user will be null once the AuthContext
   * has loaded and the user is not authenticated.
   */
  if (cognitoUser === undefined) {
    return null;
  }

  /**
   * If the cognito user is null, we redirect to the login page. This is because the user is not
   * authenticated.
   */
  if (!cognitoUser) {
    return (
      <Redirect
        to={{ pathname: ROUTES.LOGIN_ROUTE, state: { redirectPath: pathname } }}
      />
    );
  }

  const isAxiosError = error instanceof AxiosError;
  const errorResponseStatus = isAxiosError ? error.response?.status : null;

  /**
   * Redirect to the 500 page if there is an error that is not an Axios error or if the error status
   * is 500. The reason we redirect if it's not an Axios error is because it's likely an error that
   * we did not anticipate and we want to show the user a generic error page.
   */
  if (error && (!isAxiosError || errorResponseStatus === 500)) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.ERROR_500_ROUTE,
          state: { redirectPath: pathname },
        }}
      />
    );
  }

  const isSignupPendingRoute = pathname === ROUTES.SIGNUP_PENDING_ROUTE;

  /**
   * If the user is authenticated with Cognito, but there is no user record in our database, we redirect to the
   * confirming signup page. This page will handle polling the backend to create the user record.
   */
  if (!user && isFetched) {
    if (isSignupPendingRoute) {
      return <Route path={path} component={component} />;
    }

    return (
      <Redirect
        to={{
          pathname: ROUTES.SIGNUP_PENDING_ROUTE,
          state: { redirectPath: pathname },
        }}
      />
    );
  }

  /**
   * If the user has not yet loaded, we show a loading spinner.
   */
  if (!user) {
    return <PageLoader />;
  }

  /**
   * If the user exists but the route is the signup pending route, we redirect to the home page.
   */
  if (isSignupPendingRoute) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.HOME,
        }}
      />
    );
  }

  console.log('evaluating redirect for confirmation', pathname);

  /*
   * If the user has recently updated their email address without confirming it before hard refreshing
   * the application, redirect to the email verification screen with a redirectPath back to the current
   * route.
   *
   * Right now we define recently as 24 hours since the email verification code expires in 24 hours. However,
   * even after this period, the user can still go to the settings page to finish the verification.
   */
  const now = (testNowTimestamp || new Date()).getTime();
  const ago = new Date(pendingEmailTimestamp || now).getTime();
  const shouldGoToConfirmation =
    /**
     * If they have an email update pending
     */
    pendingEmail &&
    pendingEmailTimestamp &&
    /**
     * And it is within 24 hours of when that email update was originally
     * triggered
     */
    now < ago + TWENTY_FOUR_HOURS &&
    /**
     * And we are not in the email confirmation flow already
     */
    ![
      ROUTES.SETTINGS_UPDATE_EMAIL_CONFIRMATION,
      ROUTES.SETTINGS_UPDATE_EMAIL,
    ].includes(pathname) &&
    /**
     * And we haven't done this already for this load of the application
     */
    !globalRedirectContext.updateEmailConfirmation;

  if (shouldGoToConfirmation) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.SETTINGS_UPDATE_EMAIL_CONFIRMATION,
          search: new URLSearchParams(
            `redirectPath=${encodeURIComponent(pathname)}`,
          ).toString(),
          state: {
            oneTimeRedirect: true,
          },
        }}
      />
    );
  }

  /**
   * TODO: enable this when we require a user to confirm their email when they
   * change it.
   */
  // if (!emailVerified) {
  //   return <Redirect to={{ pathname: '/signup-confirm' }} />;
  // }

  return <Route path={path} component={component} />;
};

export default AuthRoute;
