import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { updateEmailAttribute } from 'libs/signupHelpers/updateEmailAttribute';
import { useGlobalRedirectContext } from 'context/GlobalRedirectContext';

export interface UpdateEmailAttributeParams {
  email: string;
}

export const useUpdateEmailAttribute = () => {
  const { currentUser, authChangeCallback } = useAuthContext();
  const history = useHistory();
  const { setKey } = useGlobalRedirectContext();

  const updateEmailAttributeFn = async ({
    email,
  }: UpdateEmailAttributeParams) => {
    try {
      if (!currentUser) {
        throw new KovoError(
          'Please log in before updating your email address.',
        );
      }

      const cognitoUser = await updateEmailAttribute(email);
      const authDetails = await getCognitoUserAuthDetails(cognitoUser);
      await authChangeCallback(cognitoUser, authDetails);

      console.log('history', JSON.parse(JSON.stringify(history.location)));

      if (history.location.search) {
        history.push({
          pathname: ROUTES.SETTINGS_UPDATE_EMAIL_CONFIRMATION,
          search: history.location.search,
        });
      } else {
        history.push(ROUTES.SETTINGS_UPDATE_EMAIL_CONFIRMATION);
      }

      setKey('updateEmailConfirmation', true);
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error updating email address', { error });
    }
  };

  const res = useMutation<void, KovoError, UpdateEmailAttributeParams>({
    mutationFn: updateEmailAttributeFn,
    mutationKey: 'updateEmailAttribute',
  });

  return res;
};
