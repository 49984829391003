import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { AuthContext } from 'context/AuthProvider';
import OnboardingPage from 'components/OnboardingPage';
import SignUpConfirmForm from 'components/SignUpConfirmForm';
import ReferralCodeValidateBanner from 'components/ReferralCodeValidateBanner';

const SignUpConfirmPage: React.FC = () => {
  const { currentUser, logInCompleting, preConfirmationState } =
    useContext(AuthContext);

  if (!currentUser && !logInCompleting) {
    return <Redirect to="/login" />;
  }

  if (!preConfirmationState) {
    return <Redirect to="/" />;
  }

  /**
   * If the user is logged in and this re-render was not triggered from the user
   * completing the sign up flow, redirect to home.
   *
   * TODO: re-enable this once we start mandating that a new email for a user
   * be confirmed.
   */
  // if (currentUser && emailVerified && !logInCompleting) {
  //   return <Redirect to="/" />;
  // }

  return (
    <OnboardingPage
      onboardingStep={1}
      supportSubject="Signup confirm"
      openSupportAsLink
    >
      <ReferralCodeValidateBanner />

      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Verify your email
      </Typography>

      <SignUpConfirmForm />
    </OnboardingPage>
  );
};

export default SignUpConfirmPage;
