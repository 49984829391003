import { Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import OnboardingPage from 'components/OnboardingPage';
import ChargeAccountContractForm from 'components/charge-account/ChargeAccountContractForm';
import ChargeAccountRedirect from 'components/charge-account/ChargeAccountRedirect';

const ChargeAccountContractPage: React.FC = () => {
  return (
    <>
      <ChargeAccountRedirect />

      <OnboardingPage>
        <Typography
          variant="h1"
          sx={{
            // don't let the contract's CSS overwrite these values
            marginBottom: `${theme.spacing(1.5)} !important`,
            padding: '0 !important',
            textAlign: 'left !important',
          }}
        >
          Review agreement
        </Typography>

        <ChargeAccountContractForm />
      </OnboardingPage>
    </>
  );
};

export default ChargeAccountContractPage;
