import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';

import useCreditScore from 'hooks/useCreditScore';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useGetCreditScores from 'hooks/queries/useGetCreditScores';
import CreditScoreCheckoutNote from 'components/CreditScoreCheckoutNote';
import CreditScoreStripeNote from 'components/CreditScoreStripeNote';
import CreditScoreIncreaseBanner from 'components/CreditScoreIncreaseBanner';

export const CREDIT_INCREASE_THRESHOLD = 10;

const CreditScoreInfo: React.FC = () => {
  const { data: creditScoreData, isLoading: isLoadingCreditScore } =
    useCreditScore();

  const { data: applicationData, isLoading: isLoadingApplication } =
    useApplicationStatus();

  const { data: creditScoresData, isLoading: isLoadingCreditScores } =
    useGetCreditScores();

  const isLoading =
    isLoadingCreditScore || isLoadingApplication || isLoadingCreditScores;

  const isNoLongerBeingUpdated =
    applicationData?.applicationStatus &&
    ['upfrontPaymentCompleted', 'paidEarly', 'paidOnSchedule'].includes(
      applicationData.applicationStatus,
    );

  const isCheckoutUser =
    applicationData?.applicationDetails?.provider === 'checkout' ||
    applicationData?.applicationDetails?.billingProvider === 'checkout';

  if (isLoading) {
    return <Skeleton variant="rounded" height={120} />;
  }

  if (
    creditScoresData?.scoreIncrease &&
    creditScoresData.scoreIncrease >= CREDIT_INCREASE_THRESHOLD
  ) {
    return (
      <CreditScoreIncreaseBanner
        scoreIncrease={creditScoresData.scoreIncrease}
      />
    );
  }

  if (isNoLongerBeingUpdated) {
    return (
      <Card variant="gradient">
        <CardContent>
          <Typography>
            <Box component="strong">Welcome back!</Box> Please be aware that
            your score is no longer being updated after your account has been
            closed. However, you can still view your credit score from the last
            time we updated it.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (creditScoreData && !creditScoreData.creditScore) {
    return (
      <Card variant="gradient">
        <CardContent>
          <Typography>
            <Box component="strong">Check back soon!</Box> Your credit score
            will be updated once a month. We don’t have your score just yet, so
            please check back soon. It usually takes 2-6 weeks.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {isCheckoutUser && <CreditScoreCheckoutNote />}

      {!isCheckoutUser && <CreditScoreStripeNote />}
    </>
  );
};

export default CreditScoreInfo;
