import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { theme } from 'context/ThemeProvider';
import { AddressData } from '../InvalidAddressDialog';
import KovoDialog from 'components/KovoDialog';

interface DialogProps extends ContentProps {
  open: boolean;
  onClose: () => void;
}

interface ContentProps {
  originalAddress?: AddressData;
  formattedAddress?: AddressData;
  onConfirm: (values: AddressData) => void;
}

export const RecommendedAddressDialogContent: React.FC<ContentProps> = ({
  originalAddress,
  formattedAddress,
  onConfirm,
}) => {
  const handleConfirm = () => {
    if (!formattedAddress) {
      throw new Error('Formatted address is undefined');
    }

    onConfirm(formattedAddress);
  };

  return (
    <>
      <DialogTitle variant="h4">Confirm your address</DialogTitle>

      <DialogContent>
        <Typography marginBottom={theme.spacing(2)}>
          Review the recommended changes.
        </Typography>

        <Typography marginBottom={theme.spacing(1)}>
          <strong>What you entered:</strong>
        </Typography>
        <Card variant="outlined" sx={{ marginBottom: theme.spacing(2) }}>
          <CardContent>
            <Typography>{originalAddress?.address}</Typography>
            {originalAddress?.address2 && (
              <Typography>{originalAddress?.address2}</Typography>
            )}
            <Typography>
              {originalAddress?.city}, {originalAddress?.state}{' '}
              {originalAddress?.zipcode}
            </Typography>
          </CardContent>
        </Card>

        <Typography marginBottom={theme.spacing(1)}>
          <strong>Recommended:</strong>
        </Typography>
        <Card
          variant="outlined"
          sx={{ boxShadow: `0 0 0 2px ${theme.palette.primary.main} inset` }}
        >
          <CardContent>
            <Typography>{formattedAddress?.address}</Typography>
            {formattedAddress?.address2 && (
              <Typography>{formattedAddress?.address2}</Typography>
            )}
            <Typography>
              {formattedAddress?.city}, {formattedAddress?.state}{' '}
              {formattedAddress?.zipcode}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleConfirm} fullWidth>
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

const RecommendedAddressDialog: React.FC<DialogProps> = ({
  open,
  originalAddress,
  formattedAddress,
  onConfirm,
  onClose,
}) => {
  return (
    <KovoDialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      closeIcon={<ArrowBackIcon />}
      sx={{ margin: theme.spacing(-2) }}
    >
      <RecommendedAddressDialogContent
        originalAddress={originalAddress}
        formattedAddress={formattedAddress}
        onConfirm={onConfirm}
      />
    </KovoDialog>
  );
};

export default RecommendedAddressDialog;
