import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, Box, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useChangePassword from 'hooks/useChangePassword';
import { theme } from 'context/ThemeProvider';
import { AuthContext } from 'context/AuthProvider';
import TextField from 'components/shared/TextField';
import PasswordInputWithStrength from 'components/PasswordInputWithStrength';

interface Props {
  onSuccess?: () => void;
}

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const { currentUser } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibilityClick = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = ({ oldPassword, newPassword }: FormValues) => {
    mutate({ oldPassword, newPassword });
  };

  const {
    mutate,
    isLoading,
    error: serverError,
    isSuccess,
  } = useChangePassword(currentUser, { onSuccess });

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema: yup.object().shape({
        oldPassword: yup.string().required('Previous password is required'),
        newPassword: yup.string().required('New password is required'),
        confirmPassword: yup
          .string()
          .required('Passwords must match')
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError.message}
        </Alert>
      )}

      {isSuccess && (
        <Alert icon={false} sx={{ marginBottom: theme.spacing(5) }}>
          Your password was changed successfully.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gap: theme.spacing(3),
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(2),
          }}
        >
          <TextField
            name="oldPassword"
            autoComplete="current-password"
            type="password"
            label="Previous password"
            error={!!(touched.oldPassword && errors.oldPassword)}
            errorText={errors.oldPassword}
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PasswordInputWithStrength
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            label="New password"
            error={!!(touched.newPassword && errors.newPassword)}
            errorText={errors.newPassword}
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            endAdornment={
              <IconButton
                edge="end"
                onClick={handlePasswordVisibilityClick}
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            }
          />

          <TextField
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            label="Confirm new password"
            error={!!(touched.confirmPassword && errors.confirmPassword)}
            errorText={errors.confirmPassword}
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            endAdornment={
              <IconButton
                edge="end"
                onClick={handlePasswordVisibilityClick}
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            }
          />
        </Box>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Confirm
        </LoadingButton>
      </form>
    </>
  );
};

export default ChangePasswordForm;
