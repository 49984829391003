import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import OnboardingPage from 'components/OnboardingPage';
import CreditEducationLogo from 'assets/img/icons/navigation/courses.svg';
import { GREY, KOVO_LIGHT_GREEN, theme } from 'context/ThemeProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { sendMessageToMobileApp } from 'helpers/mobile-app';

const EDUCATION_FAQS = [
  {
    categoryTitle: 'Understanding Credit',
    categoryContents: [
      {
        title: 'What is a credit score and why is it important?',
        content:
          'A credit score is a numerical value based on your credit history that lenders use to evaluate your creditworthiness. It influences your ability to obtain loans, credit card approvals, and favorable interest rates, impacting financial opportunities and savings.',
      },
      {
        title: 'How are credit scores calculated?',
        content:
          'Credit scores are calculated using five key factors: payment history (35%), credit utilization (30%), length of credit history (15%), new credit (10%), and credit mix (10%). Paying bills on time and keeping balances low are crucial for a good score.',
      },
      {
        title:
          'What is the difference between a credit report and a credit score?',
        content:
          'A credit report is a detailed document showing your credit history, including accounts opened, payment history, and debts, while a credit score is a numerical value derived from your credit report, summarizing your creditworthiness. Think of your credit report as the full story of your credit history, and the credit score as the summary or grade based on that story.',
      },
    ],
  },
  {
    categoryTitle: 'Building Good Credit',
    categoryContents: [
      {
        title: 'How can you start building credit?',
        content: [
          'Start by applying for a credit builder account. Making timely payments on an installment or revolving credit account can help establish credit history.',
          'With Kovo, your monthly payments are reported as loan repayments to all 4 credit bureaus. Making these payments on-time is key to building credit, since payment history is the single most important factor that contributes to your credit score.',
        ],
      },
      {
        title: 'What are the best ways to manage credit wisely?',
        content: [
          'Common challenges in building credit include high debt, missed payments, and a thin credit file. To overcome these:',
          'Reduce Debt: Aim to lower your credit utilization by paying down balances, especially on credit cards.',
          'On-Time Payments: Ensure all bills are paid on time, possibly through setting up automatic payments.',
          'Build Credit History: Consider tools like Kovo’s credit accounts to establish or expand your credit file.',
          'Staying disciplined with these steps can gradually improve your credit score.',
        ],
      },
      {
        title:
          'How long does it take to see improvements in your credit score?',
        content:
          'Improvements in your credit score can be seen within a few months of responsible credit behavior, such as reducing debt and making timely payments. Significant changes often require six months to a year of consistent effort. Patience and disciplined financial habits are key to seeing progress.',
      },
    ],
  },
  {
    categoryTitle: 'About Kovo’s Credit Services',
    categoryContents: [
      {
        title: 'How is Kovo better than other credit building tools?',
        content:
          'We report your Kovo credit accounts to all 4 credit bureaus. This helps you get the most out of the on-time payments and low credit utilization you maintain with us. Combined with monthly FICO® Score monitoring and credit advice tailored to your specific financial situation, we’re here to make credit accessible and understandable for you.',
      },
      {
        title: 'Are there any fees?',
        content: [
          'Nope. We don’t charge hidden fees.',
          'No setup fees.',
          'No membership fees.',
          'No annual fees.',
          'No late fees.',
          'No failed payment fees.',
          'No processing fees.',
          'No prepayment fees.',
          'No interest charges either.',
        ],
      },
      {
        title: 'Can Kovo help if you have bad credit or no credit?',
        content:
          'Yes, Kovo can be beneficial for individuals with bad credit or no credit. Making consistent timely payments on your Kovo credit accounts and keeping track of your credit score is especially helpful to rebuild or establish your credit profile.',
      },
    ],
  },
  {
    categoryTitle: 'Using the Kovo App',
    categoryContents: [
      {
        title: 'How can you track your progress in the app?',
        content:
          'You can track your credit-building progress through features that display your account status, payment history, and monthly FICO® Score. These tools give you a comprehensive view of your financial habits and credit health over time, helping you understand and improve your credit score.',
      },
      {
        title:
          'What support does the app offer if you have questions or problems?',
        content:
          'The app offers 24/7 support with a chat-based interface, and our team of credit experts is here to help with any questions you may have. Building credit with Kovo is easy, since there is a human in the loop ready to help when you need it.',
      },
    ],
  },
];

const onHeaderBackButtonClick = () => {
  if (window.ReactNativeWebView) {
    sendMessageToMobileApp({
      eventType: 'kovo.webapp.credit_education.back',
    });
  }
};

const CreditEducation = () => {
  return (
    <OnboardingPage
      maxWidth="90%"
      centerLogo
      showBackButton
      onBackButtonClick={
        window.ReactNativeWebView ? onHeaderBackButtonClick : undefined
      }
    >
      <Typography
        variant="h1"
        marginBottom={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          component="img"
          src={CreditEducationLogo}
          alt=""
          height={40}
          sx={{
            marginRight: theme.spacing(2),
          }}
        />
        Credit Education
      </Typography>
      <Typography variant="body1" marginBottom={3}>
        Understand what credit is, how to build it, and how Kovo helps.
      </Typography>

      {EDUCATION_FAQS.map(({ categoryTitle, categoryContents }, index) => (
        <Accordion
          key={categoryTitle}
          sx={{
            boxShadow: 'none',
            marginBottom:
              index !== EDUCATION_FAQS.length - 1 ? theme.spacing(2) : 0,

            '::before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            sx={{
              backgroundColor: KOVO_LIGHT_GREEN,
              fontWeight: 'bold',
              borderRadius: '8px',

              minHeight: 0,

              '&.Mui-expanded': {
                minHeight: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginBottom: '1px',
              },

              '.MuiAccordionSummary-content': {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              },
            }}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {categoryTitle}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
            }}
          >
            {categoryContents.map(({ title, content }, index) => (
              <Accordion
                key={title}
                sx={{
                  boxShadow: 'none',
                  '::before': {
                    display: 'none',
                  },

                  marginBottom: '1px',

                  '&.Mui-expanded': {
                    margin: 0,
                    marginBottom: '1px',
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: GREY,
                    fontWeight: 'bold',
                    borderBottomLeftRadius:
                      index === categoryContents.length - 1 ? '8px' : 0,
                    borderBottomRightRadius:
                      index === categoryContents.length - 1 ? '8px' : 0,
                    borderColor: 'white',

                    '.MuiAccordionSummary-content': {
                      marginY: theme.spacing(2),
                    },

                    '&.Mui-expanded': {
                      minHeight: 0,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      '.MuiAccordionSummary-content': {
                        marginTop: theme.spacing(2),
                        marginBottom: '12px',
                      },
                    },
                  }}
                  aria-controls="subpanel1-content"
                  id="subpanel1-header"
                >
                  {title}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: GREY,
                    borderBottomLeftRadius:
                      index === categoryContents.length - 1 ? '8px' : 0,
                    borderBottomRightRadius:
                      index === categoryContents.length - 1 ? '8px' : 0,
                  }}
                >
                  {typeof content === 'string' ? (
                    <Typography variant="body1">{content}</Typography>
                  ) : (
                    content.map((paragraph, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        marginBottom={
                          index !== content.length - 1 ? theme.spacing(2) : 0
                        }
                      >
                        {paragraph}
                      </Typography>
                    ))
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </OnboardingPage>
  );
};

export default CreditEducation;
