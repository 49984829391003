import useCreateReview from 'hooks/mutations/useCreateReview';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import AppReviewDialogView from './AppReviewDialogView';
import { sendMessageToMobileApp } from 'helpers/mobile-app';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const TRUST_PILOT_FALLBACK_URL =
  'https://www.trustpilot.com/evaluate/kovocredit.com';
const FEEDBACK_SURVEY_URL =
  'https://forms.reform.app/kovo/customer-feedback/xrp713';

const AppReviewDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { data: userData } = useCurrentUser();

  const { mutate, isLoading } = useCreateReview();

  const handleClick = async (rating: 0 | 1) => {
    mutate({
      ratingType: 'binary',
      rating,
      type: 'in_app',
      platform: 'web',
    });

    if (rating === 1) {
      if (window.ReactNativeWebView) {
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.click.rate_us',
        });
      } else {
        window
          ?.open(userData?.trustPilotUrl || TRUST_PILOT_FALLBACK_URL, '_blank')
          ?.focus();
      }
    } else if (rating === 0) {
      if (window.ReactNativeWebView) {
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.click.feedback_survey',
        });
      } else {
        window?.open(FEEDBACK_SURVEY_URL, '_blank')?.focus();
      }
    }
  };

  return (
    <AppReviewDialogView
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      handleClick={handleClick}
    />
  );
};

export default AppReviewDialog;
