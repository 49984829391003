import { Box, Grid } from '@mui/material';

import PageLoader from 'components/PageLoader';
import CourseComponent from 'components/Course';
import useGetCourses, { Course } from 'hooks/queries/useGetCourses';

const CourseList: React.FC = () => {
  const { data: courses, isLoading } = useGetCourses();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Box data-testid="course-list">
      <Grid container spacing={2}>
        {(courses || []).map((course: Course) => {
          return (
            <Grid item xs={12} key={course.id}>
              <CourseComponent course={course} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CourseList;
