import { useContext } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Alert, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import TextField from 'components/shared/TextField';
import { LocationState } from 'components/SignUpConfirmForm';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { normalizeEmail } from 'helpers/utils';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';

interface FormValues {
  email: string;
  password: string;
}

const LogInForm: React.FC = () => {
  const history = useHistory();

  const { state: routerState } = useLocation<LocationState>();

  const { authChangeCallback } = useAuthContext();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Email is required'),
        password: yup.string().required('Password is required'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  /**
   * Initially, we were not lowercasing the email before sending it to Cognito.
   * This resulted in a bunch of customer complaints that they could not log in with their
   * email that they specified because they had the letter casing wrong (I insteaed of i, a instead of A).
   * This login flow attempts to use the provided input, but also tries the email lowercased.
   * New signups have the email lowercased, but we cannot assume that the old users are migrated to have
   * lowercase email usernames.
   */
  const logInUser = async ({ email, password }: FormValues) => {
    let user;
    let successEmail = '';

    try {
      try {
        successEmail = email;
        user = await Auth.signIn({ username: email, password });
      } catch (error) {
        successEmail = normalizeEmail(email);
        user = await Auth.signIn({ username: successEmail, password });
      }
    } catch (error) {
      if (error instanceof Error) {
        const name = error.name;

        if (name === 'UserNotConfirmedException' && successEmail) {
          return {
            email: successEmail,
            password,
          };
        }
      }

      throw error;
    }

    const authDetails = await getCognitoUserAuthDetails(user);

    return {
      user,
      userDetails: authDetails,
      email: successEmail,
      password,
    };
  };

  const onSuccess = ({
    user,
    userDetails,
    email,
    password,
  }: {
    user?: CognitoUser;
    userDetails?: UserAuthDetails;
    email: string;
    password: string;
  }) => {
    if (!user && !userDetails) {
      authChangeCallback(null, null, { email, password }, true);
      history.replace('/signup-confirm', {
        redirectPath: routerState?.redirectPath,
      });
      return;
    }

    if (user && userDetails) {
      authChangeCallback(user, userDetails, undefined, true);

      /**
       * TODO: enable this when we require a user to confirm their changed
       * email address
       */
      // if (userDetails.emailVerified) {
      //   authChangeCallback(user, userDetails, undefined);
      //   history.replace('/signup-confirm', {
      //     redirectPath: routerState?.redirectPath,
      //   });
      //   return;
      // } else {
      //   authChangeCallback(
      //     user,
      //     userDetails,
      //     {
      //       email,
      //       password,
      //     },
      //     true,
      //   );

      //   if (routerState?.redirectPath) {
      //     history.replace(routerState.redirectPath);
      //   } else {
      //     history.replace('/');
      //   }
      //   return;
      // }

      // send message to mobile app letting it know that the user has logged in
      if (window.ReactNativeWebView) {
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.complete.log_in',
        });
      }

      if (routerState?.redirectPath) {
        history.replace(routerState.redirectPath);
      } else {
        history.replace('/');
      }

      return;
    }

    if (rollbarEnabled) {
      rollbar.error('Unknown login state', {
        email,
      });
    }

    throw new Error('Unknown login state');
  };

  const {
    mutate,
    error: serverError,
    isLoading,
  } = useMutation<
    {
      user?: CognitoUser;
      userDetails?: UserAuthDetails;
      email: string;
      password: string;
    },
    Error,
    FormValues
  >(logInUser, { onSuccess });

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError?.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} marginBottom={theme.spacing(3)}>
          <Grid item xs={12}>
            <TextField
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              error={!!(touched.email && errors.email)}
              errorText={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              error={!!(touched.password && errors.password)}
              errorText={errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Log In
        </LoadingButton>
      </form>
    </>
  );
};

export default LogInForm;
