import { Redirect } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import useApplicationStatus from 'hooks/useApplicationStatus';
import { theme } from 'context/ThemeProvider';
import Page from 'components/Page';
import CourseList from 'components/CourseList';

export const COURSES_STATUSES = [
  'completed',
  'upfrontPaymentCompleted',
  'paidEarly',
  'closedProductAccessRemains',
];

const Courses: React.FC = () => {
  const { data, isFetching, isLoading } = useApplicationStatus();

  if (isLoading) {
    return null;
  }

  if (
    !isFetching &&
    data &&
    !COURSES_STATUSES.includes(data.applicationStatus)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(1)}>
          Courses
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          Get smart in skills that help you succeed in a fast-changing world.
        </Typography>
      </Box>

      <CourseList />
    </Page>
  );
};

export default Courses;
