import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';
import { AxiosError } from 'axios';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { GetReviewsResponse, Review } from 'hooks/queries/useGetReviews';

type MutationArgs = Omit<Review, 'userId' | 'sk' | 'createdAt' | 'updatedAt'>;

function useCreateReview() {
  const queryClient = useQueryClient();

  const { identityId, username } = useContext(AuthContext);

  const createReview = async ({
    ratingType,
    rating,
    text,
    type,
    platform,
  }: MutationArgs): Promise<Review> => {
    const res: Review = await API.post('clientsService', '/v1/reviews', {
      body: {
        ratingType,
        rating,
        text,
        type,
        platform,
      },
      headers: {
        ...amplifyRequestContext(identityId, username, {
          'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
          'x-user-id': identityId,
          'content-type': 'application/json',
        }).headers,
      },
    });

    return res;
  };

  const onSuccess = (res: Review) => {
    queryClient.setQueryData('reviews', (data?: GetReviewsResponse) => {
      if (!data) {
        return { reviews: [res] };
      }

      return { reviews: [...data.reviews, res] };
    });
  };

  return useMutation<Review, AxiosError, MutationArgs>(createReview, {
    onSuccess,
  });
}

export default useCreateReview;
