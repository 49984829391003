import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import Page from 'components/Page';
import ChargeAccountInfoCard from 'components/charge-account/ChargeAccountInfoCard';
import ChargeAccountTransactionsCard from 'components/charge-account/ChargeAccountTransactionsCard';
import ChargeAccountPaymentCard from 'components/charge-account/ChargeAccountPaymentCard';
import ChargeAccountRedirect from 'components/charge-account/ChargeAccountRedirect';
import useGetAccounts from 'hooks/queries/useGetAccounts';

const ChargeAccountPage: React.FC = () => {
  const { data, isLoading } = useGetAccounts();

  const shouldShowExplainerText = data?.chargeAccount?.status !== 'closed';
  return (
    <>
      <ChargeAccountRedirect />

      <Page>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              textAlign: 'center',
            },
          }}
        >
          <Typography variant="h1" marginBottom={theme.spacing(2)}>
            Credit Line
          </Typography>

          {isLoading && <Skeleton variant="text" width={300} height={40} />}
          {shouldShowExplainerText && (
            <Typography marginBottom={theme.spacing(3)}>
              Build your credit with on-time payments, low credit utilization,
              and more impact over time.
            </Typography>
          )}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ChargeAccountInfoCard />
          </Grid>

          <Grid item xs={12} sx={{ '&:empty': { display: 'none' } }}>
            <ChargeAccountPaymentCard />
          </Grid>

          <Grid item xs={12}>
            <ChargeAccountTransactionsCard />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default ChargeAccountPage;
