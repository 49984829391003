import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';

import { GREEN, KOVO_GREY, RED, theme } from 'context/ThemeProvider';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import useGetApplications from 'hooks/queries/useGetApplications';
import useGetPurchases from 'hooks/queries/useGetPurchases';
import { ROUTES } from 'components/Routes';
import { ID_PROTECTION_DIGITAL_SERVICE_ID } from 'hooks/queries/useGetDigitalService';
import { Purchase } from 'types/schemas/purchase';
import { calculateAmountPastDue } from 'components/CatchupPayment/past-due-amount-calculations';

const purchaseAutopayLabel = (purchase: Purchase) => {
  const {
    status,
    scheduleItems: [currentSchedule] = [],
    digitalService,
  } = purchase;

  const subscriptionAmountDollars = digitalService.price / 100;

  if (status === 'active' && currentSchedule) {
    const delayedAutopay = moment(currentSchedule.chargeAt)
      .add('1', 'day')
      .format('MM/DD/YY');

    return `$${subscriptionAmountDollars.toFixed(2)} on ${delayedAutopay}`;
  }

  return null;
};

const ChargeAccountInfoCard: React.FC = () => {
  const { data: { chargeAccount } = {}, isLoading } = useGetAccounts();

  const { data: applicationsData, isLoading: isLoadingApplications } =
    useGetApplications();

  const {
    data: { purchases: [purchase] = [] } = {},
    isLoading: isLoadingPurchases,
  } = useGetPurchases({
    digitalServiceId: ID_PROTECTION_DIGITAL_SERVICE_ID,
  });

  if (isLoading || isLoadingApplications || isLoadingPurchases) {
    return <Skeleton variant="rounded" height={211} />;
  }

  if (!chargeAccount || !applicationsData) {
    return null;
  }

  if (!chargeAccount) {
    return null;
  }

  const pastDueBalanceDollars = calculateAmountPastDue(chargeAccount);

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">ACCOUNT INFORMATION</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        {chargeAccount.status === 'active' && (
          <>
            <Typography marginBottom={theme.spacing(2)}>
              <strong>
                Status:{' '}
                <Box component="span" color={GREEN}>
                  Current
                </Box>
              </strong>
            </Typography>

            {purchase?.status === 'active' && (
              <Typography variant="footnote">
                Next AutoPay: {purchaseAutopayLabel(purchase)}
              </Typography>
            )}
          </>
        )}

        {chargeAccount.status === 'past_due' && (
          <>
            <Typography marginBottom={theme.spacing(2)}>
              <strong>
                Status:{' '}
                <Box component="span" color={RED}>
                  Past due ${pastDueBalanceDollars.toFixed(2)}
                </Box>
              </strong>
            </Typography>

            <Button
              component={Link}
              to={ROUTES.CREDIT_LINE_CATCHUP_PAYMENT}
              variant="contained"
              fullWidth
            >
              Catch up
            </Button>
          </>
        )}

        {chargeAccount.status === 'closed' && (
          <Typography>
            <strong>
              Status:{' '}
              <Box component="span" color={KOVO_GREY}>
                Closed
              </Box>
            </strong>
          </Typography>
        )}

        {chargeAccount.status === 'processing' && (
          <Typography>
            <strong>
              Status:{' '}
              <Box component="span" color={KOVO_GREY}>
                Processing
              </Box>
            </strong>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ChargeAccountInfoCard;
