import { Auth } from 'aws-amplify';
import { useAuthContext } from 'context/AuthProvider';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';
import { useMutation } from 'react-query';

const resendSignUp = async (email?: string) => {
  try {
    if (!email) {
      return;
    }

    const normalizedEmail = normalizeEmail(email);

    await Auth.resendSignUp(normalizedEmail);
  } catch (error) {
    throw new KovoError('Error resending confirmation code', {
      error,
    }).exposeMessage();
  }
};

const useResendEmailVerificationCode = () => {
  const { preConfirmationState } = useAuthContext();

  return useMutation<void, KovoError>(() =>
    resendSignUp(preConfirmationState?.email),
  );
};

export default useResendEmailVerificationCode;
