import { useFormik } from 'formik';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

import { theme } from 'context/ThemeProvider';
import useSavePhoneNumber from 'hooks/mutations/useSavePhoneNumber';
import PhoneInput from 'components/shared/PhoneInput';

interface FormValues {
  phoneNumber: string;
}

interface Props {
  onSuccess: () => void;
}

const PhoneNumberForm: React.FC<Props> = ({ onSuccess }) => {
  const { mutate, isLoading, error } = useSavePhoneNumber({ onSuccess });

  const onSubmit = ({ phoneNumber }: FormValues) => {
    mutate(phoneNumber);
  };

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur } =
    useFormik({
      initialValues: { phoneNumber: '' },
      validationSchema: yup.object().shape({
        phoneNumber: yup
          .string()
          .required('Mobile phone number is required')
          .test(
            'phoneLength',
            'Invalid phone number',
            (value?: string) => value?.length === 12,
          ),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  return (
    <>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {error.response?.data.message ===
          'Phone number matches existing phone number'
            ? "You've entered your existing phone number. To update, please enter a new number."
            : error.response?.data.message === 'Invalid phone number'
            ? "Sorry, that isn't a valid phone number. Please re-enter and try again."
            : error.response?.data.message === 'Max send attempts reached'
            ? 'Sorry, there have been too many verification attempts for this number. Please wait 10 minutes and try again.'
            : error.response?.data.message === 'Landline or unreachable carrier'
            ? "Sorry, we can't accept landlines. Please try again with a mobile number."
            : 'There was an error. Please try again.'}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <PhoneInput
          name="phoneNumber"
          label="Mobile phone number"
          value={values.phoneNumber}
          error={!!(touched.phoneNumber && errors.phoneNumber)}
          errorText={errors.phoneNumber}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          fullWidth
          showCountryCode
          autoFocus
        />

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{ marginTop: theme.spacing(3) }}
        >
          Send Code
        </LoadingButton>
      </form>
    </>
  );
};

export default PhoneNumberForm;
