import { useMemo, useState } from 'react';
import { Box, Dialog, Link } from '@mui/material';

import useWelcomeCompleted from 'hooks/mutations/useWelcomeCompleted';
import {
  INVITING_REWARD_AMOUNT,
  REFERRED_REWARD_AMOUNT,
} from 'helpers/referralClaims';

import WelcomeDialogScreen from './WelcomeDialogScreen';
import ShareReferralCode from 'components/ShareReferralCode';

import ShareIcon from 'assets/img/welcome/share.png';
import GetAppIcon from 'assets/img/welcome/get_app.png';
import { AppDownloadElement } from 'components/AppDownloadElement';
import { theme } from 'context/ThemeProvider';
import usePatchUser from 'hooks/mutations/usePatchUser';

export interface Screen {
  key: string;
  heading: string;
  body: string[];
  image: string;
}

function isScreen<T>(screen: T | null): screen is T {
  return screen !== null;
}

const cleanupScreenArray = (rawArray: Array<Screen | null>): Array<Screen> =>
  rawArray.filter(isScreen);

const SHARE_SCREEN: Screen = {
  key: 'share',
  heading: 'It Pays to Share!',
  body: [
    `Help your friends build credit. You get ${INVITING_REWARD_AMOUNT} and they get ${REFERRED_REWARD_AMOUNT} when their first 3 payments are made on-time.`,
  ],
  image: ShareIcon,
};

const MOBILE_APP_PROMPT_SCREEN: Screen = {
  key: 'app',
  heading: 'Get the App!',
  body: [
    'Boost your credit building with the app. Get notified about critical account information, payment activity, and credit score updates.',
  ],
  image: GetAppIcon,
};

export const SHARE_DATA_TITLE = `Get ${REFERRED_REWARD_AMOUNT} when you build credit with Kovo.`;

export const SHARE_DATA_TEXT = `Get ${REFERRED_REWARD_AMOUNT} from Kovo and start building credit! Get paid after your first 3 on-time payments. Learn more.`;

export type WelcomeDialogProps = {
  welcomeCompletedAt?: string;
  appDownloadPromptSeenAt?: string;
};

const WelcomeDialog = ({
  welcomeCompletedAt,
  appDownloadPromptSeenAt,
}: WelcomeDialogProps) => {
  const { mutate: patchUser } = usePatchUser();

  const [activeStep, setActiveStep] = useState(0);

  const [isComplete, setIsComplete] = useState(false);

  const { mutate: mutateWelcomeCompleted } = useWelcomeCompleted();

  const patchAppDownloadPromptSeen = async () => {
    patchUser({
      appDownloadPromptSeenAt: new Date().toISOString(),
    });
  };

  const onboardingScreens = useMemo<Array<Screen>>(() => {
    const shareScreen = welcomeCompletedAt ? null : SHARE_SCREEN;
    if (window.ReactNativeWebView) {
      return cleanupScreenArray([shareScreen]);
    }
    const appPromptScreen = appDownloadPromptSeenAt
      ? null
      : MOBILE_APP_PROMPT_SCREEN;
    return cleanupScreenArray([shareScreen, appPromptScreen]);
  }, [appDownloadPromptSeenAt, welcomeCompletedAt]);

  const onNextClick = () => {
    if (activeStep === onboardingScreens.length - 1) {
      mutateWelcomeCompleted();
      patchAppDownloadPromptSeen();
      setIsComplete(true);
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const isShareScreen = onboardingScreens[activeStep]?.key === 'share';
  const isAppDownloadScreen = onboardingScreens[activeStep]?.key === 'app';

  return (
    <>
      <Dialog
        open={!isComplete && onboardingScreens.length > 0}
        fullScreen
        data-testid="welcome-dialog"
        PaperProps={{
          sx: {
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            padding: '0px !important',
            borderRadius: '0px !important',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            flexGrow: 1,
            textAlign: 'center',
            overflowX: 'hidden',
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.4s ease-in-out',
              transform: `translate3d(${activeStep * -100}%, 0, 0)`,
            }}
          >
            {onboardingScreens.map((screen, index) => (
              <WelcomeDialogScreen
                screen={screen}
                activeStep={activeStep}
                index={index}
                key={screen.heading}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              width: '100%',
              maxWidth: '346px',
            }}
          >
            {isShareScreen && <ShareReferralCode onComplete={onNextClick} />}
            {isAppDownloadScreen && <AppDownloadElement />}

            <Link
              sx={{
                marginTop: theme.spacing(2),

                [theme.breakpoints.up('sm')]: {
                  marginTop: theme.spacing(3),
                },

                [theme.breakpoints.up('md')]: {
                  marginTop: theme.spacing(5),
                },
              }}
              component="button"
              variant="body1"
              onClick={onNextClick}
            >
              Maybe later
            </Link>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default WelcomeDialog;
