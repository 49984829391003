import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { KovoUnauthenticatedError } from 'libs/KovoError';

/**
 * This function will sign in a user with the given email and password.
 */
export const signInUser = async (
  email: string,
  password: string,
): Promise<CognitoUser> => {
  try {
    const cognitoUser = await Auth.signIn(email, password);

    return cognitoUser;
  } catch (error) {
    // It could make sense to map the Cognito error messages to our own error messages
    // For now, simply throw the Cognito error message so it is displayed to the user
    throw new KovoUnauthenticatedError(
      error instanceof Error ? error.message : 'Error signing in',
      { error },
    );
  }
};
