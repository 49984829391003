import { getCookie } from 'helpers/cookies';
import { KovoError } from 'libs/KovoError';
import getSignupPlatform from './getSignupPlatform';
import getUserTimezone from './getUserTimezone';

export interface SignupData {
  [key: string]: string;
  timezone: string;
  marketingAttributions: string;
  signupPlatform: string;
  referralCode: string;
}

/**
 * Gets the signup data for a user. This is used tocreate the user
 * in the database.
 */
export const getSignupData = () => {
  try {
    const signupData: SignupData = {
      timezone: getUserTimezone(),
      signupPlatform: getSignupPlatform(),
      referralCode: getCookie('kovo_referral_code') || '',
      marketingAttributions: JSON.stringify({
        ...JSON.parse(getCookie('kovo_marketing_attributions') || '{}'),
        fbc: getCookie('_fbc'),
        fbp: getCookie('_fbp'),
      }),
    };

    return signupData;
  } catch (error) {
    throw new KovoError('Failed to get signup data', { error });
  }
};

export default getSignupData;
