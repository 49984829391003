import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { Application, CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

export const USE_APPLICATIONS_QUERY_KEY = 'applications';

export type GetApplicationsResponse = {
  applications: Application[];
};

function useGetApplications() {
  const { identityId, username } = useContext(AuthContext);

  const getApplication = async () => {
    const res: GetApplicationsResponse = await API.get(
      'clientsService',
      `/v1/applications`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    /**
     * The issue with this is that we will want to be able to support multiple
     * charge account applications where all but one are in a completed state
     * and then there is one that is currently in progress. For now, this is
     * fine.
     */
    const chargeAccountApplication = res.applications.find(
      ({ loanProductId, status }) =>
        status !== 'abandoned' && loanProductId === CHARGE_ACCOUNT_PRODUCT_ID,
    );

    return { ...res, chargeAccountApplication };
  };

  return useQuery<
    GetApplicationsResponse & { chargeAccountApplication?: Application }
  >([USE_APPLICATIONS_QUERY_KEY], () => getApplication());
}

export default useGetApplications;
