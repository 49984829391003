import { useContext } from 'react';
import { API } from 'aws-amplify';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';

function useVerifyPhoneNumber(
  options?: UseMutationOptions<void, Error, any, any>,
) {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const verifyPhoneNumber = async (verificationCode: string) => {
    await API.post('installments', '/installments/verify-phone-number', {
      body: {
        verificationCode,
      },
      ...amplifyRequestContext(identityId, username),
    });
  };

  const onSuccess = async () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });
    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });

    if (options?.onSuccess) {
      options.onSuccess(undefined, undefined, undefined);
    }
  };

  return useMutation<void, AxiosError<any>, string, unknown>(
    verifyPhoneNumber,
    {
      ...(options && { ...options }),
      onSuccess,
    },
  );
}

export default useVerifyPhoneNumber;
