import { useContext, useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { Alert, Box, FormHelperText, Grid, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AuthContext } from 'context/AuthProvider';
import { DARK_BLUE, KOVO_BLUE, LIGHT_GREY, theme } from 'context/ThemeProvider';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { loadScript } from 'helpers/load-script';
import useApplicationStatus from 'hooks/useApplicationStatus';
import TextField from 'components/shared/TextField';

import Visa from 'assets/img/logos/credit-cards/visa.svg';
import Mastercard from 'assets/img/logos/credit-cards/mastercard.svg';
import Amex from 'assets/img/logos/credit-cards/amex.svg';
import Discover from 'assets/img/logos/credit-cards/discover.svg';
import Lock from 'assets/img/icons/lock.svg';
import { parsePaymentError } from 'libs/parsePaymentError';
import { CreditCardFormValues, onConfirmPaymentMethod } from './types';

const CHECKOUT_CDN_SCRIPT_URL = 'https://cdn.checkout.com/js/framesv2.min.js';
const CHECKOUT_PUBLIC_KEY = import.meta.env.VITE_CHECKOUT_PUBLIC_KEY;

export interface Options {
  publicKey: string;
}

declare global {
  interface Window {
    gtag: Function;
    Frames: {
      init: (options: Options & any) => void;
      submitCard: () => Promise<{
        token: string;
        scheme: string;
      }>;
      addEventHandler: (eventName: any, cb: (e: any) => void) => void;
      removeAllEventHandlers: (eventName: string) => void;
      isCardValid: () => boolean;
      addCardToken: (form: HTMLFormElement, token: string) => void;
      Events: any;
      cardholder?: any;
      enableSubmitForm: () => undefined;
    };

    CKOConfig: {
      publicKey: string;
      debug?: boolean;
      frameSelector?: string;
      style?: any;
    };
  }
}

window.CKOConfig = {
  publicKey: CHECKOUT_PUBLIC_KEY!,
  debug: true,
};

interface MutationArgument {
  values: CreditCardFormValues;
  identityId: string;
}

export interface CheckoutCreditCardFormProps {
  buttonLabel: string;
  onConfirmPaymentMethod: onConfirmPaymentMethod;
}

const checkoutFrameSx = {
  margin: '0 -5px',
  position: 'relative',

  label: {
    fontFamily: 'Inter, sans-serif',
    letterSpacing: 'normal',
  },

  '&.frame--focus label, &.frame--valid label, &.frame--invalid label': {
    transform: 'translate(18px, 13px) scale(0.75)',
  },

  '&.frame--focus label': {
    color: KOVO_BLUE,
  },

  '.Mui-error': {
    display: 'none',
  },

  '&.frame--invalid:not(.frame--focus) .Mui-error': {
    display: 'block',
  },
};

const checkoutErrorMessageSx = {
  position: 'absolute',
  top: '100%',
  left: 5,
  marginTop: '8px',
};

const checkoutInputLabelSx = {
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'translate(18px, 22px) scale(1)',
  transformOrigin: 'top left',
  transition:
    'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  pointerEvents: 'none',
  color: LIGHT_GREY,
};

/**
 * This component is responsible for rendering the Checkout.com payment form.
 * When the form loads, it will load the Checkout.com script and initialize the
 * payment form.
 */
const CheckoutCreditCardForm: React.FC<CheckoutCreditCardFormProps> = ({
  buttonLabel,
  onConfirmPaymentMethod,
}) => {
  const formRef = useRef(null);

  const { email, identityId } = useContext(AuthContext);
  const { data: applicationStatusData } = useApplicationStatus();

  const [isCheckoutReady, setIsCheckoutReady] = useState(false);

  useEffect(() => {
    return () => {
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_SUBMITTED);
      window.Frames.removeAllEventHandlers(
        window.Frames.Events.CARD_TOKENIZATION_FAILED,
      );
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_TOKENIZED);
      window.Frames.removeAllEventHandlers(
        window.Frames.Events.CARD_VALIDATION_CHANGED,
      );
      window.Frames.removeAllEventHandlers(
        window.Frames.Events.FRAME_ACTIVATED,
      );
      window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_BLUR);
      window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_FOCUS);
      window.Frames.removeAllEventHandlers(
        window.Frames.Events.FRAME_VALIDATION_CHANGED,
      );
      window.Frames.removeAllEventHandlers(
        window.Frames.Events.PAYMENT_METHOD_CHANGED,
      );
      window.Frames.removeAllEventHandlers(window.Frames.Events.READY);
    };
  }, []);

  // This will trigger submitPayment() when the card is submitted.
  const onSubmit = (values: CreditCardFormValues) => {
    mutate({ values, identityId });
  };

  /**
   * This function is responsible for submitting the payment to the backend.
   * @param param0
   * @returns
   */
  const submitPayment = async ({ values, identityId }: MutationArgument) => {
    try {
      console.group('submitPayment');
      console.log('submitPayment', values, identityId);
      if (!formRef.current) {
        if (rollbarEnabled) {
          rollbar.error('Error submitting payment: Form ref is undefined', {
            email,
            identityId,
          });
        }
        throw new Error('Form ref is undefined');
      }

      console.log('Frames before', window.Frames);
      window.Frames.cardholder = {
        name: values.name,
        billingAddress: {
          zip: values.zipcode,
          country: 'US',
        },
      };

      console.log('Submitting card');

      const { token, scheme } = await window.Frames.submitCard();

      console.log('Token', token);
      console.log('Adding card token');
      window.Frames.addCardToken(formRef.current, token);

      console.log('Frames after', window.Frames);
      console.log('Calling `onconfirmPaymentMethod`');
      await onConfirmPaymentMethod({ token, values, scheme });
    } catch (error) {
      const typedError = error as Error;

      if (error instanceof Error) {
        if (rollbarEnabled) {
          rollbar.error(
            `Error submitting credit card payment: ${
              typeof typedError === 'string' ? typedError : typedError.message
            }`,
            {
              error,
            },
          );
        }

        throw error;
      }

      throw new Error('Something went wrong. Please try again.');
    } finally {
      console.groupEnd();
    }
  };

  const name = applicationStatusData?.applicationDetails
    ? `${applicationStatusData?.applicationDetails?.firstName} ${applicationStatusData?.applicationDetails?.lastName}`
    : '';

  const zipcode = applicationStatusData?.applicationDetails?.zipcode || '';

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        name,
        zipcode,
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Name is required'),
        zipcode: yup.string().required('Zip code is required'),
      }),
      onSubmit,
    });

  const loadCheckoutScript = async () => {
    try {
      await loadScript(CHECKOUT_CDN_SCRIPT_URL);

      window.Frames.init({
        publicKey: window.CKOConfig.publicKey,
        style: {
          base: {
            height: '58px',
            fontFamily: 'sans-serif',
            '-webkit-font-smoothing': 'antialiased',
            color: DARK_BLUE,
            fontSize: '16px',
            border: `1px solid ${LIGHT_GREY}`,
            'border-radius': '8px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '16px',
            letterSpacing: 'normal',
            fontWeight: '400',
            fontStretch: '100%',
            backgroundColor: theme.palette.common.white,
            transition:
              'box-shadow 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            boxShadow: `0 0 0 0 ${DARK_BLUE} inset`,

            margin: '5px',
            width: 'calc(100% - 10px)',
          },
          hover: {
            borderColor: `${KOVO_BLUE} !important`,
          },
          focus: {
            borderColor: `${KOVO_BLUE} !important`,
            boxShadow: `0px 0px 5px 0px ${KOVO_BLUE}`,
          },
          placeholder: {
            base: {
              color: 'transparent',
            },
          },
          invalid: {
            borderColor: theme.palette.error.main,
          },
        },
        ready: () => {
          setIsCheckoutReady(() => true);
        },
      });
    } catch (error) {
      const typedError = error as Error;

      if (rollbarEnabled) {
        rollbar.error(`Error loading Checkout script: ${typedError.message}`, {
          error,
        });
      }
    }
  };

  useQuery('checkoutScript', loadCheckoutScript, {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const onError = () => {
    /**
     * Reset the Checkout payment form without removing any data.
     */
    window.Frames.enableSubmitForm();
  };

  const {
    mutate,
    error: serverError,
    isLoading: isProcessingCreditCard,
  } = useMutation<void, AxiosError | string, MutationArgument>(submitPayment, {
    onError,
  });

  return (
    <form
      onSubmit={handleSubmit}
      ref={formRef}
      data-testid="checkout-payment-form"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(3),

          img: {
            margin: theme.spacing(0, 0.75),
          },
        }}
      >
        <Box component="img" src={Visa} width="40px" height="24px" />
        <Box component="img" src={Mastercard} width="40px" height="24px" />
        <Box component="img" src={Amex} width="40px" height="24px" />
        <Box component="img" src={Discover} width="40px" height="24px" />
      </Box>

      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {parsePaymentError(serverError)}
        </Alert>
      )}

      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Box
            className="card-number-frame"
            maxHeight={56}
            sx={checkoutFrameSx}
          >
            {isCheckoutReady ? (
              <>
                <Box
                  component="label"
                  htmlFor="cardnumber"
                  sx={checkoutInputLabelSx}
                >
                  Card number
                </Box>
                <FormHelperText error sx={checkoutErrorMessageSx}>
                  Invalid card number
                </FormHelperText>
              </>
            ) : (
              <Skeleton variant="rounded" height={56} />
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            className="expiry-date-frame"
            maxHeight={56}
            sx={checkoutFrameSx}
          >
            {isCheckoutReady ? (
              <>
                <Box
                  component="label"
                  htmlFor="exp-date"
                  sx={checkoutInputLabelSx}
                >
                  MM/YY
                </Box>
                <FormHelperText error sx={checkoutErrorMessageSx}>
                  Invalid expiration date
                </FormHelperText>
              </>
            ) : (
              <Skeleton variant="rounded" height={56} />
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            marginBottom: '5px',
          }}
        >
          <Box className="cvv-frame" maxHeight={56} sx={checkoutFrameSx}>
            {isCheckoutReady ? (
              <>
                <Box component="label" htmlFor="cvc" sx={checkoutInputLabelSx}>
                  CVV
                </Box>
                <FormHelperText error sx={checkoutErrorMessageSx}>
                  Invalid CVV
                </FormHelperText>
              </>
            ) : (
              <Skeleton variant="rounded" height={56} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {!isCheckoutReady ? (
            <Skeleton variant="rounded" height={56} />
          ) : (
            <TextField
              name="name"
              label="Name on card"
              error={!!(touched.name && errors.name)}
              errorText={errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              inputProps={{
                sx: {
                  fontFamily: 'sans-serif',
                },
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {!isCheckoutReady ? (
            <Skeleton variant="rounded" height={56} />
          ) : (
            <TextField
              name="zipcode"
              label="Billing zip code"
              type="tel"
              pattern="[0-9\-]*"
              maxLength={5}
              error={!!(touched.zipcode && errors.zipcode)}
              errorText={errors.zipcode}
              value={values.zipcode}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              inputProps={{
                sx: {
                  fontFamily: 'sans-serif',
                },
              }}
            />
          )}
        </Grid>
      </Grid>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isProcessingCreditCard}
        fullWidth
        sx={{ margin: theme.spacing(3, 0, 1) }}
      >
        {buttonLabel}
        <Box
          component="img"
          src={Lock}
          sx={{
            position: 'absolute',
            right: theme.spacing(3),
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </LoadingButton>
    </form>
  );
};

export default CheckoutCreditCardForm;
