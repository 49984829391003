import { Box, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import OnboardingPage from 'components/OnboardingPage';
import ChargeAccountRedirect from 'components/charge-account/ChargeAccountRedirect';
import ContractDialog from 'components/ContractDialog';

import ApplicationSignupPayment from 'components/ApplicationSignupPayment/ApplicationSignupPayment';
import useGetApplications from 'hooks/queries/useGetApplications';
import PageLoader from 'components/PageLoader';
import useGetDigitalService, {
  ID_PROTECTION_DIGITAL_SERVICE_ID,
} from 'hooks/queries/useGetDigitalService';
import ProductBenefitsTable from 'components/ProductBenefitsTable';
import { ProductBenefit } from 'components/ProductBenefitsTable/ProductBenefitsTable';
import { CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

const CREDIT_LINE_BENEFITS: Array<ProductBenefit> = [
  {
    title: 'Revolving Credit Line Account',
    subtitle: '$0 fees, 0% interest, single-purpose',
  },
  {
    title: 'Credit Reporting: All 4 Bureaus',
    subtitle: 'TransUnion + Equifax + Experian + Innovis',
  },
  {
    title: 'Maximum Credit Protection',
    subtitle: 'Up to $1M ID Fraud Insurance',
  },
  {
    title: 'FICO® Score',
    subtitle: 'Track the score used by 90% of top lenders',
  },
  {
    title: 'Credit Rewards',
    subtitle: 'Earn 1% on loans, up to $1,225',
  },
  {
    title: 'Unlimited Referrals',
    subtitle: 'Get $30 for every friend you refer',
  },
  {
    title: 'Personal Growth Tools',
    subtitle: 'Ongoing access to support your credit journey',
  },
];

const ChargeAccountPaymentPage: React.FC = () => {
  const { data: { chargeAccountApplication: application } = {} } =
    useGetApplications();
  const { data: digitalService } = useGetDigitalService(
    ID_PROTECTION_DIGITAL_SERVICE_ID,
  );

  if (!application || !digitalService) {
    return <PageLoader />;
  }

  return (
    <>
      <ChargeAccountRedirect />

      <OnboardingPage>
        <Typography variant="h1" marginBottom={theme.spacing(3)}>
          Activate your credit line
        </Typography>

        <ProductBenefitsTable
          benefits={CREDIT_LINE_BENEFITS}
          totalString="$10.00/mo"
          totalExplainer="Monthly auto-renew. Payments are drawn from your single-purpose credit line and due 1 day later."
        />

        <ApplicationSignupPayment
          application={application}
          digitalService={digitalService}
        />

        <Typography
          variant="footnote"
          marginBottom={theme.spacing(1)}
          marginTop={theme.spacing(3)}
        >
          By adding your payment method, you are:
        </Typography>
        <Box
          component="ul"
          sx={{ paddingLeft: theme.spacing(2), margin: theme.spacing(0, 0, 3) }}
        >
          <Box component="li">
            <Typography variant="footnote">
              Electronically signing the{' '}
              <ContractDialog productId={CHARGE_ACCOUNT_PRODUCT_ID}>
                Kovo Revolving Credit Line Agreement
              </ContractDialog>{' '}
              and electing to 1-day payment terms on your revolving credit
              account using auto-pay with this payment method. You acknowledge
              you have reviewed the full text and agree to its content.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="footnote">
              Authorizing Kovo to charge you for Maximum Credit Protection. You
              can cancel upcoming renewal by getting in touch with our support
              team.
            </Typography>
          </Box>
        </Box>
      </OnboardingPage>
    </>
  );
};

export default ChargeAccountPaymentPage;
