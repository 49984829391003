import { Redirect } from 'react-router';

import { ROUTES } from 'components/Routes';

// /profile is deprecated
const ProfilePage: React.FC = () => {
  return <Redirect to={ROUTES.SETTINGS} />;
};

export default ProfilePage;
