import { Alert, Box, Link as MuiLink, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import config from 'config';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import TextField from 'components/shared/TextField';
import PasswordInputWithStrength from 'components/PasswordInputWithStrength';
import { EMAIL_REGEX } from 'helpers/constants';
import { normalizeEmail } from 'helpers/utils';
import Checkbox from 'components/shared/Checkbox';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUpForm: React.FC = () => {
  const history = useHistory();

  const { authChangeCallback } = useAuthContext();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        confirmPassword: '',
        checkboxChecked: false,
      },
      validationSchema: yup.object().shape({
        email: yup
          .string()
          .matches(EMAIL_REGEX, 'Invalid email')
          .required('Email is required'),
        password: yup.string().required('Password is required'),
        confirmPassword: yup
          .string()
          .required('Passwords must match')
          .oneOf([yup.ref('password'), null], 'Passwords must match'),
        checkboxChecked: yup
          .boolean()
          .oneOf([true], 'You must agree to the terms and conditions'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  const signUpUser = async ({ email, password }: FormValues) => {
    const formattedEmail = normalizeEmail(email);
    const signup = await Auth.signUp({
      username: formattedEmail,
      password,
    });

    const authDetails = await getCognitoUserAuthDetails(signup.user);

    return {
      user: signup.user,
      userDetails: authDetails,
      email: formattedEmail,
      password,
    };
  };

  const onSuccess = ({
    user,
    userDetails,
    email,
    password,
  }: {
    user: CognitoUser;
    userDetails: UserAuthDetails;
    email: string;
    password: string;
  }) => {
    if (config.VITE_STAGE === 'prod') {
      window.gtag('event', 'CompleteRegistration');
      ReactPixel.track('CompleteRegistration', {});
      window.ttq.track('CompleteRegistration');
    }

    /**
     * Ensure currentUser is set before navigating to /signup-confirm
     */
    authChangeCallback(user, userDetails, {
      email,
      password,
    });

    history.replace('/signup-confirm');
  };

  const {
    mutate,
    isLoading,
    error: serverError,
  } = useMutation<
    {
      user: CognitoUser;
      userDetails: UserAuthDetails;
      email: string;
      password: string;
    },
    Error,
    FormValues
  >(signUpUser, { onSuccess });

  const legalPagesHost = window.ReactNativeWebView
    ? ''
    : 'https://www.kovocredit.com';

  const termsAndConditionsLabelElement = () => (
    <Typography
      variant="footnote"
      sx={{
        textAlign: 'left',
        marginLeft: theme.spacing(1.5),
      }}
    >
      Agree to Kovo’s{' '}
      <MuiLink
        href={`${legalPagesHost}/legal/privacy`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </MuiLink>
      ,{' '}
      <MuiLink
        href={`${legalPagesHost}/legal/terms`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </MuiLink>
      , and{' '}
      <MuiLink
        href={`${legalPagesHost}/legal/e-sign`}
        target="_blank"
        rel="noopener noreferrer"
      >
        E-Sign Consent
      </MuiLink>
      .
    </Typography>
  );

  return (
    <Box textAlign="center">
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError?.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} marginBottom={theme.spacing(3)}>
          <Grid item xs={12}>
            <TextField
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              error={!!(touched.email && errors.email)}
              errorText={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordInputWithStrength
              name="password"
              label="Password"
              type="password"
              autoComplete="new-password"
              error={!!(touched.password && errors.password)}
              errorText={errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="confirmPassword"
              label="Confirm password"
              type="password"
              autoComplete="new-password"
              error={!!(touched.confirmPassword && errors.confirmPassword)}
              errorText={errors.confirmPassword}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            checkboxSize={30}
            label={termsAndConditionsLabelElement()}
            name="checkboxChecked"
            checked={values.checkboxChecked}
            onChange={handleChange}
            showBorder
            sx={{
              marginBottom: !!(
                touched.checkboxChecked && errors.checkboxChecked
              )
                ? 0
                : theme.spacing(3),
            }}
          />
          {!!(touched.checkboxChecked && errors.checkboxChecked) && (
            <Typography
              variant="footnote"
              color="error"
              sx={{
                textAlign: 'left',
                marginLeft: '14px',
                marginBottom: theme.spacing(1),
              }}
            >
              {errors.checkboxChecked}
            </Typography>
          )}
        </Grid>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Create Account
        </LoadingButton>
      </form>
    </Box>
  );
};

export default SignUpForm;
