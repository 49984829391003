// TODO: move to hooks/mutations folder

import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import config from 'config';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { FormValues } from 'components/ApplyForm/ApplyForm';
import { ApplicationStatus } from 'context/Profile';
import { logOnboardingEvent } from 'libs/logger';
import { AuthContext } from 'context/AuthProvider';
import useCurrentUser, {
  USE_CURRENT_USER_QUERY_KEY,
} from './queries/useCurrentUser';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { removeCookie } from 'helpers/cookies';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';

const formatData = (data: FormValues) => {
  return {
    ...data,
    monthlyIncome: parseInt(
      data.monthlyIncome.replace('$', '').replace(',', ''),
      10,
    ),
  };
};

interface MutateResponse {
  applicationStatus: ApplicationStatus;
  phoneNumber: string;
  values: FormValues;
}

function useApplicationSubmit() {
  const { email, identityId, username } = useContext(AuthContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();

  const submitApplication = async (
    values: FormValues,
  ): Promise<MutateResponse> => {
    const { applicationStatus, phoneNumber } = await API.post(
      'installments',
      '/v2/installments/application-submit',
      {
        body: {
          ...formatData(values),
          isPhoneVerificationEnabled: true,
        },
        ...amplifyRequestContext(identityId, username),
      },
    );

    return { applicationStatus, phoneNumber, values };
  };

  const onSuccess = async ({
    applicationStatus,
    phoneNumber,
    values,
  }: MutateResponse) => {
    if (config.VITE_STAGE === 'prod') {
      const eventName = 'SubmitApplication';
      window.gtag('event', eventName);

      /**
       * Facebook Pixel requires the phone number to be in the format +1234567890
       * and the country code to be included.
       */
      const ph =
        user && user.phoneNumber
          ? user.phoneNumber
          : phoneNumber
          ? phoneNumber.replace('+', '')
          : '';

      /**
       * If the phone number is undefined, we log an error to Rollbar.
       * This should never happen.
       */
      if (!ph) {
        rollbarEnabled &&
          rollbar.error(
            'Phone number is undefined when submitting application',
            {
              email,
              identityId,
              username,
              values,
            },
          );
      }

      const advancedMatchingData: AdvancedMatching = {
        em: email,
        db: moment(values.birthday).format('YYYYMMDD'),
        ph,
        fn: values.firstName.toLowerCase(),
        ln: values.lastName.toLowerCase(),
        ct: values.city.replace(/ /g, '').toLowerCase(),
        st: values.state.toLowerCase(),
        zp: values.zipcode,
        country: 'us',
        ge: '',
      };
      ReactPixel.init(config.FACEBOOK_PIXEL_ID, advancedMatchingData);
      fbq(
        'track',
        eventName,
        {},
        { eventID: `${identityId}-${eventName}-installments10` },
      );
      window.ttq.track('ApplicationSubmitted');

      removeCookie('kovo_referral_code');
      removeCookie('kovo_marketing_attributions');
    }

    logOnboardingEvent({
      eventName: 'application submitted',
      email,
    });

    await queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });

    if (applicationStatus === 'signatureNeeded') {
      return history.push('/esign');
    }

    if (
      ['kycFailed', 'rejected', 'applicationAdverseAction'].includes(
        applicationStatus,
      )
    ) {
      // 'kycFailed', 'rejected' Deprecated
      logOnboardingEvent({
        eventName: 'application rejection adverse action',
        email,
      });

      return history.push('/adverse-action-notice');
    }

    if (['applicationRejected'].includes(applicationStatus)) {
      logOnboardingEvent({
        eventName: 'application rejection cannot verify id',
        email,
      });

      return history.push('/application-rejected');
    }

    if (applicationStatus === 'kycPending') {
      return history.push('/apply-pending');
    }

    throw new Error('Application status is not recognized. Please try again.');
  };

  const onError = (error: AxiosError) => {
    window.scrollTo(0, 0);
  };

  return useMutation<MutateResponse, AxiosError<any>, FormValues>(
    submitApplication,
    { onSuccess, onError },
  );
}

export default useApplicationSubmit;
