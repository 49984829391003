import posthog from 'posthog-js';
import config from 'config';

const FEATURE_FLAG_LOCAL_OVERRIDES: Record<string, boolean> = {
  // Add feature flags here for local development
  FF_USERS_SERVICE_SIGNUP: true,
};

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  if (config.VITE_STAGE === 'local') {
    console.log(
      '[⚛︎] Requested feature flag in local environment:',
      featureFlag,
    );
    if (FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] !== undefined) {
      console.log(
        `[⚛︎] Overriding feature flag ${featureFlag} with ${FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag]}`,
      );
      return FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag];
    }
  }
  return posthog.isFeatureEnabled(featureFlag) || false;
};
