import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { Reward } from 'components/RewardsList/RewardsList';

export const useRewardsQuery = () => {
  const { identityId, username } = useContext(AuthContext);

  const getRewards = async (identityId: string) => {
    const rewards = await API.get(
      'billing',
      '/billing/v2/rewards',
      amplifyRequestContext(identityId, username),
    );

    return rewards;
  };

  return useQuery<Reward[]>(
    ['rewards', identityId],
    () => getRewards(identityId),
    {
      refetchOnWindowFocus: false,
    },
  );
};
