import { Auth } from '@aws-amplify/auth';
import { UserAuthDetails } from 'context/AuthProvider';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { CognitoUser } from '@aws-amplify/auth';
import { getCognitoUserAttributes } from './getCognitoUserAttributes';
import {
  PENDING_EMAIL_ATTRIBUTE_KEY,
  PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
} from './types';
import { KovoError } from 'libs/KovoError';

class MissingEmailVerifiedAttributeError extends Error {
  identityId: string;

  constructor(identityId: string) {
    super(`Cognito user missing the email_verified attribute`);
    this.identityId = identityId;
  }
}

class MissingEmailAttributeError extends Error {
  identityId: string;

  constructor(identityId: string) {
    super(`Cognito user missing the email_verified attribute`);
    this.identityId = identityId;
  }
}

export class MissingAuthCredential extends Error {
  username: string;

  constructor(user: CognitoUser) {
    super('Missing Cognito credential for a logged in user');

    this.username = user.getUsername();
  }
}

/**
 * Use this on sign in to parse and populate a few more fields than
 * just the default Cognito User fields.
 */
export const getCognitoUserAuthDetails = async (
  cognitoUser?: CognitoUser,
): Promise<UserAuthDetails> => {
  try {
    if (!cognitoUser) {
      return {
        email: '',
        emailVerified: false,
        identityId: '',
        username: '',
      };
    }

    const session = cognitoUser.getSignInUserSession();

    if (!session) {
      return {
        email: '',
        emailVerified: false,
        identityId: '',
        username: '',
      };
    }

    const credential = await Auth.currentCredentials();

    if (!credential) {
      throw new MissingAuthCredential(cognitoUser);
    }

    let identityId = credential.identityId;

    /**
     * We prefix with local for local users
     */
    if (import.meta.env.VITE_STAGE === 'local') {
      const id = identityId.split(':')[1];
      identityId = `local:${id}`;
    }

    /**
     * Fetch the attributes from the JWT claim via the built in methods
     * just in case Cognito JS changes the object shape without warning.
     */
    const userAttributes = await getCognitoUserAttributes(cognitoUser);

    const emailVerifiedAttribute = userAttributes.find((a) => {
      return a.Name === 'email_verified';
    });

    const emailAttribute = userAttributes.find((a) => {
      return a.Name === 'email';
    });

    if (!emailVerifiedAttribute) {
      const e = new MissingEmailVerifiedAttributeError(identityId);
      if (rollbarEnabled) {
        rollbar.error(e);
      } else {
        console.error(e);
      }
    }

    if (!emailAttribute) {
      const e = new MissingEmailAttributeError(identityId);

      if (rollbarEnabled) {
        rollbar.error(e);
      } else {
        console.error(e);
      }
    }

    const emailVerified = emailVerifiedAttribute?.Value
      ? emailVerifiedAttribute.Value === 'true'
      : false;

    const email = emailAttribute?.Value ? emailAttribute.Value : '';

    const username = cognitoUser.getUsername(); // user pool user id

    const pendingEmailAttribute = userAttributes.find((a) => {
      return a.Name === PENDING_EMAIL_ATTRIBUTE_KEY;
    });

    const pendingEmailTimestampAttribute = userAttributes.find((a) => {
      return a.Name === PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY;
    });

    return {
      identityId,
      email,
      emailVerified,
      username,
      pendingEmail: pendingEmailAttribute
        ? pendingEmailAttribute.Value
        : undefined,
      pendingEmailTimestamp: pendingEmailTimestampAttribute
        ? pendingEmailTimestampAttribute.Value
        : undefined,
    };
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError('Error getting cognito user auth details', {
      error,
    });
  }
};
