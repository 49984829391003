import config from 'config';
import { normalizeEmail, normalizePhoneNumber } from 'helpers/utils';
import { INSTALLMENTS_PRODUCT_ID, LoanProductId } from 'types/schemas';
import { ApplicationStatusResponse } from 'context/Profile';
import { logOnboardingEvent } from 'libs/logger';
import * as impact from 'helpers/impact';

export const trackConversion = (
  userId: string,
  username: string,
  email: string,
  productId: LoanProductId,
  applicationStatus: ApplicationStatusResponse,
) => {
  if (config.VITE_STAGE === 'prod' && productId === INSTALLMENTS_PRODUCT_ID) {
    const eventName = 'Purchase';

    window.gtag('event', eventName);

    // Google Ads conversion
    window.gtag('event', 'conversion', {
      send_to: 'AW-721372392/Y059CKnj2KoYEOiJ_dcC',
      transaction_id: `${userId}-installments10`,
    });

    // Facebook conversion
    fbq(
      'track',
      eventName,
      {},
      { eventID: `${userId}-${eventName}-installments10` },
    );

    // TikTok conversion
    const tikTokEvent = 'CompletePayment';
    const normalizedEmail = normalizeEmail(email);
    const normalizedPhoneNumber = applicationStatus.applicationDetails?.phone
      ? normalizePhoneNumber(applicationStatus.applicationDetails.phone)
      : '';
    const tikTokIdentifyData = {
      email: normalizedEmail,
      phone_number: normalizedPhoneNumber,
      external_id: userId,
    };
    window.ttq.identify(tikTokIdentifyData);
    window.ttq.track(tikTokEvent, undefined, {
      event_id: `${userId}-${tikTokEvent}-installments10`,
    });
    window.ttq.track('SubmitForm');
  }

  // Trigger Impact "Initial Installment Payment" action
  impact.trackInitialPayment({
    customerId: username,
    customerEmail: email,
  });

  logOnboardingEvent({ eventName: 'payment completed', email: email! });
};
