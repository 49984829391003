import { useContext } from 'react';
import { Link, Skeleton, Typography } from '@mui/material';

import useContractDownloadUrl from 'hooks/useContractDownloadUrl';
import { theme } from 'context/ThemeProvider';
import { AuthContext } from 'context/AuthProvider';

const HellosignContractDownload: React.FC = () => {
  const { identityId, username } = useContext(AuthContext);

  const {
    data: downloadUrl,
    isLoading,
    error,
  } = useContractDownloadUrl(identityId, username);

  if (isLoading) {
    return <Skeleton sx={{ marginTop: theme.spacing(2) }} />;
  }

  if (error) {
    return (
      <Typography variant="footnote" marginTop={theme.spacing(2)}>
        {error}
      </Typography>
    );
  }

  if (!downloadUrl) {
    return null;
  }

  return (
    <Link
      href={downloadUrl}
      variant="body1"
      sx={{ marginTop: theme.spacing(2), display: 'inline-block' }}
    >
      Installment Credit Agreement
    </Link>
  );
};

export default HellosignContractDownload;
