import { useAuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';
import { useMutation } from 'react-query';
import { verifyEmailAttribute } from 'libs/signupHelpers/verifyEmailAttribute';

export const useVerifyEmailAttribute = () => {
  const { currentUser, pendingEmail } = useAuthContext();

  const verifyEmailAttributeFn = async () => {
    try {
      if (!currentUser) {
        throw new KovoError(
          'Please log in before updating your email address.',
        );
      }

      if (!pendingEmail) {
        throw new KovoError(
          'Please update your email address before verifying the update.',
        );
      }

      await verifyEmailAttribute(pendingEmail);
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error sending email address verfication code', {
        error,
      });
    }
  };

  const res = useMutation<void, KovoError, void>({
    mutationFn: verifyEmailAttributeFn,
    mutationKey: 'verifyEmailAttribute',
  });

  return res;
};
