import { Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import OnboardingPage from 'components/OnboardingPage';
import ForgotPasswordForm from 'components/ForgotPasswordForm';

const ForgotPasswordPage: React.FC = () => {
  return (
    <OnboardingPage
      centerLogo
      linkLogoToHomepage
      supportSubject="forgot password"
    >
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Forgot your password?
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        Enter your email to reset your password.
      </Typography>

      <ForgotPasswordForm />

      <Typography
        variant="footnote"
        marginTop={theme.spacing(3)}
        marginBottom={theme.spacing(1)}
      >
        Don’t have an account?{' '}
        <MuiLink component={Link} to="/signup">
          Sign Up
        </MuiLink>
        .
      </Typography>

      <Typography variant="footnote">
        Don't need to reset your password?{' '}
        <MuiLink component={Link} to="/login">
          Log in
        </MuiLink>
        .
      </Typography>
    </OnboardingPage>
  );
};

export default ForgotPasswordPage;
