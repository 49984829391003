import { useCallback, useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';

import { GREY, theme } from 'context/ThemeProvider';
import { CONTRACT_WIDTH } from 'components/EsignContract/EsignContract';

interface Props {
  children: React.ReactNode;
  isScrolled: boolean;
  setIsScrolled: (isScrolled: boolean) => void;
}

const ContractScrollWrapper: React.FC<Props> = ({
  children,
  isScrolled,
  setIsScrolled,
}) => {
  const contractContainer = useRef<HTMLDivElement>(null);

  const handleScrollButtonClick = () => {
    const el = contractContainer.current;

    if (el) {
      const height = el.scrollHeight;

      el.scroll({ top: height, behavior: 'smooth' });
      setIsScrolled(true);
    }
  };

  const onContainerScroll = useCallback(
    (e: Event) => {
      const { scrollTop, scrollHeight, offsetHeight } =
        e.currentTarget as HTMLDivElement;
      const isAtBottom = scrollTop >= scrollHeight - offsetHeight - 10;

      if (isAtBottom) {
        setIsScrolled(true);
        e.currentTarget?.removeEventListener('scroll', onContainerScroll);
      }
    },
    [setIsScrolled],
  );

  useEffect(() => {
    const containerEl = contractContainer.current;

    containerEl?.addEventListener('scroll', onContainerScroll, {
      passive: true,
    });

    return () => {
      containerEl?.removeEventListener('scroll', onContainerScroll);
    };
  }, [contractContainer, onContainerScroll]);

  return (
    <>
      <Box
        overflow="hidden"
        position="relative"
        marginBottom={theme.spacing(3)}
      >
        <Box
          sx={{
            height: 'calc(100vh - 320px)',
            overflowY: 'scroll',
            backgroundColor: GREY,

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-20px',
              left: '0',
              width: '100%',
              height: '20px',
              boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.16)',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-20px',
              left: '0',
              width: '100%',
              height: '20px',
              boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.16)',
            },

            [theme.breakpoints.up('sm')]: {
              maxHeight: 'calc(100vh - 350px)',
              minHeight: '300px',
              padding: theme.spacing(1, 2),
            },

            [theme.breakpoints.up('md')]: {
              maxHeight: 'calc(100vh - 475px)',
            },
          }}
          ref={contractContainer}
          data-testid="contract-container"
        >
          {children}
        </Box>
      </Box>

      {!isScrolled && (
        <Box
          sx={{
            padding: theme.spacing(0, 1.5),

            [theme.breakpoints.up('sm')]: {
              paddingLeft: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
              paddingRight: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
            },

            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(0, 3),
            },
          }}
        >
          <Button
            variant="outlined-gradient"
            fullWidth
            onClick={handleScrollButtonClick}
          >
            Review
          </Button>
        </Box>
      )}
    </>
  );
};

export default ContractScrollWrapper;
