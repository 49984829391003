import { API } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Alert, Box, CircularProgress } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import {
  getReferralCodeFromCookies,
  removeReferralCodeFromCookies,
} from 'helpers/referralCode';
import { REFERRED_REWARD_AMOUNT } from 'helpers/referralClaims';

const fetchReferralCode = async (referralCode?: string) => {
  if (!referralCode) {
    return;
  }

  const { referralCode: referralCodeResponse } = await API.get(
    'billing',
    `/billing/public/referral-codes/${referralCode}`,
    {},
  );

  return referralCodeResponse;
};

const ReferralCodeValidateBanner: React.FC = () => {
  const referralCode = getReferralCodeFromCookies();

  const { data, isLoading } = useQuery(['referralCode', referralCode], () =>
    fetchReferralCode(referralCode),
  );

  const isReferralCodeValid = !!data;

  useEffect(() => {
    // Remove cookie if referral code is invalid
    if (!isLoading && referralCode && !isReferralCodeValid) {
      removeReferralCodeFromCookies();
    }
  }, [referralCode, isLoading, isReferralCodeValid]);

  if (!referralCode) {
    return null;
  }

  if (isLoading) {
    return (
      <Alert
        icon={false}
        severity="info"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(3),
        }}
      >
        {/* Need to set width to prevent the scroll bar poping showing during animatino */}
        <Box width={60}>
          <CircularProgress color="inherit" size={48} />
        </Box>
      </Alert>
    );
  }

  if (!isReferralCodeValid) {
    return null;
  }

  return (
    <Box
      className="ReferralCodeValidateBanner"
      data-testid="ReferralCodeValidateBanner"
      sx={{ marginBottom: theme.spacing(3) }}
    >
      <Alert severity="success" icon={false}>
        You're invited! Join Kovo with a special offer: get{' '}
        {REFERRED_REWARD_AMOUNT} when you make 3 on-time payments.
      </Alert>
    </Box>
  );
};

export default ReferralCodeValidateBanner;
