import { useState } from 'react';
import { Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import PhoneNumberForm from 'components/PhoneNumberForm';
import PhoneNumberVerificationForm from 'components/PhoneNumberVerificationForm';

interface Props {
  onSuccess: () => void;
}

const PhoneNumberUpdate: React.FC<Props> = ({ onSuccess }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleSuccess = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  return (
    <>
      {activeStep === 0 && (
        <>
          <PhoneNumberForm onSuccess={handleSuccess} />

          <Typography variant="footnote" marginTop={theme.spacing(3)}>
            By sending a code, you are confirming this mobile phone number
            belongs to you and authorizing Kovo to text you at this number.
          </Typography>
        </>
      )}

      {activeStep === 1 && (
        <PhoneNumberVerificationForm
          goBack={handleBack}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default PhoneNumberUpdate;
