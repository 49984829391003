import { Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';

const LogOutButton: React.FC = () => {
  return (
    <Box textAlign="center" marginTop={theme.spacing(1)}>
      <MuiLink
        component={Link}
        to="/logout"
        underline="always"
        variant="footnote"
      >
        Log out
      </MuiLink>
    </Box>
  );
};

export default LogOutButton;
