import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CatchupPaymentSelectTabValue } from './types';
import { Account, ProductTypes } from 'types/schemas';
import useGetAccounts, {
  USE_ACCOUNTS_QUERY_KEY,
} from 'hooks/queries/useGetAccounts';
import { calculateAmountPastDue } from 'components/CatchupPayment/past-due-amount-calculations';
import { createTransaction } from 'hooks/mutations/useCreateTransaction';
import { useAuthContext } from 'context/AuthProvider';
import PageLoader from 'components/PageLoader';
import { useProductConfig } from 'hooks/useProductConfig';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useClientsService } from 'hooks/useClientsService';
import { onConfirmPaymentMethod } from 'components/CheckoutPaymentMethods';

export interface CatchupPaymentContextState {
  catchupAmount: number;
  setCatchupAmount: Dispatch<SetStateAction<number>>;
  tab: CatchupPaymentSelectTabValue;
  setTab: (tab: CatchupPaymentSelectTabValue) => void;
  productType: ProductTypes;
  account: Account;
  pastDueAmountDollars: number;
  createCatchupPayment: (amount: number) => Promise<void>;
  onConfirmPaymentMethod: onConfirmPaymentMethod;
}

export const CatchupPaymentContext = createContext<CatchupPaymentContextState>({
  catchupAmount: 0,
  setCatchupAmount: () => {},
  tab: '0',
  setTab: () => {},
  productType: 'loan_installment',
  account: {} as any,
  pastDueAmountDollars: 0,
  createCatchupPayment: async () => {},
  onConfirmPaymentMethod: async () => {},
});

export const useCatchupPaymentContext = () => {
  return useContext(CatchupPaymentContext);
};

const withCatchupPaymentProvider = <P extends {} = {}>(Component: FC<P>) => {
  return (props: P & { productType: ProductTypes }) => {
    const [catchupAmount, setCatchupAmount] = useState<number>(0);
    const [tab, setTabState] = useState<CatchupPaymentSelectTabValue>('0');

    const productConfig = useProductConfig(props.productType);
    const history = useHistory();
    const queryClient = useQueryClient();
    const clientsService = useClientsService();

    const { identityId: userId, username } = useAuthContext();
    const { data } = useGetAccounts();

    const account = data?.accounts?.find(
      (account) => account.product.type === props.productType,
    );

    useEffect(() => {
      if (account && catchupAmount === 0) {
        setCatchupAmount(calculateAmountPastDue(account));
      }
    }, [account, catchupAmount]);

    const createCatchupPayment = useCallback(
      async (amount: number) => {
        if (!account) {
          throw new Error('Account not found to make catchup payment');
        }

        await createTransaction({
          accountId: account.id,
          amount: amount * 100 * -1,
          trigger: 'manual',
          adjustment: false,
          shouldCapture: true,
          userId,
          username,
        });

        queryClient.invalidateQueries({
          queryKey: [USE_ACCOUNTS_QUERY_KEY],
        });

        history.push(productConfig.infoRoute);
      },
      [
        account,
        userId,
        username,
        history,
        productConfig.infoRoute,
        queryClient,
      ],
    );

    const onConfirmPaymentMethod = useCallback<onConfirmPaymentMethod>(
      async ({ token, walletType, values, last4 }) => {
        await clientsService.post('/v1/payment_methods', {
          integrationId: 'checkout',
          postalCode: values?.zipcode,
          integrationSourceToken: token,
          default: true,
          walletType,
          last4,
        });

        await createCatchupPayment(catchupAmount);
      },
      [clientsService, createCatchupPayment, catchupAmount],
    );

    const setTab = useCallback(
      (tab: CatchupPaymentSelectTabValue) => {
        setTabState(tab);

        if (tab === '0' && account) {
          setCatchupAmount(calculateAmountPastDue(account));
        }
      },
      [setTabState, account],
    );

    if (!account) {
      return <PageLoader />;
    }

    const pastDueAmountDollars = calculateAmountPastDue(account);

    return (
      <CatchupPaymentContext.Provider
        value={{
          catchupAmount,
          setCatchupAmount,
          tab,
          setTab,
          productType: props.productType,
          account,
          pastDueAmountDollars,
          createCatchupPayment,
          onConfirmPaymentMethod,
        }}
      >
        <Component {...props} />
      </CatchupPaymentContext.Provider>
    );
  };
};

export default withCatchupPaymentProvider;
