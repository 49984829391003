import { useRef } from 'react';
import { Box } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import KovoLogo from 'components/KovoLogo';
import OnboardingStepIndicator from 'components/OnboardingStepIndicator';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
  onboardingStep?: 1 | 2 | 3 | 4 | 5;
  centerLogo?: boolean;
  linkLogoToHomepage?: boolean;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
}

const OnboardingHeader: React.FC<Props> = ({
  linkLogoToHomepage,
  centerLogo,
  onboardingStep,
  showBackButton,
  onBackButtonClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      component="header"
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        margin: theme.spacing(0, -1.5, 2),
        padding: theme.spacing(1.5),
        transition: 'box-shadow 0.2s linear',

        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(0, -3, 2),
          padding: theme.spacing(3),
        },

        // extend header full width
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          width: 'calc((100vw - 100%) / 2)',
          height: 1,
          backgroundColor: 'inherit',
        },
        '&:before': {
          left: '100%',
        },
        '&:after': {
          right: '100%',
        },
      }}
    >
      {showBackButton && (
        <Box
          component="a"
          onClick={onBackButtonClick}
          href={onBackButtonClick ? '#' : undefined}
          sx={{
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(2.5),
            color: theme.palette.text.primary,

            [theme.breakpoints.up('md')]: {
              left: theme.spacing(2.5),
            },
          }}
        >
          <ArrowBackIosNewIcon />
        </Box>
      )}
      {linkLogoToHomepage && !window.ReactNativeWebView ? (
        <Box
          component="a"
          href="https://kovocredit.com/"
          fontSize={0}
          sx={{
            ...(!centerLogo && { marginRight: 'auto' }),
          }}
        >
          <KovoLogo centerLogo={centerLogo} />
        </Box>
      ) : (
        <KovoLogo centerLogo={centerLogo} />
      )}

      {onboardingStep && (
        <OnboardingStepIndicator activeStep={onboardingStep} />
      )}
    </Box>
  );
};

export default OnboardingHeader;
