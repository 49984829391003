import { Typography } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';

import useApplicationStatus from 'hooks/useApplicationStatus';
import { theme } from 'context/ThemeProvider';
import OnboardingPage from 'components/OnboardingPage/OnboardingPage';
import PhoneNumberForm from 'components/PhoneNumberForm';
import PageLoader from 'components/PageLoader/PageLoader';

const PhoneNumberPage: React.FC = () => {
  const history = useHistory();

  const { data: applicationData, isLoading } = useApplicationStatus();

  const goToNextPage = () => {
    history.push('/verify-phone-number');
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (!applicationData) {
    return null;
  }

  if (!['new', 'detailsNeeded'].includes(applicationData.applicationStatus)) {
    return <Redirect to="/" />;
  }

  if (!!applicationData.applicationDetails?.phoneNumber) {
    return <Redirect to="/apply" />;
  }

  return (
    <OnboardingPage onboardingStep={1} supportSubject="add-phone-number">
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Add your number
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        We need to verify your mobile phone number to start your application.
      </Typography>

      <PhoneNumberForm onSuccess={goToNextPage} />

      <Typography variant="footnote" marginTop={theme.spacing(3)}>
        By sending a code, you are confirming this mobile phone number belongs
        to you and authorizing Kovo to text you at this number.
      </Typography>
    </OnboardingPage>
  );
};

export default PhoneNumberPage;
