import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import HellosignContractDownload from 'components/HellosignContractDownload';
import InstallmentsContractDownload from 'components/InstallmentsContractDownload';
import ChargeAccountContractDownload from 'components/charge-account/ChargeAccountContractDownload';

const DocumentsCard: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading) {
    return <Skeleton variant="rounded" height={117} />;
  }

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">DOCUMENTS</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0, 0) }} />

        <ChargeAccountContractDownload />

        {data && data.applicationDetails?.contractProvider === 'internal' ? (
          <InstallmentsContractDownload />
        ) : (
          <HellosignContractDownload />
        )}
      </CardContent>
    </Card>
  );
};

export default DocumentsCard;
