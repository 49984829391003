import { Box } from '@mui/material';

import html from './summary-of-benefits';
import { wrapHtmlBodyInDiv } from 'helpers/html';

const SummaryOfBenefits: React.FC = () => {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: wrapHtmlBodyInDiv(html, 'summary-of-benefits'),
      }}
    />
  );
};

export default SummaryOfBenefits;
