import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

import CreditCard from 'assets/img/icons/credit-card.svg';
import ApplePay from 'assets/img/icons/apple-pay.svg';
import GooglePay from 'assets/img/icons/google-pay.svg';
import { PaymentMethod } from 'types/schemas';

interface PaymentMethodLabelProps {
  paymentMethod: PaymentMethod;
  hideIcons?: boolean;
}

const PaymentMethodLabel: FC<PaymentMethodLabelProps> = ({
  paymentMethod,
  hideIcons = false,
}) => {
  return (
    <Box component="span" display="flex">
      {paymentMethod.walletType === null && (
        <>
          <Box
            component="img"
            src={CreditCard}
            marginRight={theme.spacing(1)}
            hidden={hideIcons}
          />
          <Typography component="span" variant="inherit">
            Card ••{paymentMethod.last4}
          </Typography>
        </>
      )}

      {paymentMethod.walletType === 'apple_pay' && (
        <>
          <Box
            hidden={hideIcons}
            component="img"
            src={ApplePay}
            marginRight={theme.spacing(1)}
          />
          <Typography component="span" variant="inherit">
            Apple Pay
          </Typography>
        </>
      )}

      {paymentMethod.walletType === 'google_pay' && (
        <>
          <Box
            hidden={hideIcons}
            component="img"
            src={GooglePay}
            marginRight={theme.spacing(1)}
          />
          <Typography component="span" variant="inherit">
            Google Pay ••{paymentMethod.last4}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default PaymentMethodLabel;
