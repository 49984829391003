import { Auth } from 'aws-amplify';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link } from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { theme } from 'context/ThemeProvider';
import TextField from 'components/shared/TextField';
import { normalizeEmail } from 'helpers/utils';

const SUPPORT_EMAIL = 'support@kovocredit.com';

interface FormValues {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const history = useHistory();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Email is required'),
      }),
      onSubmit,
    });

  const sendConfirmationCode = async (values: FormValues) => {
    try {
      const email = normalizeEmail(values.email);

      await Auth.forgotPassword(email);

      return { ...values, email };
    } catch (e: any) {
      // If the user is not found, we still want to show the success message
      // to prevent account enumeration.
      if (e.name === 'UserNotFoundException') {
        return values;
      }

      throw e;
    }
  };

  const onSuccess = (values: FormValues) => {
    const email = normalizeEmail(values.email);
    history.replace(`/reset-password?email=${email}`);
  };

  const {
    mutate,
    isLoading,
    error: serverError,
  } = useMutation<FormValues, Error, FormValues>(sendConfirmationCode, {
    onSuccess,
  });

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {serverError.name === 'InvalidParameterException' ? (
            <>
              Please email{' '}
              <Link
                href={`mailto:${SUPPORT_EMAIL}?subject=forgot-password-issue`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {SUPPORT_EMAIL}
              </Link>{' '}
              to reset your password.
            </>
          ) : (
            'There was an error. Please try again.'
          )}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Box marginBottom={theme.spacing(3)}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={values.email}
            error={!!(touched.email && errors.email)}
            errorText={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          fullWidth
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
