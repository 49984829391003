import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { DARK_BLUE, KOVO_GREEN, theme } from 'context/ThemeProvider';
import useGetApplications from 'hooks/queries/useGetApplications';
import OnboardingPage from 'components/OnboardingPage';
import ApplyButton from 'components/charge-account/ApplyButton';
import ChargeAccountRedirect from 'components/charge-account/ChargeAccountRedirect';
import PopupDialog from 'components/PopupDialog';
import SummaryOfBenefits from 'components/credit-protection/SummaryOfBenefits';
import ChargeAccountCreateApplication from 'components/charge-account/ChargeAccountCreateApplication';

import Checkmark from 'assets/img/icons/checkmark-green.svg';
import Badge from 'assets/img/icons/one-hundred-percent-badge.svg';
import BadgeLarge from 'assets/img/icons/one-hundred-percent-badge-large.svg';
import Clock from 'assets/img/icons/clock.svg';

const listItemIconSx = {
  height: '64px',
  width: '64px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1.5),
  marginLeft: theme.spacing(0.5),
  color: DARK_BLUE,

  [theme.breakpoints.up('md')]: {
    height: '78px',
    width: '78px',
  },
};

const CREDIT_BUREAUS = ['TransUnion®', 'Equifax®', 'Experian®', 'Innovis®'];

const numberTypographySx = {
  color: KOVO_GREEN,
  fontSize: '80px',
  fontWeight: '300',
  lineHeight: '55px',
  marginBottom: theme.spacing(1.5),
};

const ChargeAccountWhatYouGet: React.FC = () => {
  const { data, isLoading } = useGetApplications();
  const chargeAccountApplication = data?.chargeAccountApplication;

  const isDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <ChargeAccountRedirect />

      {!isLoading && !chargeAccountApplication && (
        <ChargeAccountCreateApplication />
      )}

      <OnboardingPage>
        <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
          What you get
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          Powered up credit-building! Get the single-purpose Credit Line with
          Maximum Credit Protection for $10/month.
        </Typography>

        <Typography>
          Build your credit with <strong>all 4 credit bureaus</strong>:
        </Typography>

        <List
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            marginBottom: theme.spacing(4.5),
          }}
        >
          {CREDIT_BUREAUS.map((text) => (
            <ListItem disablePadding key={text}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1) }}>
                <Box
                  component="img"
                  src={Checkmark}
                  alt=""
                  height="18px"
                  width="22px"
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>

        <Typography sx={numberTypographySx}>1</Typography>
        <Typography variant="h4" marginBottom={theme.spacing(1.5)}>
          Build 100% of your credit score
        </Typography>
        <List disablePadding>
          <ListItem disableGutters>
            <ListItemIcon
              sx={{
                ...listItemIconSx,
                backgroundColor: 'rgba(32, 211, 133, 0.60)',
              }}
            >
              <Typography variant="h4">35%</Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography>
                Build <strong>payment history</strong> with $10/month
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon
              sx={{
                ...listItemIconSx,
                backgroundColor: 'rgba(32, 211, 133, 0.50)',
              }}
            >
              <Typography variant="h4">30%</Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography>
                Reduce <strong>credit utilization</strong> with a $500 limit
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon
              sx={{
                ...listItemIconSx,
                backgroundColor: 'rgba(32, 211, 133, 0.40)',
              }}
            >
              <Typography variant="h4">15%</Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography>
                Increase your <strong>age of accounts</strong> every month it’s
                open
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon
              sx={{
                ...listItemIconSx,
                backgroundColor: 'rgba(32, 211, 133, 0.30)',
              }}
            >
              <Typography variant="h4">10%</Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography>
                Expand your <strong>credit mix</strong> with a revolving charge
                account
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon
              sx={{
                ...listItemIconSx,
                backgroundColor: 'rgba(32, 211, 133, 0.30)',
              }}
            >
              <Typography variant="h4">10%</Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography>
                <strong>No hard inquiry</strong>
              </Typography>
            </ListItemText>
          </ListItem>
        </List>

        <Box
          sx={{
            height: '1px',
            width: '74px',
            backgroundColor: theme.palette.common.black,
            margin: theme.spacing(1, 0),

            [theme.breakpoints.up('md')]: {
              width: '88px',
            },
          }}
        />
        <Box
          component="img"
          src={isDesktopWidth ? BadgeLarge : Badge}
          alt="100%"
          marginBottom={theme.spacing(6)}
        />

        <Typography sx={numberTypographySx}>2</Typography>
        <Typography variant="h4" marginBottom={theme.spacing(1.5)}>
          Maximum credit protection
        </Typography>
        <List disablePadding sx={{ marginBottom: theme.spacing(6) }}>
          {[
            'Up to $1 Million ID Fraud Insurance',
            '24/7 ID Theft Resolution Services',
            // 'Real-time ID Monitoring', TODO un-comment when ID monitoring is live
          ].map((item) => (
            <ListItem disablePadding key={item}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1) }}>
                <Box component="img" src={Checkmark} alt="" />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>

        <Typography sx={numberTypographySx}>3</Typography>
        <Typography variant="h4" marginBottom={theme.spacing(1.5)}>
          Rewarding features
        </Typography>
        <List disablePadding sx={{ marginBottom: theme.spacing(6) }}>
          {[
            'FICO® Score updates',
            'Unlimited $30 for every referral',
            'Access to financial tools and services',
          ].map((item) => (
            <ListItem disablePadding key={item}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1) }}>
                <Box component="img" src={Checkmark} alt="" />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>

        <Typography sx={numberTypographySx}>4</Typography>
        <Typography variant="h4" marginBottom={theme.spacing(1.5)}>
          Safety built-in
        </Typography>
        <List disablePadding sx={{ marginBottom: theme.spacing(6) }}>
          {[
            '$0 fees, 0% interest',
            'No credit check',
            'Protect your credit while you build it',
          ].map((item) => (
            <ListItem disablePadding key={item}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1) }}>
                <Box component="img" src={Checkmark} alt="" />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>

        <Box
          component="img"
          src={Clock}
          alt=""
          marginBottom={theme.spacing(1.5)}
        />
        <Typography variant="h4" marginBottom={theme.spacing(1.5)}>
          Clock’s ticking!
        </Typography>
        <Typography marginBottom={theme.spacing(6)}>
          Having a long-term account is critical to building credit. With every
          month your Kovo Credit Line is open, you build your score that much
          more.
        </Typography>

        <ApplyButton />

        <Typography variant="footnote" margin={theme.spacing(3, 0)}>
          By continuing, you are authorizing Kovo to initialize Maximum Credit
          Protection with your information. ID Fraud Insurance is underwritten
          and administered by American Bankers Insurance Company of Florida, an
          Assurant company. Refer to{' '}
          <PopupDialog dialogText={<SummaryOfBenefits />} variant="footnote">
            Summary of Benefits
          </PopupDialog>{' '}
          for coverage terms, conditions, and exclusions.
        </Typography>
      </OnboardingPage>
    </>
  );
};

export default ChargeAccountWhatYouGet;
