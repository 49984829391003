import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import NavigationItem from 'components/NavigationItem';

import InstallmentPlan from 'assets/img/icons/navigation/installment-plan.svg';
import InstallmentPlanLarge from 'assets/img/icons/navigation/installment-plan_large.svg';
import { loanAccountStatusToLabelMap } from 'libs/loanAccountStatusToLabelMap';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import { calculateAmountPastDue } from 'components/CatchupPayment/past-due-amount-calculations';

const InstallmentPlanNavItem: React.FC = () => {
  const { data, isFetching } = useGetAccounts({
    refetchInterval: (data) => {
      return !data?.installmentAccount ? 500 : false;
    },
  });

  if (isFetching) {
    return (
      <Grid item xs={12} md={6} order={{ xs: 0 }}>
        <NavigationItem
          route="/installment-plan"
          icon={InstallmentPlan}
          iconDesktop={InstallmentPlanLarge}
          title="Installment Plan"
          subtitle={
            <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
              <Skeleton />
            </Typography>
          }
        />
      </Grid>
    );
  }

  const installmentAccount = data?.installmentAccount;

  if (!installmentAccount) {
    return null;
  }

  const loanStatus = installmentAccount.status;
  const isPastDue = loanStatus === 'past_due';

  const statusLabel = loanAccountStatusToLabelMap[loanStatus];

  if (isPastDue) {
    const amountDueDollars = calculateAmountPastDue(installmentAccount);

    return (
      <Grid item xs={12} md={6} order={{ xs: 0 }}>
        <NavigationItem
          route="/installment-plan"
          icon={InstallmentPlan}
          iconDesktop={InstallmentPlanLarge}
          title="Installment Plan"
          subtitle={
            <>
              <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
                Account Status:{' '}
                <Box component="strong" color={statusLabel.color}>
                  {statusLabel.label} ${amountDueDollars}
                </Box>
              </Typography>
              <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
                <strong>Missed payments can harm your credit</strong>
              </Typography>
            </>
          }
          ctaButton={
            <Button variant="contained" fullWidth>
              Pay Now
            </Button>
          }
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6} order={{ xs: 0 }}>
      <NavigationItem
        route="/installment-plan"
        icon={InstallmentPlan}
        iconDesktop={InstallmentPlanLarge}
        title="Installment Plan"
        subtitle={
          <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
            Account Status:{' '}
            <Box component="strong" color={statusLabel.color}>
              {statusLabel.label}
            </Box>
          </Typography>
        }
      />
    </Grid>
  );
};

export default InstallmentPlanNavItem;
