import { useEffect } from 'react';

const EccPage: React.FC = () => {
  useEffect(() => {
    // /ecc is decprecated
    window.location.href = 'https://kovocredit.com/legal/e-sign';
  }, []);

  return null;
};

export default EccPage;
