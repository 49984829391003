import { Box, Button, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import NavigationItem from 'components/NavigationItem';
import { ROUTES } from 'components/Routes';

import ChargeAccountIcon from 'assets/img/icons/navigation/charge-account.svg';
import ChargeAccountIconLarge from 'assets/img/icons/navigation/charge-account_large.svg';
import { loanAccountStatusToLabelMap } from 'libs/loanAccountStatusToLabelMap';

const ChargeAccountNavItem: React.FC = () => {
  const { data, isFetching } = useGetAccounts({
    // there is a delay in charge account creation, refetch until it's defined
    refetchInterval: (data) => {
      return !data?.chargeAccount ? 500 : false;
    },
  });

  if (isFetching) {
    return (
      <NavigationItem
        route={ROUTES.CREDIT_LINE}
        icon={ChargeAccountIcon}
        iconDesktop={ChargeAccountIconLarge}
        title="Credit Line"
        subtitle={
          <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
            <Skeleton />
          </Typography>
        }
      />
    );
  }

  if (!data) {
    return null;
  }

  const { chargeAccount } = data;

  if (!chargeAccount) {
    // this is essentially a loading view while charge account is loading
    return (
      <NavigationItem
        route={ROUTES.CREDIT_LINE}
        icon={ChargeAccountIcon}
        iconDesktop={ChargeAccountIconLarge}
        title="Credit Line"
      />
    );
  }

  const statusLabel = loanAccountStatusToLabelMap[chargeAccount.status];

  return (
    <NavigationItem
      route={ROUTES.CREDIT_LINE}
      icon={ChargeAccountIcon}
      iconDesktop={ChargeAccountIconLarge}
      title="Credit Line"
      subtitle={
        <>
          <Typography variant="footnote" marginTop={theme.spacing(0.25)}>
            Account Status:{' '}
            <Box component="strong" color={statusLabel.color}>
              {statusLabel.label}
            </Box>
          </Typography>
        </>
      }
      ctaButton={
        chargeAccount.status === 'past_due' ? (
          <Button variant="contained" fullWidth>
            Pay Now
          </Button>
        ) : undefined
      }
    />
  );
};

export default ChargeAccountNavItem;
