import { Redirect } from 'react-router';

import { ROUTES } from 'components/Routes';

// /contact-us is deprecated
const ContactUs: React.FC = () => {
  return <Redirect to={ROUTES.SETTINGS} />;
};

export default ContactUs;
