import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';

const textMapping: {
  [key: string]: { description: string; banner?: string };
} = {
  'Credit Cards': {
    description:
      'Looking for a credit card? View offers from issuers in the Kovo network.',
    banner:
      'You’re eligible to earn up to $75 when you open a credit card with an issuer through the Kovo network.',
  },
  'Personal Loans': {
    description:
      'Looking for a personal loan? View offers from lenders in the Kovo network.',
    banner:
      'You’re eligible to earn 1% back (up to $500) when you get a personal loan with a lender through the Kovo network.',
  },
  'Student Loans': {
    description:
      'Looking for a student loan? View offers from lenders in the Kovo network.',
    banner:
      'You’re eligible to earn 1% back (up to $250) when you get a student loan with a lender through the Kovo network.',
  },
  'Student Loan Refinancing': {
    description:
      'Looking to refinance a student loan? View offers from lenders in the Kovo network.',
    banner:
      'You’re eligible to earn 1% back (up to $250) when you get a student loan refinanced with a lender through the Kovo network.',
  },
  'Auto Loan Refinancing': {
    description:
      'Looking to refinance an auto loan? View offers from lenders in the Kovo network.',
    banner:
      'You’re eligible to earn 1% back (up to $150) when you get an auto loan refinanced with a lender through the Kovo network.',
  },
  Insurance: {
    description:
      'Looking for insurance? View offers from providers in the Kovo network.',
  },
  Services: {
    description: 'View offers from service providers in the Kovo network.',
  },
};

const OfferCategoryHeading: React.FC = () => {
  const { identityId, username } = useContext(AuthContext);

  const { categoryId } = useParams<{ categoryId?: string }>();

  const { data: applicationStatus } = useApplicationStatus();

  const getCategoryById = async (categoryId?: string) => {
    if (!categoryId) return;

    const category = await API.get(
      'billing',
      `/billing/offer-categories/${categoryId}`,
      amplifyRequestContext(identityId, username),
    );

    return category;
  };

  const { data: category, isLoading } = useQuery(['category', categoryId], () =>
    getCategoryById(categoryId),
  );

  const descriptionText = textMapping[category?.name]?.description;

  const bannerText = textMapping[category?.name]?.banner;

  return (
    <>
      <Box
        maxWidth="sm"
        sx={{
          marginBottom: theme.spacing(2),
          marginLeft: 'auto',
          marginRight: 'auto',

          [theme.breakpoints.up('md')]: { textAlign: 'center' },
        }}
      >
        <Typography variant="h1">
          {isLoading ? (
            <Skeleton width="170px" sx={{ display: 'inline-block' }} />
          ) : (
            category.name
          )}
        </Typography>

        {descriptionText && (
          <Typography marginTop={theme.spacing(1)}>
            {isLoading ? <Skeleton /> : descriptionText}
          </Typography>
        )}
      </Box>

      {applicationStatus &&
        applicationStatus.rewardsStatus.isEligibleForRewards3Months &&
        bannerText && (
          <Card variant="gradient" sx={{ marginBottom: theme.spacing(2) }}>
            <CardContent>
              <Typography>{bannerText}</Typography>
            </CardContent>
          </Card>
        )}
    </>
  );
};

export default OfferCategoryHeading;
