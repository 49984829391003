import {
  APP_STORE_URL,
  PLAY_STORE_URL,
  getPlatformUserAgent,
} from 'helpers/mobile-app';
import { useEffect } from 'react';
import AppPreviewImage from 'assets/img/app-download/app_preview.png';
import { Box, Typography } from '@mui/material';
import { theme } from 'context/ThemeProvider';
import {
  AppStoreButton,
  PlayStoreButton,
} from 'components/AppDownloadElement/AppDownloadElement';
const DownloadPage: React.FC = () => {
  useEffect(() => {
    // Redirect to the app stores
    const currentPlatform = getPlatformUserAgent();
    if (currentPlatform === 'ios') {
      window.location.href = APP_STORE_URL;
    } else if (currentPlatform === 'android') {
      window.location.href = PLAY_STORE_URL;
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          marginTop: 'auto',
          marginBottom: 'auto',

          paddingLeft: theme.spacing(5),
          paddingRight: theme.spacing(5),
          flexDirection: 'column',

          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(3),
          },
        }}
      >
        <Box
          width="100%"
          component="img"
          alt="App Preview"
          src={AppPreviewImage}
          sx={{
            marginBottom: theme.spacing(2),
            maxWidth: '190px',

            [theme.breakpoints.up('sm')]: {
              marginBottom: theme.spacing(5),
              maxWidth: '216px',
            },

            [theme.breakpoints.up('md')]: {
              marginBottom: 0,
              maxWidth: '225px',
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',

              [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(2),
              },
            }}
          >
            {['Get the', 'Kovo App!'].map((text, index) => (
              <Typography
                key={text}
                variant="h1"
                textAlign="center"
                sx={{
                  fontSize: '35px',
                  lineHeight: '100%',

                  [theme.breakpoints.up('sm')]: {
                    marginBottom: index === 0 ? theme.spacing(1) : 0,
                    fontSize: '44px',
                  },

                  [theme.breakpoints.up('md')]: {
                    fontSize: '48px',
                  },
                }}
              >
                {text}
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: theme.spacing(2),
              maxWidth: '180px',
              gap: theme.spacing(1),

              [theme.breakpoints.up('sm')]: {
                maxWidth: '260px',
                marginTop: theme.spacing(5),
                gap: theme.spacing(2),
              },

              [theme.breakpoints.up('md')]: {
                gap: theme.spacing(1),
              },
            }}
          >
            <AppStoreButton />
            <PlayStoreButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadPage;
