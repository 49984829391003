import { Skeleton } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useCheckoutStatusV2 from 'hooks/useCheckoutStatusV2';
import CheckoutStatusBanner from 'components/CheckoutStatusBanner';
import PastDueRewardsBanner from 'components/PastDueRewardsBanner';
import EligibleForRewardsBanner from 'components/EligibleForRewardsBanner';
import IneligibleForRewardsBanner from 'components/IneligibleForRewardsBanner';

const CheckoutRewardBanner: React.FC = () => {
  const { data: applicationStatusData, isLoading: isLoadingApplication } =
    useApplicationStatus();

  const { data: checkoutStatus, isLoading: isLoadingCheckout } =
    useCheckoutStatusV2();

  if (isLoadingCheckout || isLoadingApplication) {
    return (
      <Skeleton
        variant="rounded"
        height={72}
        sx={{ marginBottom: theme.spacing(2) }}
      />
    );
  }

  if (!checkoutStatus) {
    return null;
  }

  const isEligibleForRewards =
    applicationStatusData?.rewardsStatus.isEligibleForRewards3Months;
  const isPastDue = checkoutStatus.status === 'past_due';

  if (isPastDue) {
    return (
      <>
        <PastDueRewardsBanner />

        <CheckoutStatusBanner onlyShowPastDue />
      </>
    );
  }

  if (isEligibleForRewards) {
    return <EligibleForRewardsBanner />;
  }

  return <IneligibleForRewardsBanner />;
};

export default CheckoutRewardBanner;
