import ResetPasswordForm from 'components/ResetPasswordForm';
import OnboardingPage from 'components/OnboardingPage';

const ResetPasswordPage: React.FC = () => {
  return (
    <OnboardingPage
      centerLogo
      linkLogoToHomepage
      supportSubject="forgot password"
    >
      <ResetPasswordForm />
    </OnboardingPage>
  );
};

export default ResetPasswordPage;
