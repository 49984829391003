import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';

export type CreditScore = {
  craData: { [key: string]: string };
  craSource: 'tu' | undefined;
  scoreDate: string;
  userId: string;
};

export type GetCreditScoresResponse = {
  creditScores: CreditScore[];
};

const EFX_SCORE_KEY = 'Score_2352_1';
const TU_SCORE_KEY = 'cemp08_bFinscr';

const parseCreditScoreData = (creditScore: CreditScore) => {
  const isTuScore = creditScore.craSource === 'tu';

  return isTuScore
    ? creditScore.craData[TU_SCORE_KEY]
    : creditScore.craData[EFX_SCORE_KEY];
};

function useGetCreditScores() {
  const { identityId, username } = useContext(AuthContext);

  const getCreditScores = async () => {
    const res: GetCreditScoresResponse = await API.get(
      'clientsService',
      `/v1/credit_scores`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    if (!res.creditScores.length || res.creditScores.length === 1) {
      return { ...res, scoreIncrease: null };
    }

    const orderedScores = res.creditScores.sort(
      (a, b) =>
        new Date(a.scoreDate).getTime() - new Date(b.scoreDate).getTime(),
    );
    const firstCreditScore = parseInt(parseCreditScoreData(orderedScores[0]));
    const latestCreditScore = parseInt(
      parseCreditScoreData(orderedScores[orderedScores.length - 1]),
    );
    const scoreIncrease = latestCreditScore - firstCreditScore;

    return { ...res, scoreIncrease };
  };

  return useQuery<GetCreditScoresResponse & { scoreIncrease: number | null }>(
    'credit-scores',
    getCreditScores,
    {
      refetchOnWindowFocus: false,
    },
  );
}

export default useGetCreditScores;
