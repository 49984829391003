import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import PageLoader from 'components/PageLoader';
import OnboardingPage from 'components/OnboardingPage';

const ApplicationRejected: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (data && !['applicationRejected'].includes(data.applicationStatus)) {
    return <Redirect to="/" />;
  }

  return (
    <OnboardingPage supportSubject="application rejected">
      <Typography variant="h1" marginBottom={theme.spacing(2)}>
        Unable to proceed with your application
      </Typography>

      <Typography marginBottom={theme.spacing(1)}>
        Thanks for applying to the Installment Plan.
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        After reviewing your background verification, we are unable to proceed
        with your application.
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>
        <strong>Please note</strong>: We did <strong>not</strong> run a credit
        check or review your credit report.
      </Typography>
    </OnboardingPage>
  );
};

export default ApplicationRejected;
