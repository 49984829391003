import {
  Box,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  Typography,
} from '@mui/material';

import { theme } from 'context/ThemeProvider';

import KovoLogoSvg from 'assets/img/logos/kovo-logo.svg';
import Close from 'assets/img/icons/close.svg';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  handleClick: (rating: 0 | 1) => void;
}

const AppReviewDialogView: React.FC<Props> = ({
  isOpen,
  onClose,
  handleClick,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'white',
        },
      }}
    >
      <DialogContent
        sx={{ textAlign: 'center', padding: theme.spacing(5.5, 3, 2.5) }}
      >
        <IconButton
          onClick={onClose}
          aria-label="Close dialog"
          data-testid="close-button"
          sx={{
            position: 'absolute',
            top: theme.spacing(2.5),
            left: theme.spacing(2.5),
            height: '24px',
            width: '24px',
            zIndex: '9999',
          }}
        >
          <Box component="img" src={Close} alt="✕" />
        </IconButton>

        <Box
          component="img"
          src={KovoLogoSvg}
          alt=""
          sx={{
            maxWidth: '62px',
            marginBottom: theme.spacing(4.5),
          }}
        />

        <Typography
          variant="h1"
          sx={{
            marginBottom: theme.spacing(3),
          }}
        >
          How would you rate your experience?
        </Typography>

        <Button
          variant="contained"
          fullWidth
          onClick={() => handleClick(1)}
          sx={{
            backgroundColor: '#2F683F',
          }}
        >
          <span
            style={{
              fontSize: '26px',
              marginRight: theme.spacing(1.5),
            }}
          >
            👍
          </span>
          Great!
        </Button>

        <Button
          variant="contained"
          fullWidth
          onClick={() => handleClick(0)}
          sx={{
            backgroundColor: '#75180C',
            marginTop: theme.spacing(3),
          }}
        >
          <span
            style={{
              fontSize: '26px',
              marginRight: theme.spacing(1.5),
            }}
          >
            🙁
          </span>
          Not so great
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AppReviewDialogView;
