import moment from 'moment';

import { INTEGER_REGEX } from 'helpers/constants';

export const isIterableArray = (array: any) =>
  Array.isArray(array) && !!array.length;

//===============================
// Store
//===============================

/**
 * Get an item from LocalStorage
 *
 * store.getItem is typed as a string because getItem returns string | null.
 * The built in types for JSON.parse only accept a string, but null is safe to pass as well.
 * Ironically, an empty string is not safe to pass to JSON.parse.
 */
export const getItemFromStore = (
  key: string,
  defaultValue: any,
  store = localStorage,
) => JSON.parse(store.getItem(key) as string) || defaultValue;

export const setItemToStore = (
  key: string,
  payload: any,
  store = localStorage,
) => store.setItem(key, JSON.stringify(payload));

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2),
  );

//===============================
// Moment
//===============================
export const getDuration = (startDate: Date, endDate: Date) => {
  if (!moment.isMoment(startDate))
    throw new Error(
      `Start date must be a moment object, received ${typeof startDate}`,
    );
  if (endDate && !moment.isMoment(endDate))
    throw new Error(
      `End date must be a moment object, received ${typeof startDate}`,
    );

  return `${startDate.format('ll')} - ${
    endDate ? endDate.format('ll') : 'Present'
  } • ${startDate.from(endDate || moment(), true)}`;
};

export const numberFormatter = (number: number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue: string) => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b),
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343',
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727',
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000',
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000',
};

export const getGrays = (isDark: boolean) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//===============================
// Strings
//===============================
export const parseDigits = (digits: string): string =>
  (digits.match(INTEGER_REGEX) || []).join('');

/**
 * Cognito does not care about email casing or trimming whitespace because we
 * are using an older user pool. We do not have a way to migrate to a new user pool,
 * so this is our fix to make sure all emails are lowercased and whitespace
 * is trimmed. This bug may not manifest itself in your local environment because
 * that user pool may have newer settings than the prod user pool.
 */
export const normalizeEmail = (email: string) => {
  return email.toLowerCase().trim();
};

export const normalizePhoneNumber = (phone: string): string => {
  return `+1${phone.replace(/-/g, '')}`;
};
