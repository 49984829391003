import { Link, Redirect } from 'react-router-dom';
import { Box, Link as MuiLink } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import Page from 'components/Page';
import OffersList from 'components/OffersList';
import OfferCategoryHeading from 'components/OfferCategoryHeading';
import PageLoader from 'components/PageLoader';
import { OFFERS_REWARDS_STATUSES } from './OfferCategoriesPage';

const OffersPage: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (!OFFERS_REWARDS_STATUSES.includes(data.applicationStatus)) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <MuiLink
        component={Link}
        to="/offer-categories"
        underline="none"
        variant="body1"
        color={theme.palette.primary.main}
        marginBottom={theme.spacing(2)}
        display="inline-block"
      >
        <ArrowBackIcon
          sx={{ verticalAlign: 'top', marginRight: theme.spacing(1) }}
        />
        <Box component="strong">Offers</Box>
      </MuiLink>

      <OfferCategoryHeading />

      <OffersList />
    </Page>
  );
};

export default OffersPage;
