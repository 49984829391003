import { Auth } from 'aws-amplify';
import { normalizeEmail } from 'helpers/utils';
import {
  KovoUnauthenticatedError,
  KovoEmailVerificationError,
  KovoError,
} from 'libs/KovoError';
import getSignupData from './getSignupData';

/**
 * Given a user's email and confirmation code, this function will confirm the user's email.
 * If the user is already confirmed, this function will do nothing.
 * If the confirmation code is invalid, this function will throw a KovoEmailVerificationError error.
 * If the user is not authorized, this function will throw a KovoUnauthenticatedError error.
 *
 */
export const confirmUserEmail = async (
  email: string,
  confirmationCode: string,
) => {
  try {
    const normalizedEmail = normalizeEmail(email);

    await Auth.confirmSignUp(normalizedEmail, confirmationCode, {
      clientMetadata: getSignupData(),
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'CodeMismatchException') {
        throw new KovoEmailVerificationError('Code mismatch exception.', {
          error,
        }).setDisplayMessage(
          'Invalid email verification code. Please try again.',
        );
      }

      /**
       * Catch and ignore NotAuthorizedException when the message is that the user is already
       * confirmed because we shouldn't call Auth.confirmSignUp twice anyway.
       */
      const isNotAuthorizedException = error.name === 'NotAuthorizedException';
      const isUserAlreadyConfirmedError = error.message.includes(
        'User cannot be confirmed. Current status is CONFIRMED',
      );

      if (isNotAuthorizedException && isUserAlreadyConfirmedError) {
        return;
      }

      if (isNotAuthorizedException) {
        throw new KovoUnauthenticatedError('Not authorized exception.', {
          error,
        }).setDisplayMessage('Error confirming user signup. Please try again.');
      }
    }

    throw new KovoError('Error confirming user signup.', {
      error,
    }).setDisplayMessage('Error signing up. Please try again.');
  }
};
