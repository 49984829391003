import { loadScript } from 'helpers/load-script';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuthContext } from './AuthProvider';
import config from 'config';
import { useLocation } from 'react-router-dom';
import { routeToAdaAnswerMapping } from 'data/ada/route-to-ada-answer-mapping';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { sendMessageToMobileApp } from 'helpers/mobile-app';

declare global {
  interface Window {
    adaEmbed?: AdaEmbed;
    adaSettings?: AdaSettings;
  }
}

/**
 * Docs: https://developers.ada.cx/reference/embed2-reference
 */
interface AdaEmbed {
  setSensitiveMetaFields: (fields: Record<string, string>) => Promise<void>;
  setMetaFields: (fields: Record<string, string>) => Promise<void>;
  start: (options?: Partial<AdaSettings>) => Promise<void>;
  stop: () => Promise<void>;
  getInfo: () => Promise<{
    hasActiveChatter: boolean;
    hasClosedChat: boolean;
    isChatOpen: boolean;
    isDrawerOpen: boolean;
  }>;
  deleteHistory: () => Promise<void>;
  reset: (options?: Partial<AdaSettings>) => Promise<void>;
  subscribeEvent: (
    event: string,
    callback: (data: any, context: any) => void,
  ) => Promise<void>;
  toggle: () => Promise<void>;
  triggerAnswer: (answer: string) => Promise<void>;
}

interface AdaSettings {
  adaReadyCallback?: (options: { isRolledOut: boolean }) => void;
  onAdaEmbedLoad?: () => void;
  handle?: string;
  testMode?: boolean;
  lazy?: boolean;
  id?: string;
  metaFields?: {
    phone_number?: string;
    name?: string;
    kovo_api_url?: string;
    is_test_mode?: boolean;
    kovo_web_url?: string;
    email?: string;
    user_id?: string;
  };
  sensitiveMetaFields?: {
    kovo_access_token: string | null;
  };
  hideMask?: boolean;
  greeting?: string;
  toggleCallback?: (isOpen: boolean) => void;
}

type StartBot = (options?: {
  shouldOpen?: boolean;
  subject?: string;
  answerId?: string;
  openAsLink?: boolean;
}) => void;

export type State = {
  adaSettings: AdaSettings;
  /** Open question: Is there some way to know if the bot is started without keeping track of it ourselves? */
  started?: boolean;
  loaded?: boolean;
  startBot: StartBot;
};

const testMode = import.meta.env.VITE_STAGE !== 'prod';

const initialState: State = {
  adaSettings: {
    handle: 'kovo',
    testMode,
    id: '__ada',
    metaFields: {
      kovo_api_url: config.apiGateway.appsService.URL,
      is_test_mode: testMode,
      kovo_web_url: window.location.origin,
    },
  },
  startBot: () => {},
};

export const AdaContext = createContext<State>(initialState);

interface Props {
  children: React.ReactNode;
}

const AdaProvider: React.FC<Props & Partial<State>> = ({
  children,
  ...defaultState
}) => {
  const [state, setState] = useState<State>({
    ...initialState,
    ...defaultState,
  });

  const { identityToken, currentUser, email, identityId } = useAuthContext();
  const location = useLocation();
  const { data } = useApplicationStatus();

  const shouldStart = !!currentUser;

  const startBot: StartBot = useCallback(
    async ({
      shouldOpen = true,
      subject,
      answerId = routeToAdaAnswerMapping[location.pathname] ??
        routeToAdaAnswerMapping.default,
      openAsLink = false,
    } = {}) => {
      const SUPPORT_EMAIL = 'support@kovocredit.com';

      // If the bot is not enabled or should not start, open the support email
      if (!shouldStart || openAsLink) {
        // If we're in the mobile app, send a message to the mobile app that will open the email client
        if (window.ReactNativeWebView) {
          sendMessageToMobileApp({
            eventType: 'kovo.webapp.email_support',
            body: {
              emailAddress: SUPPORT_EMAIL,
              subject: subject ?? 'Support Request',
            },
          });
          return;
        }

        const href = subject
          ? `${SUPPORT_EMAIL}?subject=${subject}`
          : SUPPORT_EMAIL;
        window.open(`mailto:${href}`, '_blank');
        return;
      }

      if (!window.adaEmbed) {
        return;
      }

      // If the bot is not started, start it
      if (!state.started) {
        await window.adaEmbed.start({
          ...initialState.adaSettings,
          metaFields: {
            ...initialState.adaSettings.metaFields,
            name: data?.applicationDetails?.firstName
              ? `${data?.applicationDetails.firstName} ${data?.applicationDetails.lastName}`
              : undefined,
            email,
            user_id: identityId,
          },
          sensitiveMetaFields: {
            kovo_access_token: identityToken,
          },
        });

        setState((state) => ({ ...state, started: true }));
      }

      // If the bot is not open, open it
      if (shouldOpen) {
        const { isDrawerOpen } = await window.adaEmbed.getInfo();

        if (!isDrawerOpen) {
          await window.adaEmbed.toggle();
        }
      }

      // await new Promise<void>((resolve, reject) => {
      //   setTimeout(async () => {
      //     try {
      //       // When the bot is open, trigger the answer
      //       await window.adaEmbed?.triggerAnswer(answerId);
      //       resolve();
      //     } catch (error) {
      //       reject(error);
      //     }
      //   }, 1000);
      // });
    },
    [
      state.started,
      location.pathname,
      identityToken,
      shouldStart,
      data?.applicationDetails,
      email,
      identityId,
    ],
  );

  useEffect(() => {
    if (state.loaded) {
      return;
    }

    /**
     * This sets the initial Ada settings. We can update these settings
     * using adaEmbed.reset({ ... }) or when starting the bot
     */
    window.adaSettings = state.adaSettings;

    const loadAda = async () => {
      await loadScript('https://static.ada.support/embed2.js', {
        id: '__ada',
        'data-lazy': 'true',
      });

      setState((state) => ({ ...state, loaded: true }));
    };

    loadAda();
  }, [state.loaded, state.adaSettings]);

  // Set the identity token when the user logs in and when it changes
  useEffect(() => {
    const kovo_access_token = identityToken;

    if (kovo_access_token && state.started) {
      window.adaEmbed?.setSensitiveMetaFields({
        kovo_access_token,
      });
    }
  }, [identityToken, state.loaded, state.started]);

  // Stop the bot when the user logs out
  useEffect(() => {
    const stopBot = async () => {
      await window.adaEmbed?.reset();
      await window.adaEmbed?.deleteHistory();
      await window.adaEmbed?.stop();
      setState((state) => ({ ...state, started: false }));
    };

    // If the bot should not start and has started, stop it
    if (!shouldStart && state.started) {
      stopBot();
    }
  }, [shouldStart, state.started]);

  return (
    <AdaContext.Provider
      value={{
        ...state,
        startBot,
      }}
    >
      {children}
    </AdaContext.Provider>
  );
};

export default AdaProvider;
