import { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import {
  CheckoutPaymentMethods,
  PaymentMode,
} from 'components/CheckoutPaymentMethods';

import withCatchupPaymentProvider, {
  useCatchupPaymentContext,
} from './CatchupPaymentProvider';
import CatchupPaymentExistingPaymentMethod from './CatchupPaymentExistingPaymentMethod';
import CatchupPaymentAmountTabs from './CatchupPaymentAmountTabs';

export interface CatchupPaymentFormValues {
  amount: number;
}

export interface CatchupPaymentProps {
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   * This is only used for unit testing.
   */
  walletsDisabled?: boolean;
}

export const CatchupPayment: FC<CatchupPaymentProps> = ({
  walletsDisabled = false,
}) => {
  const { catchupAmount, account, onConfirmPaymentMethod } =
    useCatchupPaymentContext();

  return (
    <Card elevation={0}>
      <CardContent>
        <CatchupPaymentAmountTabs />
        <CatchupPaymentExistingPaymentMethod
          currentPaymentMethod={account.primaryPaymentMethod}
        />
      </CardContent>

      <Box
        sx={{
          position: 'relative',

          '&::before, &::after': {
            content: '""',
            borderBottom: '1px solid #CFCFCF',
            position: 'absolute',
            top: '50%',
            width: 'calc((100% - 255px) / 2)',

            [theme.breakpoints.up('sm')]: {},
          },

          '&::before': {
            left: 0,
          },
          '&::after': {
            right: 0,
          },
        }}
      >
        <Typography marginBottom={theme.spacing(3)} textAlign="center">
          Or use a new payment method
        </Typography>
      </Box>

      <CheckoutPaymentMethods
        onConfirmPaymentMethod={onConfirmPaymentMethod}
        amountInDollars={catchupAmount}
        paymentMode={PaymentMode.PAYMENT}
        walletsDisabled={walletsDisabled}
      />
    </Card>
  );
};

export default withCatchupPaymentProvider(CatchupPayment);
