import { Box, CircularProgress, Grow, Typography } from '@mui/material';

import {
  KOVO_BLUE,
  KOVO_GREEN,
  KOVO_ORANGE,
  KOVO_PINK,
  RED,
  theme,
} from 'context/ThemeProvider';
import OnboardingPage from 'components/OnboardingPage';
import CheckmarkCircle from 'assets/img/icons/checkmark-circle-green.svg';

import ConfettiExplosion from 'react-confetti-explosion';

type ApplyPendingProps = {
  showSuccess: boolean;
};

const ApplyPending = ({ showSuccess }: ApplyPendingProps) => {
  const elementToRender = showSuccess ? (
    <>
      <ConfettiExplosion
        particleCount={200}
        duration={3000}
        zIndex={1}
        colors={[KOVO_ORANGE, RED, KOVO_PINK, KOVO_BLUE, KOVO_GREEN]}
      />
      <Grow in={showSuccess}>
        <Box
          zIndex={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={CheckmarkCircle}
            alt="Green checkmark"
            aria-hidden={true}
            width={96}
            height={96}
            marginBottom={theme.spacing(2)}
          />
          <Typography variant="h1" color="inherit">
            You’re approved!
          </Typography>
        </Box>
      </Grow>
    </>
  ) : (
    <>
      <CircularProgress
        color="inherit"
        size={96}
        thickness={6}
        sx={{
          marginBottom: theme.spacing(2),
        }}
      />
      <Typography variant="h1" color="inherit">
        Getting your decision...
      </Typography>
    </>
  );

  return (
    <OnboardingPage showFooter={false} onboardingStep={3}>
      <Box height="80vh">
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            [theme.breakpoints.up('md')]: {
              height: '80%',
            },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.primary.main,
            width: '100%',
          }}
        >
          {elementToRender}
        </Box>
      </Box>
    </OnboardingPage>
  );
};

export default ApplyPending;
