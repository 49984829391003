import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { DARK_BLUE, GREY, KOVO_BLUE, theme } from 'context/ThemeProvider';

import CreditCard from 'assets/img/icons/credit-card.svg';
import { PaymentMethodStatus, PaymentMethods } from './CheckoutPaymentMethods';

const CheckoutPaymentFormContainer: React.FC<{
  paymentMethods: PaymentMethods;
  walletsDisabled: boolean;
}> = ({ children, paymentMethods, walletsDisabled }) => {
  const [expanded, setExpanded] = useState(false);
  const [walletsHaveLoaded, setWalletsHaveLoaded] = useState(walletsDisabled);

  useEffect(() => {
    if (walletsHaveLoaded) {
      return;
    }

    if (walletsDisabled) {
      setWalletsHaveLoaded(true);

      return;
    }

    setWalletsHaveLoaded(
      paymentMethods.applePay !== PaymentMethodStatus.Loading &&
        paymentMethods.googlePay !== PaymentMethodStatus.Loading,
    );

    // If the payment methods are still loading after 3 seconds, show the form.
    const timeout = setTimeout(() => {
      if (!walletsHaveLoaded) {
        setWalletsHaveLoaded(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [paymentMethods, walletsHaveLoaded, walletsDisabled]);

  const walletsEnabled =
    paymentMethods.applePay === PaymentMethodStatus.Enabled ||
    paymentMethods.googlePay === PaymentMethodStatus.Enabled;

  if (!walletsHaveLoaded) {
    return null;
  }

  if (!walletsEnabled) {
    return <>{children}</>;
  }

  return (
    <Box position="relative">
      <Accordion
        expanded={expanded}
        onChange={(_, isExpanded) => setExpanded(isExpanded)}
        elevation={0}
        data-testid="credit-card-payment-method-accordion"
        sx={{
          '&.Mui-expanded': {
            boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12) inset',
          },
        }}
      >
        <AccordionSummary
          sx={{
            height: 55,
            width: '100%',
            background: `linear-gradient(white, white) padding-box, linear-gradient(to right, ${KOVO_BLUE}, #FF66CC) border-box`,
            border: '2px solid transparent',
            borderRadius: '4px',

            '&.Mui-expanded': {
              minHeight: 'auto',
              background: 'none',
              border: 'none',
              borderRadius: '2px',

              '&:hover': {
                background: 'transparent',
              },
            },

            '&:hover': {
              background: `linear-gradient(${GREY}, ${GREY}) padding-box, linear-gradient(to right, ${KOVO_BLUE}, #FF66CC) border-box`,
            },

            '.MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          component="button"
        >
          <Box component="img" src={CreditCard} />
          <Typography
            sx={{
              color: DARK_BLUE,
              fontSize: '26px',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.26px',
              marginLeft: theme.spacing(0.75),
            }}
          >
            Card
          </Typography>
        </AccordionSummary>

        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CheckoutPaymentFormContainer;
