import { UseQueryOptions, useQuery } from 'react-query';

import {
  Account,
  CHARGE_ACCOUNT_PRODUCT_ID,
  INSTALLMENTS_PRODUCT_ID,
} from 'types/schemas';
import { useClientsService } from 'hooks/useClientsService';

export type GetAccountsResponse = {
  accounts: Account[];
};

type GetAccounts = GetAccountsResponse & {
  chargeAccount?: Account;
  installmentAccount?: Account;
};

export const USE_ACCOUNTS_QUERY_KEY = 'accounts';

function useGetAccounts(options?: UseQueryOptions<GetAccounts>) {
  const clientsService = useClientsService();

  const getAccounts = async () => {
    const res: GetAccountsResponse = await clientsService.get<GetAccounts>(
      `/v1/accounts`,
    );

    const chargeAccount = res.accounts.find(
      ({ productId }) => productId === CHARGE_ACCOUNT_PRODUCT_ID,
    );

    const installmentAccount = res.accounts.find(
      ({ productId }) => productId === INSTALLMENTS_PRODUCT_ID,
    );

    return {
      ...res,
      chargeAccount,
      installmentAccount,
    };
  };

  return useQuery<GetAccounts>([USE_ACCOUNTS_QUERY_KEY], () => getAccounts(), {
    refetchInterval: (data) => {
      const processing = data?.accounts.some(
        (account) => account.status === 'processing',
      );

      return processing ? 500 : false;
    },
    ...options,
  });
}

export default useGetAccounts;
