import { Link, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useEsignDownloadUrlQuery from 'hooks/useEsignDownloadUrlQuery';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';

const INSTALLMENTS_CONTRACT_FILE_NAME = 'Kovo_Retail_Installment_Contract_';
const InstallmentsContractDownload: React.FC = () => {
  const { data, isFetching, error } = useEsignDownloadUrlQuery(
    INSTALLMENTS_PRODUCT_ID,
    INSTALLMENTS_CONTRACT_FILE_NAME,
  );

  if (isFetching) {
    return <Skeleton sx={{ marginTop: theme.spacing(2) }} />;
  }

  if (error) {
    return (
      <Typography variant="footnote" marginTop={theme.spacing(2)}>
        Error generating download link. Please contact support@kovocredit.com.
      </Typography>
    );
  }

  if (!data) {
    return null;
  }
  return (
    <Link
      href={data?.downloadUrl}
      target="_blank"
      rel="noopener noreferrer"
      variant="body1"
      sx={{ marginTop: theme.spacing(2), display: 'inline-block' }}
    >
      Installment Credit Agreement
    </Link>
  );
};

export default InstallmentsContractDownload;
