import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { ReferralCode } from 'types/ReferralCode';

export interface FetchReferralCodeResponse {
  status: string;
  referralCode: ReferralCode | null;
}

export const useReferralCode = () => {
  const { identityId, username } = useContext(AuthContext);

  const fetchReferralCode = async (): Promise<FetchReferralCodeResponse> => {
    return API.get(
      'billing',
      '/billing/referral-code',
      amplifyRequestContext(identityId, username),
    );
  };

  return useQuery<FetchReferralCodeResponse, Error, ReferralCode | null>(
    ['referralCode'],
    () => fetchReferralCode(),
    {
      select: (data) => data.referralCode,
    },
  );
};
