import { useContext } from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import { Application, LoanProductId } from 'types/schemas';
export type CompletedApplicationResponse = {
  completedApplication?: Application;
};

function useGetCompletedApplication(productId: LoanProductId) {
  const { identityId, username } = useContext(AuthContext);
  const getCompletedApplication = async () => {
    const res = await API.get('clientsService', `/v1/applications`, {
      headers: {
        ...amplifyRequestContext(identityId, username, {
          'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
          'x-user-id': identityId,
        }).headers,
      },
    });

    const filteredApplications: [Application] = res.applications.filter(
      (application: Application) =>
        application.loanProductId === productId &&
        application.status === 'completed',
    );

    return {
      completedApplication: filteredApplications[0],
    };
  };

  return useQuery<CompletedApplicationResponse>(
    [`completedApplication-${productId}`],
    () => getCompletedApplication(),
  );
}

export default useGetCompletedApplication;
