import { useContext } from 'react';
import { API } from 'aws-amplify';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { AuthContext } from 'context/AuthProvider';
import ClaimGiftCardDialog from 'components/ClaimGiftCardDialog';
import { FormValues } from 'components/ClaimGiftCardDialog/ClaimGiftCardDialog';

interface Props {
  onClose: () => void;
  open: boolean;
  referralClaimIds: string[];
  claimableRewardCategoryIds: string[];
}

interface MutationArgument {
  values: FormValues;
}

const ReferralRedemptionDialog: React.FC<Props> = ({
  onClose,
  open,
  referralClaimIds,
  claimableRewardCategoryIds,
}) => {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();
  const history = useHistory();

  const claimReferrals = async ({ values }: MutationArgument) => {
    await API.post('billing', `/billing/referrals/claim`, {
      body: {
        referralClaimIds,
        claimableRewardCategoryIds,
        product: values.giftCardName?.toLowerCase(),
      },
      ...amplifyRequestContext(identityId, username),
    });
  };

  const onSuccess = () => {
    queryClient.invalidateQueries('giftCards');
    queryClient.invalidateQueries('referralClaim');
    queryClient.invalidateQueries('rewards');
    onClose();
    history.push('/wallet');
  };

  return (
    <ClaimGiftCardDialog
      onClose={onClose}
      open={open}
      mutateFn={claimReferrals}
      onSuccess={onSuccess}
    />
  );
};

export default ReferralRedemptionDialog;
