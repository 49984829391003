import { Box, Link as MuiLink, Typography } from '@mui/material';
import { Link, Redirect } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import {
  INVITING_REWARD_AMOUNT,
  REFERRED_REWARD_AMOUNT,
} from 'helpers/referralClaims';

import Page from 'components/Page';
import PageLoader from 'components/PageLoader';
import ShareReferralCode from 'components/ShareReferralCode';
import ReferralsBalance from 'components/ReferralsBalance';
import PopupDialog from 'components/PopupDialog';
import { OFFERS_REWARDS_STATUSES } from './OfferCategoriesPage';

const Referrals: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (!OFFERS_REWARDS_STATUSES.includes(data.applicationStatus)) {
    return <Redirect to="/" />;
  }

  return (
    <Page>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Get {INVITING_REWARD_AMOUNT} for you + {REFERRED_REWARD_AMOUNT} for a
          friend
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          It pays to share! You'll get {INVITING_REWARD_AMOUNT} and they get{' '}
          {REFERRED_REWARD_AMOUNT} when their first 3 payments are made on time.
          The best part? <Box component="strong">Earnings are unlimited</Box>.{' '}
          <PopupDialog
            variant="body1"
            dialogText={
              <>
                To earn from inviting friends, a new customer must sign up using
                your unique invite link and make 3 timely payments.
                <br />
                <br />
                When both conditions are met, earnings become available in your
                wallet and you will be able to access and redeem them in the
                form of a gift card.
              </>
            }
          >
            Details
          </PopupDialog>
          .
        </Typography>
      </Box>

      <ReferralsBalance hideClaimSection />

      <ShareReferralCode />

      <Typography
        variant="footnote"
        marginTop={theme.spacing(3)}
        textAlign="center"
      >
        Access your earnings in your{' '}
        <MuiLink component={Link} to="/wallet">
          wallet
        </MuiLink>
        .
      </Typography>
    </Page>
  );
};

export default Referrals;
