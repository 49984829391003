import { Box, Grid, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import Page from 'components/Page';
import AccountStatusCard from 'components/AccountStatusCard';
import PaymentMethodCard from 'components/PaymentMethodCard';
import PaymentHistoryCard from 'components/PaymentHistoryCard';
import StripePaymentInfo from 'components/StripePaymentInfo/StripePaymentInfo';
import PageLoader from 'components/PageLoader/PageLoader';
import useGetAccounts from 'hooks/queries/useGetAccounts';

const InstallmentPlanPage: React.FC = () => {
  const { data: applicationData, isLoading: isLoadingApplication } =
    useApplicationStatus();
  const { data: accountsData, isLoading: isLoadingAccounts } = useGetAccounts();

  const isCheckoutUser =
    applicationData?.applicationDetails?.provider === 'checkout' ||
    applicationData?.applicationDetails?.billingProvider === 'checkout';

  if (isLoadingApplication || isLoadingAccounts) {
    return <PageLoader />;
  }

  const shouldShowExplainerText = () => {
    if (!accountsData) {
      return false;
    }
    const account = accountsData.accounts.find(
      ({ product }) => product.type === 'loan_installment',
    );
    return account?.status !== 'closed';
  };

  return (
    <Page>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Installment Plan
        </Typography>

        {shouldShowExplainerText() && (
          <Typography marginBottom={theme.spacing(4)}>
            Make your monthly payments on-time to build positive payment
            history.
          </Typography>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isCheckoutUser ? <AccountStatusCard /> : <StripePaymentInfo />}
        </Grid>

        <Grid item xs={12}>
          {isCheckoutUser && <PaymentMethodCard />}
        </Grid>

        <Grid item xs={12}>
          {isCheckoutUser && <PaymentHistoryCard />}
        </Grid>
      </Grid>
    </Page>
  );
};

export default InstallmentPlanPage;
