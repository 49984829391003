import { useHistory } from 'react-router-dom';

import useApplicationStatus from 'hooks/useApplicationStatus';
import ApplyPending from 'components/ApplyPending';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthProvider';
import { logOnboardingEvent } from 'libs/logger';

const ApplyPendingPage: React.FC = () => {
  const history = useHistory();
  const { email } = useContext(AuthContext);
  const { data } = useApplicationStatus({
    refetchInterval: 500,
  });
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.applicationStatus === 'signatureNeeded') {
      logOnboardingEvent({
        eventName: 'application accepted',
        email,
      });

      history.replace('/esign');
      return;
    }

    if (
      data?.applicationStatus === 'applicationAdverseAction' ||
      data?.applicationStatus === 'rejected' || // Rejected is deprecated
      data?.applicationStatus === 'kycFailed' // kycFailed is deprecated
    ) {
      logOnboardingEvent({
        eventName: 'application adverse action underwriting',
        email,
      });

      history.replace('/adverse-action-notice');
      return;
    }

    if (data?.applicationStatus === 'applicationRejected') {
      logOnboardingEvent({
        eventName: 'application rejected id verification',
        email,
      });

      history.replace('/application-rejected');
      return;
    }

    if (
      data.applicationStatus !== 'new' &&
      data.applicationStatus !== 'kycPending'
    ) {
      setShowSuccess(true);
      setTimeout(() => {
        history.replace('/');
      }, 3000);
      return;
    }
  }, [data, email, history]);

  return <ApplyPending showSuccess={showSuccess} />;
};

export default ApplyPendingPage;
