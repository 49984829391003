import { useQuery } from 'react-query';
import { API } from 'aws-amplify';

import { amplifyRequestContext } from 'helpers/amplify-request-context';

function useContractDownloadUrl(identityId?: string, username?: string) {
  const getDownloadUrl = async (identityId?: string, username?: string) => {
    if (!identityId || !username) return;

    const res = await API.post(
      'installments',
      '/installments/downloadUrl',
      amplifyRequestContext(identityId, username),
    );

    if (res.error) {
      throw res.error;
    }

    return res.downloadUrl;
  };

  return useQuery<string>(
    ['contractDownloadUrl', identityId, username],
    () => getDownloadUrl(identityId, username),
    { refetchOnWindowFocus: false },
  );
}

export default useContractDownloadUrl;
