import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { Offer } from 'components/OffersList/OffersList';

const useOffersByCategoryQuery = () => {
  const { identityId, username } = useContext(AuthContext);

  const { categoryId } = useParams<{ categoryId?: string }>();

  const getOffersByCategory = async (categoryId?: string) => {
    if (!categoryId) return;

    const offers = await API.get(
      'billing',
      `/billing/v2/offer-categories/${categoryId}/offers`,
      amplifyRequestContext(identityId, username),
    );

    return offers;
  };

  return useQuery<Offer[]>(
    ['offers', categoryId],
    () => getOffersByCategory(categoryId),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useOffersByCategoryQuery;
